import React from 'react'
import Moment from 'moment'
import { BsPeople } from 'react-icons/bs'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import Modal from '@components/common/modal/Modal'
import { FlexContainer, XSpacing, YSpacing } from '@components/common'
import DividerLine from '@components/common/DividerLine'
import { TechHelpForm } from '@components/common'
import Tooltip from '@components/common/Tooltip'
import { colors } from '../../../../constants'
import LinkText from '@components/common/form/LinkText'
import { Audit, CateringOrder, CateringOrderMenuItem } from '@types'

interface OrderDetailsModalProps {
  orderDetails: CateringOrder
  hideModal: () => void
  setSelectedOrder: (order: CateringOrder) => void
}

const OrderDetailsModal = ({
  orderDetails,
  hideModal,
  setSelectedOrder,
}: OrderDetailsModalProps) => {
  const onSelectMenu = () => {
    setSelectedOrder(orderDetails)
    hideModal()
  }

  const renderItemInfoTooltip = (orderItem: CateringOrderMenuItem) => {
    const { menuItem } = orderItem
    const {
      description,
      servingSize,
      servingsPerPkg,
      pricePerPersonCalc,
      price,
      tagsDietaryPreferenceList,
    } = menuItem
    const pricedPerPkg = pricePerPersonCalc === 'PerPackage'
    const itemPrice = Number(price)
    const pricePerServing =
      pricedPerPkg && Number(servingsPerPkg) > 0
        ? `${(itemPrice / Number(servingsPerPkg)).toFixed(
            2,
          )} ($${itemPrice.toFixed(2)} Per Pkg)`
        : itemPrice.toFixed(2)

    return (
      <FlexContainer flexDirection="column">
        <p className="font-bold">Price Per Serving:</p>
        <p className="ml-3">${pricePerServing}</p>
        <YSpacing height="10px" />
        <p className="font-extrabold">Description:</p>
        <p className="ml-3">{description}</p>
        <YSpacing height="10px" />
        <p className="font-bold">Servings Size:</p>
        <p className="ml-3">{servingSize || 'N/A'}</p>
        <YSpacing height="10px" />
        <p className="font-bold">Servings Per Pkg:</p>
        <p className="ml-3">{servingsPerPkg}</p>
        <YSpacing height="10px" />
        <p className="font-bold">Dietary Tags:</p>
        <p className="ml-3">{tagsDietaryPreferenceList}</p>
        {menuItem.packaging && (
          <>
            <YSpacing height="10px" />
            <p className="font-bold">Packaging:</p>
            <p className="ml-3">{menuItem.packaging.name}</p>
          </>
        )}
      </FlexContainer>
    )
  }

  const renderItems = () => {
    if (orderDetails) {
      const { chefs, orderMenuItems } = orderDetails
      const includeChefNames = chefs.length > 1

      return (
        <>
          {chefs.map((c) => (
            <>
              {includeChefNames && (
                <h1 className="text-lg font-bold">{c.name}&apos;s Items</h1>
              )}
              <table className="table-fixed w-full border-separate order-table">
                <tr>
                  <th className="text-left bold uppercase w-3/6">Item</th>
                  <th className="text-left bold uppercase w-1/6">Price</th>
                  <th className="text-left bold uppercase w-1/6">Qty</th>
                  <th className="text-right bold uppercase w-1/6">Total</th>
                </tr>
                {orderMenuItems
                  .filter((i) => i.chefId === c.id)
                  .map((i) => (
                    <tr key={i.id}>
                      <td className="text-left flex w-full">
                        {i.name}
                        <XSpacing width="10px" />
                        <FlexContainer margin="0 10px 0 0" width="30%">
                          <Tooltip iconType="info" isTooltipBottom={true}>
                            {renderItemInfoTooltip(i)}
                          </Tooltip>
                          <XSpacing width="5px" />
                          <Tooltip iconType="notes" isTooltipBottom={true}>
                            <p className="font-extrabold">Internal Notes</p>
                            <p>
                              {i.menuItem
                                ? i.menuItem.internalNotes
                                : i.internalNotes}
                            </p>
                          </Tooltip>
                        </FlexContainer>
                      </td>
                      <td className="text-left">
                        ${Number(i.price).toFixed(2)}
                      </td>
                      <td className="text-left">{i.quantity}</td>
                      <td className="text-right">
                        ${(Number(i.price) * i.quantity).toFixed(2)}
                      </td>
                    </tr>
                  ))}
              </table>
              {chefs.length > 1 && <DividerLine margin="10px 0px" />}
            </>
          ))}
        </>
      )
    }
  }

  const renderOrderDetails = () => {
    if (orderDetails) {
      const {
        audits,
        chefs,
        concept,
        conceptName,
        headCount,
        dropoffAddress,
        serviceFee,
        subtotal,
        tax,
        tip,
        total,
      } = orderDetails
      const chefName = ((chefs || [])[0] || {}).name || 'No Chefs On Order'
      const titleStr = `${conceptName ? conceptName + ' By ' : ''} ${chefName}`

      return (
        <div>
          <h1 className="text-2xl font-bold">{titleStr}</h1>
          {chefs.length > 1 && (
            <>
              <YSpacing height="10px" />
              <FlexContainer alignItems="center">
                <h3 className="font-bold text-xl">Supporting Chefs:</h3>
                {chefs.slice(1).map((c) => (
                  <h3 key={c.id} style={{ marginLeft: '10px' }}>
                    {c.name}
                  </h3>
                ))}
              </FlexContainer>
            </>
          )}
          <YSpacing height="15px" />
          {audits?.length > 0 && <CreatedBy audit={audits[0]} />}
          <YSpacing height="5px" />
          <PricePerPerson
            price={
              concept
                ? chefs.length > 0
                  ? (Number(subtotal) / Number(headCount)).toFixed(2)
                  : concept.estimatedPricePerPerson
                : (Number(subtotal) / Number(headCount)).toFixed(2)
            }
          />
          <YSpacing height="20px" />
          <FlexContainer>
            <HiOutlineLocationMarker size={18} />
            <XSpacing width="20px" />
            <FlexContainer flexDirection="column">
              <p>{`${dropoffAddress.line1} ${dropoffAddress.line2}`}</p>
              <p>{`${dropoffAddress.city}, ${dropoffAddress.state} ${dropoffAddress.zip}`}</p>
            </FlexContainer>
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer>
            <BsPeople size={18} />
            <XSpacing width="15px" />
            <p>{headCount} people</p>
          </FlexContainer>
          <DividerLine margin="10px 0" />
          {renderItems()}
          <YSpacing height="20px" />
          <FlexContainer justifyContent="space-between">
            <strong>Subtotal:</strong>
            <strong>${Number(subtotal).toFixed(2)}</strong>
          </FlexContainer>
          <YSpacing height="10px" />
          <FlexContainer justifyContent="space-between">
            <strong>Delivery & Service Fee:</strong>
            <strong>${Number(serviceFee).toFixed(2)}</strong>
          </FlexContainer>
          <YSpacing height="10px" />
          <FlexContainer justifyContent="space-between">
            <strong>Tax:</strong>
            <strong>${Number(tax).toFixed(2)}</strong>
          </FlexContainer>
          <YSpacing height="10px" />
          <FlexContainer justifyContent="space-between">
            <strong>Gratuity:</strong>
            <strong>${Number(tip).toFixed(2)}</strong>
          </FlexContainer>
          <YSpacing height="10px" />
          <FlexContainer justifyContent="space-between">
            <strong>Total:</strong>
            <strong>${Number(total).toFixed(2)}</strong>
          </FlexContainer>
          <YSpacing height="40px" />
          <FlexContainer alignItems="center" justifyContent="flex-end">
            <TechHelpForm margin="0 30px 0 0" />
            <LinkText
              testId="close"
              label="Close"
              onClick={hideModal}
              color={colors.violet}
            />
            <XSpacing width="20px" />
            <LinkText
              testId="selectMenu"
              label="Select Menu"
              onClick={onSelectMenu}
              color={colors.blue300}
            />
          </FlexContainer>
        </div>
      )
    }
  }

  return (
    <Modal
      title="View Menu Details"
      height="800px"
      width="750px"
      hideModal={hideModal}
      className="z-200"
    >
      {orderDetails && renderOrderDetails()}
    </Modal>
  )
}

const CreatedBy = ({ audit }: { audit: Audit }) =>
  audit ? (
    <p>
      Created By:{' '}
      {`${audit.createdBy.firstName} ${audit.createdBy.lastName} on ${Moment(
        audit.created_at,
      ).format('MM/DD/YYYY')}`}
    </p>
  ) : null

const PricePerPerson = ({ price }: { price: string }) => (
  <p className="text-xl">
    Price Per Person:{' '}
    <span className="bg-green-bright px-3 py-1.5 rounded text-white font-bold">
      ${price}
    </span>
  </p>
)

export default OrderDetailsModal
