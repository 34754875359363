import { connect } from 'react-redux'

import { ProposalDashboard } from '@components/proposal'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const {
    proposals,
    proposalDashboard,
    proposalDashboardParams,
    user,
    router,
  } = state
  const { emailProposalOn } = state.proposalPage
  const { isLoading } = state.proposalPage
  const apiUrl = `${config.api_host}/api/admin/`
  const proposalPdfUrlPrefix = `${apiUrl}proposals`

  const port = router.location.port ? `:${router.location.port}` : ''
  const host =
    config.admin_url + (config.admin_url === 'http://localhost' ? port : '')
  const { hqs } = user

  return {
    loadingEmail: emailProposalOn,
    isLoading,
    hqs,
    proposals,
    proposalDashboard,
    proposalDashboardParams,
    proposalPdfUrlPrefix,
    userEmail: (user && user.email) || '',
    baseUri: host,
  }
}

const mapDispatchToProps = () => {
  const { ProposalService, RestService, UIService, ChefService } = services
  const {
    pResponseAccountExecutives,
    pResponseChefs,
    pResponseGeneric,
    pResponseProposal,
    pResponseProposals,
    pResponseOrderError,
    pRequestOrdersFilter: pRequestProposalsFilter,
    pResponseOrder,
  } = presenters.Api
  const pResponseError = presenters.Errors.responseErrorMessage
  const {
    AsyncLoadAccounts,
    LoadAccountExecutives,
    LoadDashboardProposals,
    SelectProposal,
    EmailProposal,
    ProposalAction,
    DeleteProposals,
    LoadChefs,
    LoadDinerProfiles,
    ProposalsToOrders,
  } = coordinators

  const loadProposals = LoadDashboardProposals({
    UIService,
    ProposalService,
    RestService,
    pRequestProposalsFilter,
    pResponseProposals,
  })
  const newProposal = () => UIService.EditProposal.show('new')
  const { clearOrderables: clearProposals } = ProposalService
  const proposalAction = ProposalAction({ RestService, UIService })
  const selectProposal = SelectProposal({
    ProposalService,
    RestService,
    pResponseProposal,
  })
  const deleteProposals = DeleteProposals({
    ProposalService,
    RestService,
    UIService,
    pResponseProposalError: pResponseOrderError,
    HandleError,
  })
  const emailProposal = EmailProposal({
    ProposalService,
    RestService,
    UIService,
    HandleError,
  })
  const { setLastLoadProposalDashboardParams } = UIService.ProposalDashboard

  const loadAccountExecutives = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })
  const loadAccounts = AsyncLoadAccounts({ RestService, pResponseGeneric })
  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })
  const loadDinerProfiles = LoadDinerProfiles({ RestService, UIService })

  const proposalsToOrders = ProposalsToOrders({
    RestService,
    pResponseOrder,
    HandleError,
    pResponseError,
  })

  const { FlashMessage, ConfirmationModal } = UIService
  const { displayFailureMessage } = FlashMessage

  return {
    clearProposals,
    confirmationModal: ConfirmationModal,
    loadAccountExecutives,
    loadAccounts,
    loadChefs,
    loadProposals,
    loadDinerProfiles,
    newProposal,
    proposalAction,
    selectProposal,
    deleteProposals,
    emailProposal,
    proposalsToOrders,
    displayFailureMessage,
    setLastLoadProposalDashboardParams,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalDashboard)
