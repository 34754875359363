import React from 'react'
import PropTypes from 'prop-types'
import { FormInputContainer } from '@res/styledComponents/index'
import Error from '@components/common/form/Error'

const Input = (props) => {
  const {
    background,
    color,
    error,
    value,
    defaultValue,
    disabled,
    label,
    marginBottom,
    width,
    type,
    testId,
    pattern,
    onChange,
    onKeyDown,
    placeholder,
    textAlign,
    min,
    max,
  } = props

  // Prevent number inputs from incrementing/decrementing when user focuses
  // input and scrolls
  const handleFocus = (focusEvent) => {
    if (type === 'number') {
      focusEvent.target.addEventListener(
        'wheel',
        (e) => {
          e.preventDefault()
        },
        { passive: false },
      )
    }
  }

  return (
    <FormInputContainer width={width} marginBottom={marginBottom}>
      <label>{label}</label>
      <input
        name={label}
        pattern={pattern}
        placeholder={placeholder}
        id={testId}
        disabled={disabled}
        style={{
          cursor: disabled ? 'not-allowed' : 'auto',
          textAlign,
          background,
          color,
        }}
        type={type}
        min={type === 'number' ? min : undefined}
        max={type === 'number' ? max : undefined}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={handleFocus}
      />
      {error && <Error error={error} />}
    </FormInputContainer>
  )
}

Input.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  pattern: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  marginBottom: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.string,
  testId: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  textAlign: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
}

Input.defaultProps = {
  disabled: false,
  marginBottom: '20px',
  width: '100%',
  type: 'text',
}

export default Input
