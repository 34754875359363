import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FaCheckCircle } from 'react-icons/fa'
import { FaCircleXmark } from 'react-icons/fa6'
import services from '@services'
import Modal from '@components/common/modal/Modal'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'
import LoadingIndicator from '@components/common/LoadingIndicator'
import LinkText from '@components/common/form/LinkText'
import sort from '@res/images/sort.svg'
import {
  AuthorizedInteractable,
  AuthorizedDisplay,
} from '@containers/common/auth'
import {
  Button,
  Checkbox,
  CurrencyInput,
  Dropdown,
  Input,
  MultiSelectDropDown,
  RadioButton,
} from '@components/common/form'
import { BiPencil, BiTrash, BiCopyAlt } from 'react-icons/bi'
import { HiInformationCircle } from 'react-icons/hi'
import { ButtonsBar } from '@res/styledComponents/index'
import FlexContainer from '@components/common/FlexContainer'

export class MenuItems extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedAuditTypes: [],
      checkedMenuItems: [],
      dishTypes: [],
      goMealTypes: [],
      menuItems: props.menuItems || [],
      menuItem: null,
      editModalVisible: false,
      sortAsc: true,
      editedItems: [],
      filter: '',
      marketType: '',
      mealType: '',
      goMealType: '',
      inputType: '',
      selectedMenuItem: {},
      selectedDishType: '',
      showDishTypeSelector: false,
      viewArchivedMenuItems: false,
    }
  }

  componentDidMount() {
    ;(async () => {
      const { loadChefMenuItems, chefId } = this.props
      loadChefMenuItems(chefId)

      const { allMealTypes, allGroupOrderMealTypes } =
        await this.props.loadMenuItemSettings()
      this.setState({
        dishTypes: allMealTypes,
        goMealTypes: allGroupOrderMealTypes || [],
      })
    })()
  }

  componentDidUpdate(prevProps, prevState) {
    const { loadChefMenuItems, chefId } = this.props
    const { viewArchivedMenuItems } = prevState
    ;(async () => {
      if (viewArchivedMenuItems != this.state.viewArchivedMenuItems) {
        this.setState({ showLoader: true })
        await loadChefMenuItems(chefId, this.state.viewArchivedMenuItems)
        this.setState({ showLoader: false })
      }
    })()
  }

  componentWillReceiveProps(nextProps) {
    const { menuItems } = nextProps
    if (!!menuItems && menuItems !== this.state.menuItems) {
      this.setState({ menuItems }, () => {
        const goItemIds = menuItems
          .filter((item) => item.marketType === 'Group Order')
          .map((item) => item.id)
        if (goItemIds.length > 0) {
          this.loadMenuItemRatings(goItemIds)
        }
        this.sortMenuItemsByString('name', true)
      })
    }
  }

  loadMenuItemRatings = async (ids) => {
    const { loadMenuItemRatings } = this.props
    const { menuItems } = this.state

    const ratings = await loadMenuItemRatings(ids)

    const updatedMenuItems = menuItems.map((item) => {
      if (item.marketType === 'Group Order') {
        return {
          ...item,
          ratingInfo: {
            averageRating: ratings[item.id].rating,
            numRatings: ratings[item.id].count,
          },
        }
      } else {
        return item
      }
    })

    this.setState({ menuItems: updatedMenuItems })
  }

  sortArrayByProperty = (array, property) => {
    return array.sort((a, b) => {
      return a[property] > b[property] ? 1 : -1
    })
  }

  deleteMenuItems = () => {
    const { deleteMenuItems } = this.props
    deleteMenuItems()
  }

  newMenuItem = () => {
    const { newMenuItem } = this.props
    newMenuItem()
  }

  editMenuItem = (menuItem) => {
    const { editMenuItem } = this.props
    editMenuItem(menuItem)
  }

  sortMenuItemsByString = (columnName, willReceiveProps) => {
    const { menuItems, sortAsc } = this.state
    const nextSort = willReceiveProps ? sortAsc : !sortAsc
    const getColumn = (item) =>
      item[columnName] ? item[columnName].toLowerCase() : ''
    const sortedMenuItems = menuItems.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !nextSort ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ menuItems: sortedMenuItems, sortAsc: nextSort })
  }

  onCheckMenuItem = (id, name) => () => {
    let { checkedMenuItems } = this.state
    const menuItem = { id, name }
    const idx = checkedMenuItems.findIndex(
      (tempItem) => tempItem.id === menuItem.id,
    )
    if (idx === -1) {
      checkedMenuItems = [...checkedMenuItems, menuItem]
    } else {
      checkedMenuItems = [
        ...checkedMenuItems.slice(0, idx),
        ...checkedMenuItems.slice(idx + 1),
      ]
    }
    this.setState({ checkedMenuItems })
  }

  onSearch = (e) => {
    this.setState({
      filter: e.target.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
      checkedMenuItems: [],
    })
  }

  removeMenuItemIds = async () => {
    const { checkedMenuItems } = this.state
    const { chefId, deleteMenuItems } = this.props
    const successfulItems = await deleteMenuItems({ checkedMenuItems, chefId })
    // filter out sucessfully archived items
    this.setState({
      checkedMenuItems: checkedMenuItems.filter(
        (item) => !successfulItems[item.id],
      ),
      showLoader: false,
    })
  }

  restoreMenuItemIds = async () => {
    const { checkedMenuItems } = this.state
    const { chefId, bulkRestoreMenuItems } = this.props
    const success = await bulkRestoreMenuItems({ checkedMenuItems, chefId })
    if (success) {
      this.setState({ checkedMenuItems: [] })
    }
    this.setState({ showLoader: false })
  }

  handleBulk = () => {
    const { viewArchivedMenuItems } = this.state
    if (viewArchivedMenuItems) {
      this.setState({ showLoader: true }, this.restoreMenuItemIds)
    } else {
      this.setState({ showLoader: true }, this.removeMenuItemIds)
    }
  }

  sortMenuItemsByNumber = (columnName) => {
    const { menuItems, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? parseFloat(item[columnName].replace(/[$,]/g, '')) : 0.0
    const sortedMenuItems = menuItems.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ menuItems: sortedMenuItems, sortAsc: !sortAsc })
  }

  sortMenuItemsByMargin = () => {
    const { menuItems, sortAsc } = this.state
    const sortedMenuItems = menuItems.sort((a, b) => {
      const marginA = this.calcMargin(a)
      const marginB = this.calcMargin(b)

      return !sortAsc
        ? marginB > marginA
          ? 1
          : -1
        : marginA > marginB
          ? 1
          : -1
    })
    this.setState({ menuItems: sortedMenuItems, sortAsc: !sortAsc })
  }

  sortMenuItemsByBoolean = (columnName) => {
    const { menuItems, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] === true ? 1 : 0)
    const sortedMenuItems = menuItems.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ menuItems: sortedMenuItems, sortAsc: !sortAsc })
  }

  selectMenuItem = (menuItem) => (e) => {
    e.preventDefault()
    this.editMenuItem(menuItem.id)
  }

  saveMenuItems = () => {
    const { editedItems } = this.state
    const { saveMenuItem } = this.props
    const itemsLeft = []
    editedItems.forEach(async (item) => {
      const didSave = await saveMenuItem(item, false)

      if (!didSave) {
        itemsLeft.push(item)
      }
    })

    this.setState({ editedItems: itemsLeft })
  }

  clearEdits = () => {
    const { menuItems } = this.props
    const sliced = menuItems.map((i) => ({ ...i }))
    const sortedItems = this.sortArrayByProperty(sliced, 'name')
    this.setState({ editedItems: [], menuItems: sortedItems })
  }

  calcMargin = (item) => {
    const marketPrice = parseFloat(item.marketPrice.replace(/[$,]/g, ''))
    const chefPrice = parseFloat(item.chefPrice.replace(/[$,]/g, ''))
    if (marketPrice === 0.0) {
      return 0.0
    } else {
      return ((marketPrice - chefPrice) / marketPrice) * 100
    }
  }

  onChangeEditedItems = (value, id, property) => {
    const { menuItems, editedItems } = this.state
    const items = menuItems.map((i) => ({ ...i }))
    const index = menuItems.findIndex((item) => item.id === id)
    items[index][property] = value
    items[index].edited = true
    const currentEditIndex = editedItems.findIndex((item) => item.id === id)
    if (currentEditIndex >= 0) {
      editedItems[currentEditIndex] = items[index]
    } else {
      editedItems.push(items[index])
    }
    this.setState({ menuItems: items, editedItems })
  }

  handleChangingDishType = async (item) => {
    const { loadMenuItem } = this.props
    const menuItem = await loadMenuItem(item.id)
    this.setState({ showDishTypeSelector: true, selectedMenuItem: menuItem })
  }

  handleSavingDishType = async () => {
    const { selectedMenuItem, selectedDishType } = this.state
    const { saveMenuItem } = this.props
    const updatedItem = { ...selectedMenuItem, mealType: selectedDishType }

    const savedMenuItem = await saveMenuItem(updatedItem, false, true)
    if (savedMenuItem) {
      const menuItems = this.state.menuItems.map((item) => {
        if (item.id === savedMenuItem.id) {
          return savedMenuItem
        } else {
          return item
        }
      })
      this.setState({
        menuItems,
        selectedDishType: '',
        showDishTypeSelector: false,
      })
    }
  }

  renderInputDeails = () => {
    services.UIService.Alert.showPopup({
      message:
        'Custom items are menu items created on a Proposal or Sales Order. Manual items are menu items manually created from this page.',
    })
  }

  render() {
    const {
      selectedAuditTypes,
      checkedMenuItems,
      dishTypes,
      goMealTypes,
      menuItems,
      editedItems,
      filter,
      marketType,
      mealType,
      goMealType,
      inputType,
      showLoader,
      viewArchivedMenuItems,
    } = this.state
    const { menuItems: loadedItems, pToMenuItemVerifications } = this.props
    const areMenuItemsLoaded = !!loadedItems
    const areMenuItemsEmpty = menuItems && menuItems.length === 0
    const itemsChecked = checkedMenuItems.length > 0
    let displayedMenuItems = menuItems || []
    const marketTypes = ['Office', 'Group Order']
    const inputTypes = ['Custom', 'Manual']
    const auditTypes = [
      { label: 'Menu Item Description Verified', value: 'descriptionVerified' },
      { label: 'Allergen Tags Verified', value: 'allergensVerified' },
      { label: 'Serving Details Verified', value: 'servingDetailsVerified' },
      {
        label: 'Real Image Uploaded Verified',
        value: 'imageVerified',
      },
    ]

    if (
      filter !== '' ||
      marketType !== '' ||
      mealType !== '' ||
      inputType !== '' ||
      goMealType !== '' ||
      selectedAuditTypes.length > 0
    ) {
      displayedMenuItems = menuItems.filter((menuItem) => {
        const filterRegex = new RegExp(`.*${filter}.*`, 'gi')
        if (marketType !== '' && menuItem.marketType !== marketType) {
          return false
        }
        if (mealType !== '' && menuItem.mealType !== mealType) {
          return false
        }
        if (inputType !== '' && menuItem.inputType !== inputType) {
          return false
        }
        if (goMealType !== '') {
          const itemGOMealTypes = (
            menuItem.tagsGroupOrderMealTypeList || ''
          ).split(',')
          if (!itemGOMealTypes.includes(goMealType)) {
            return false
          }
        }
        if (
          selectedAuditTypes.length > 0 &&
          !selectedAuditTypes.every((filter) => menuItem[filter])
        ) {
          return false
        }
        const matches = menuItem.name.match(filterRegex)

        return matches !== null && matches.length > 0
      })
    }

    return (
      <div style={{ width: '1800px' }}>
        {this.state.showDishTypeSelector && (
          <Modal
            title="Select new Dish Type"
            hideModal={() => this.setState({ showDishTypeSelector: false })}
            color="blue"
          >
            {dishTypes.map((type) => (
              <RadioButton
                marginBottom="5px"
                name="DishType"
                value={type}
                key={type}
                checked={type === this.state.selectedDishType}
                onChange={() => this.setState({ selectedDishType: type })}
              />
            ))}
            <YSpacing height="20px" />
            <Button
              label="Save Menu Item's Dish Type"
              onClick={() => this.handleSavingDishType()}
            />
          </Modal>
        )}
        <ButtonsBar>
          <FlexContainer flexDirection="row" alignItems="flex-start">
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <button
                style={{ width: '200px' }}
                onClick={(event) => {
                  event.preventDefault()
                  this.newMenuItem()
                }}
              >
                <BiPencil />
                Add New Menu Item
              </button>
            </AuthorizedInteractable>
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <button
                style={{ width: '300px' }}
                onClick={() => {
                  this.props.copyMenuItems()
                }}
              >
                <BiCopyAlt />
                Copy Menu Items From Chefs
              </button>
            </AuthorizedInteractable>
            {itemsChecked && (
              <AuthorizedInteractable roles={['master admin', 'chef lead']}>
                <button onClick={this.handleBulk} style={{ width: '200px' }}>
                  {!viewArchivedMenuItems && <BiTrash />}
                  {viewArchivedMenuItems
                    ? 'Restore Checked Items'
                    : 'Archive Checked Items'}
                </button>
              </AuthorizedInteractable>
            )}
            <Checkbox
              checked={this.state.viewArchivedMenuItems}
              onClick={() =>
                this.setState({
                  viewArchivedMenuItems: !this.state.viewArchivedMenuItems,
                })
              }
              label="View Archived Menu Items"
            />
          </FlexContainer>
        </ButtonsBar>
        <FlexContainer>
          <Input
            label="Filter Menu Items"
            onChange={this.onSearch}
            width="250px"
          />
          <XSpacing width="20px" />
          <Dropdown
            label="Filter Market Type"
            width="200px"
            value={marketType}
            onChange={(e) => this.setState({ marketType: e.target.value })}
          >
            <option value="">No Filter</option>
            {marketTypes.map((market) => (
              <option key={market} value={market}>
                {market}
              </option>
            ))}
          </Dropdown>
          <XSpacing width="20px" />
          <Dropdown
            label="Filter Dish Type"
            width="200px"
            value={mealType}
            onChange={(e) => this.setState({ mealType: e.target.value })}
          >
            <option value="">No Filter</option>
            {dishTypes.map((meal) => (
              <option key={meal} value={meal}>
                {meal}
              </option>
            ))}
          </Dropdown>
          <XSpacing width="20px" />
          <Dropdown
            label="Filter Group Order Meal Type"
            width="230px"
            value={goMealType}
            onChange={(e) => this.setState({ goMealType: e.target.value })}
          >
            <option value="">No Filter</option>
            {goMealTypes.map((mealType) => (
              <option key={mealType} value={mealType}>
                {mealType}
              </option>
            ))}
          </Dropdown>
          <XSpacing width="20px" />
          <Dropdown
            label="Filter by Input Type"
            width="200px"
            value={inputType}
            onChange={(e) => this.setState({ inputType: e.target.value })}
          >
            <option value="">No Filter</option>
            {inputTypes.map((input) => (
              <option key={input} value={input}>
                {input}
              </option>
            ))}
          </Dropdown>
          <XSpacing width="20px" />
          <MultiSelectDropDown
            checkedOptions={selectedAuditTypes}
            includeSelectAll={true}
            label="Filter By Audit Type"
            options={auditTypes}
            onChange={(checkedOptions) =>
              this.setState({ selectedAuditTypes: checkedOptions })
            }
            width="230px"
          />
        </FlexContainer>
        <Panel width="100%" maxWidth="auto">
          <Table>
            <thead>
              <tr>
                <th>
                  <p className="flex">Avg Rating</p>
                </th>
                <th
                  className="pointer"
                  onClick={() => this.sortMenuItemsByString('name')}
                >
                  <p className="flex">
                    Item Name <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByString('marketType')}
                >
                  <p className="flex">
                    Market Type <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByString('mealType')}
                >
                  <p className="flex">
                    Dish Type <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() =>
                    this.sortMenuItemsByString('tagsGroupOrderMealTypeList')
                  }
                >
                  <p className="flex">
                    GO Meal Type <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByString('tagsCuisineList')}
                >
                  <p className="flex">
                    Cuisine Types <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th className="pointer nowrap">
                  <p className="flex">Is Hot</p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByNumber('chefPrice')}
                >
                  <p className="flex">
                    Chef Price <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByNumber('marketPrice')}
                >
                  <p className="flex">
                    Market Price <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByMargin()}
                >
                  <p className="flex">
                    Margin % <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByBoolean('isEnabled')}
                >
                  <p className="flex">
                    Marketplace Enabled
                    <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByBoolean('isApproved')}
                >
                  <p className="flex">
                    Approved <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="flex flex-row"
                  onClick={() => this.sortMenuItemsByString('inputType')}
                >
                  <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <HiInformationCircle
                      onMouseOver={() => this.renderInputDeails()}
                      fontSize="15px"
                    />
                  </div>
                  <p style={{ display: 'flex', paddingTop: '5px' }}>
                    Input Type{' '}
                  </p>
                  <img className="item-sort" src={sort} />
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByString('addedBy')}
                >
                  <p className="flex">
                    Added By <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th className="pointer nowrap">
                  {' '}
                  <p className="flex">Copy</p>{' '}
                </th>
                <th className="pointer nowrap">
                  <AuthorizedDisplay roles={['master admin', 'chef lead']}>
                    <p className="flex">
                      {viewArchivedMenuItems ? 'Bulk Restore' : 'Bulk Archive'}
                    </p>
                  </AuthorizedDisplay>
                </th>
                <th>Audit Completed</th>
              </tr>
            </thead>
            <tbody>
              {(!areMenuItemsLoaded || showLoader) && (
                <tr>
                  <td colSpan="4">
                    <LoadingIndicator />
                  </td>
                </tr>
              )}

              {areMenuItemsLoaded && areMenuItemsEmpty && (
                <tr>
                  <td colSpan="5">
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '1.2em',
                        color: 'rgba(0,0,0,0.2)',
                        padding: '20px',
                        backgroundColor: 'rgba(0,0,0,0.01)',
                      }}
                    >
                      No menu items yet.
                    </div>
                  </td>
                </tr>
              )}

              {areMenuItemsLoaded &&
                !showLoader &&
                displayedMenuItems.map((menuItem) => {
                  let backgroundColor = menuItem.edited ? 'lightblue' : null
                  if (menuItem.inputType === 'Custom') {
                    backgroundColor = '#FFFF00'
                  }
                  const verifications = pToMenuItemVerifications(menuItem)
                  const verificationsCompleted = verifications.filter(
                    (v) => v,
                  ).length
                  const allergensAndDescriptionVerified =
                    menuItem.allergensVerified && menuItem.descriptionVerified
                  const applicableVerifications = verifications.length
                  const itemVerificationsCompleted =
                    verificationsCompleted === applicableVerifications
                  let allChildVerificationsCompleted = true
                  let allChildAllergensAndDescripVerified = true
                  if (menuItem.childItems) {
                    allChildVerificationsCompleted = menuItem.childItems.every(
                      (childItem) => {
                        const childVerifications =
                          pToMenuItemVerifications(childItem)
                        const childVerificationsCompleted =
                          childVerifications.filter((v) => v).length

                        return (
                          childVerificationsCompleted ===
                          childVerifications.length
                        )
                      },
                    )
                    allChildAllergensAndDescripVerified =
                      menuItem.childItems.every(
                        (childItem) =>
                          childItem.allergensVerified &&
                          childItem.descriptionVerified,
                      )
                  }
                  const allVerificationsCompleted =
                    itemVerificationsCompleted && allChildVerificationsCompleted
                  const allAllergensAndDescriptionVerified =
                    allergensAndDescriptionVerified &&
                    allChildAllergensAndDescripVerified
                  const { averageRating, numRatings } =
                    menuItem.ratingInfo || {}

                  return (
                    <tr
                      key={menuItem.id}
                      className="clickable"
                      style={{ backgroundColor: backgroundColor }}
                    >
                      <td>
                        {(menuItem.ratingInfo && (
                          <Fragment>
                            <p>
                              {numRatings !== 0
                                ? `${averageRating.toFixed(2)} ⭐`
                                : 'N/A'}
                            </p>
                            <p>{numRatings !== 0 ? `(${numRatings})` : ''}</p>
                          </Fragment>
                        )) ||
                          (menuItem.marketType === 'Group Order'
                            ? 'loading...'
                            : '')}
                      </td>
                      <td onClick={this.selectMenuItem(menuItem)}>
                        {menuItem.name}
                      </td>
                      <td onClick={this.selectMenuItem(menuItem)}>
                        {menuItem.marketType}
                      </td>
                      <td onClick={() => this.handleChangingDishType(menuItem)}>
                        {menuItem.mealType}
                      </td>
                      <td onClick={this.selectMenuItem(menuItem)}>
                        {menuItem.tagsGroupOrderMealTypeList &&
                          menuItem.tagsGroupOrderMealTypeList
                            .split(',')
                            .map((goMealType) => (
                              <span
                                key={`tag_${goMealType}`}
                                className="badge badge-cuisine"
                              >
                                {' '}
                                {goMealType}{' '}
                              </span>
                            ))}
                      </td>
                      <td onClick={this.selectMenuItem(menuItem)}>
                        {menuItem.tagsCuisineList &&
                          menuItem.tagsCuisineList.split(',').map((cuisine) => (
                            <span
                              key={`tag_${cuisine}`}
                              className="badge badge-cuisine"
                            >
                              {' '}
                              {cuisine}{' '}
                            </span>
                          ))}
                      </td>
                      <td>
                        <Checkbox
                          checked={menuItem.isHot}
                          onChange={(e) =>
                            this.onChangeEditedItems(
                              e.target.checked,
                              menuItem.id,
                              'isHot',
                            )
                          }
                        />
                      </td>
                      <td>
                        <CurrencyInput
                          value={menuItem.chefPrice}
                          onChange={(value) =>
                            this.onChangeEditedItems(
                              `${value}`,
                              menuItem.id,
                              'chefPrice',
                            )
                          }
                        />
                      </td>
                      <td>
                        <CurrencyInput
                          value={menuItem.marketPrice}
                          onChange={(value) =>
                            this.onChangeEditedItems(
                              `${value}`,
                              menuItem.id,
                              'marketPrice',
                            )
                          }
                        />
                      </td>
                      <td>{`${this.calcMargin(menuItem).toFixed(2)}%`}</td>
                      <td>
                        <AuthorizedInteractable
                          roles={['master admin', 'chef lead']}
                        >
                          <Checkbox
                            checked={menuItem.isEnabled}
                            onChange={(e) =>
                              this.onChangeEditedItems(
                                e.target.checked,
                                menuItem.id,
                                'isEnabled',
                              )
                            }
                          />
                        </AuthorizedInteractable>
                      </td>
                      <td>
                        <AuthorizedInteractable
                          roles={['master admin', 'chef lead']}
                        >
                          <Checkbox
                            checked={menuItem.isApproved}
                            onChange={(e) =>
                              this.onChangeEditedItems(
                                e.target.checked,
                                menuItem.id,
                                'isApproved',
                              )
                            }
                          />
                        </AuthorizedInteractable>
                      </td>
                      <td onClick={this.selectMenuItem(menuItem)}>
                        {menuItem.inputType ? menuItem.inputType : 'Manual'}
                      </td>
                      <td onClick={this.selectMenuItem(menuItem)}>
                        {menuItem.addedBy ? menuItem.addedBy : ''}
                      </td>
                      <td>
                        <AuthorizedInteractable
                          roles={['master admin', 'chef lead']}
                        >
                          <LinkText
                            label="Copy"
                            onClick={(e) => {
                              e.preventDefault()
                              this.props.showCopyMenuItem(menuItem.id)
                            }}
                          />
                        </AuthorizedInteractable>
                      </td>
                      <td>
                        <AuthorizedDisplay
                          roles={['master admin', 'chef lead']}
                        >
                          <Checkbox
                            checked={
                              !!checkedMenuItems.find(
                                (item) => item.id === menuItem.id,
                              )
                            }
                            onChange={this.onCheckMenuItem(
                              menuItem.id,
                              menuItem.name,
                            )}
                          />
                        </AuthorizedDisplay>
                      </td>
                      <td>
                        {allVerificationsCompleted ? (
                          <FaCheckCircle
                            size={20}
                            color={'green'}
                            style={{ marginLeft: '30%' }}
                          />
                        ) : (
                          <FaCircleXmark
                            size={20}
                            color={
                              allAllergensAndDescriptionVerified
                                ? 'orange'
                                : 'red'
                            }
                            style={{ marginLeft: '30%' }}
                          />
                        )}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </Panel>
        <div className="page-fixed">
          {itemsChecked && (
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <button
                className="button-primary"
                key="removeItem"
                onClick={this.handleBulk}
              >
                {viewArchivedMenuItems
                  ? 'Restore Checked Items'
                  : 'Archive Checked Items'}
              </button>
            </AuthorizedInteractable>
          )}
          {editedItems.length ? (
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <button
                className="button-primary"
                key="saveItems"
                onClick={this.saveMenuItems}
              >
                Save Menu Items
              </button>
            </AuthorizedInteractable>
          ) : null}
          {editedItems.length ? (
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <button
                className="button-primary"
                key="saveItems"
                onClick={this.clearEdits}
              >
                Clear Edits
              </button>
            </AuthorizedInteractable>
          ) : null}
        </div>
      </div>
    )
  }
}

MenuItems.propTypes = {
  chefId: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.object),

  bulkRestoreMenuItems: PropTypes.func,
  deleteMenuItems: PropTypes.func,
  editMenuItem: PropTypes.func,
  loadChefMenuItems: PropTypes.func,
  loadMenuItemRatings: PropTypes.func,
  loadMenuItem: PropTypes.func,
  loadMenuItemSettings: PropTypes.func,
  newMenuItem: PropTypes.func,
  pToMenuItemVerifications: PropTypes.func,
  showCopyMenuItem: PropTypes.func,
  saveMenuItem: PropTypes.func,
  copyMenuItems: PropTypes.func,
}

export default MenuItems
