import { connect } from 'react-redux'

import { PromptBuilder } from '@components/cateringConcierge'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'
import { camelCaseify } from '~/utils'

const mapStateToProps = (state) => {
  const { headquarter, user } = state

  const { headquarters } = headquarter
  const { hqs } = user

  return {
    headquarters,
    hqs,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const {
    pResponseAccounts,
    pResponseSurveyObjects,
    pRequestOrdersFilter,
    pRequestSurveys,
  } = presenters.Api
  const {
    GetIndexAccountsByIds,
    GetMarketActiveItems,
    LoadDinerProfiles,
    AsyncLoadSurveys,
    SearchAccountingClients,
    SearchOrders,
  } = coordinators

  const getAccountsByIds = GetIndexAccountsByIds({
    RestService,
    pResponseAccounts,
  })
  const getMarketActiveItems = GetMarketActiveItems({
    RestService,
    pResponseMenuItems: camelCaseify,
  })
  const loadDinerProfiles = LoadDinerProfiles({ RestService, UIService })
  const loadSurveys = AsyncLoadSurveys({
    RestService,
    pResponseSurveyObjects,
    pRequestSurveys,
  })
  const searchAccountingClients = SearchAccountingClients({
    RestService,
    pResponseClients: camelCaseify,
  })
  const searchOrders = SearchOrders({
    RestService,
    pRequestOrdersFilter,
    pResponseOrders: camelCaseify,
    HandleError,
  })

  return {
    getAccountsByIds,
    getMarketActiveItems,
    loadDinerProfiles,
    loadSurveys,
    searchAccountingClients,
    searchOrders,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromptBuilder)
