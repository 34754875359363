import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors } from '../../../../constants'

const Button = (props) => {
  const {
    className,
    label,
    width,
    backgroundColor,
    onClick,
    testId,
    margin,
    disabled,
    padding,
  } = props

  return (
    <ButtonStyles
      id={testId}
      data-testId={testId}
      className={className}
      disabled={disabled}
      backgroundColor={backgroundColor}
      padding={padding}
      style={{ width, margin }}
      onClick={onClick}
    >
      {label}
    </ButtonStyles>
  )
}

const ButtonStyles = styled.button`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.blue200};
  color: #fff;
  white-space: nowrap;
  font-family: 'bold';
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  padding: ${(props) => (props.padding ? props.padding : '10px 30px')};
  transition: 0.2s ease-in-out all;
  &:hover {
    filter: brightness(90%);
    color: #fff;
    text-decoration: none;
  }
  &:disabled {
    background: ${colors.gray200};
  }
`

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.any,
  width: PropTypes.string,
  padding: PropTypes.string,
  backgroundColor: PropTypes.string,
  testId: PropTypes.string,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.any,
}

export default Button
