export const GetMarketActiveItems =
  ({ RestService, pResponseMenuItems }) =>
  async (hq) => {
    let finished = false
    const allMenuItems = []
    const resultsPerPage = 300
    let page = 1
    while (!finished) {
      const params = {
        parent_items: true,
        is_approved: true,
        serializer: 'catering_concierge',
        market_types: 'Office',
        chef_status: 'active',
        hqs: hq,
        page,
        results_per_page: resultsPerPage,
      }
      Object.entries(params).forEach(([key, val]) => {
        if (!val || val === '') {
          delete params[key]
        }
      })
      const pageOfItems = await RestService.get(
        '/api/admin/menu_items',
        params,
        {
          timeout: 35000,
        },
      )
      if (!pageOfItems) {
        break
      }
      if (pageOfItems.length !== resultsPerPage) {
        finished = true
      }
      page += 1
      allMenuItems.push(...pageOfItems)
    }

    return pResponseMenuItems(allMenuItems)
  }

export const SearchMenuItems =
  ({ RestService, pResponseMenuItemsSearch }) =>
  async ({
    chefIds,
    search,
    page,
    resultsPerPage,
    marketTypes,
    hqs,
    isApproved,
    chefStatus,
    excludingChefIds,
  }) => {
    const params = {
      parent_items: true,
      serializer: 'search',
      chef_ids: chefIds.join(','),
      search,
      page,
      results_per_page: resultsPerPage,
      market_types: marketTypes.join(','),
      hqs: hqs && hqs.join(','),
      excluding_chef_ids: excludingChefIds && excludingChefIds.join(','),
    }

    if (isApproved !== '') {
      if (isApproved === 'true') {
        params['live_and_approved'] = 'true'
      } else {
        params['not_approved'] = 'true'
      }
    }

    if (chefStatus !== '') {
      params['chef_status'] = chefStatus
    }

    Object.entries(params).forEach(([key, val]) => {
      if (!val || val === '') {
        delete params[key]
      }
    })
    const response = await RestService.get('/api/admin/menu_items', params, {
      timeout: 35000,
    })

    return pResponseMenuItemsSearch(response)
  }

export const BulkUpdateMenuItems =
  ({
    RestService,
    HandleError,
    pResponseMenuItemsSearch,
    pRequestBulkUpdateMenuItems,
  }) =>
  async (data) => {
    try {
      const req = pRequestBulkUpdateMenuItems(data)
      const response = await RestService.put(
        '/api/admin/menu_items/bulk_update',
        req,
        { timeout: 30000 },
      )

      return pResponseMenuItemsSearch(response)
    } catch (error) {
      HandleError({ error })

      return false
    }
  }
