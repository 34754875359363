import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import services from '@services'
import { NavLink } from 'react-router-dom'
import loadGif from '@res/images/loading.gif'

import { CalendarPopup } from '@containers/proposal'

class Calendar extends Component {
  state = {
    proposal: {},
    wWidth: 0,
    wHeight: 0,
    showProposalModal: false,
  }

  setPopupRef = (element) => {
    element && this.setState({ [`popup${element.id}`]: element })
  }

  componentWillMount() {
    const { proposals } = this.props
    this.setState({ proposals })
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  componentWillReceiveProps(nextProps) {
    const { proposal, proposals } = nextProps
    this.setState({ proposal, proposals })
  }

  updateWindowDimensions = () => {
    this.setState({ wWidth: window.innerWidth, wHeight: window.innerHeight })
  }

  onLoadProposal = (p) => {
    this.props.selectProposal(p.id)
    this.setState({
      [`showHover${p.id}`]: true,
      p,
    })
  }

  onSelectProposal = (isChecked, proposalID) => {
    const { checkedProposals } = this.props
    const { proposals } = this.props
    if (isChecked) {
      const index = checkedProposals.findIndex((p) => p.id === proposalID)
      if (index === -1) {
        const proposal = proposals
          .map((p) => p[1].find((x) => x.id === proposalID))
          .filter((x) => x !== undefined)[0]
        checkedProposals.push(proposal)
      }
    } else {
      const index = checkedProposals.findIndex((c) => c.id === proposalID)
      checkedProposals.splice(index, 1)
    }
    services.ProposalService.setCheckedProposals(checkedProposals)
  }

  arrangeProposalItems = (proposal) => {
    const itemsArranged = [
      ...proposal.proposalMenuItems.sort(
        (a, b) => parseFloat(a.displayOrder) - parseFloat(b.displayOrder),
      ),
    ]
    proposal['proposalMenuItems'] = itemsArranged

    return proposal
  }

  generateDays = () => {
    const { month, year, isSmallCalendar, proposals } = this.props
    const firstDayOfMonth = new Date(year, month).getDay()
    const daysInMonth = new Date(year, month + 1, 0).getDate()
    const days = []
    const rows = []
    let cells = []

    const currentMonthProposals = []
    proposals &&
      proposals.map((p) => {
        if (Moment(p.clientSetUpTime).format('M') - 1 == month) {
          currentMonthProposals.push(p)
          // if ( Moment().startOf( 'day' ).add( 2, 'day' ).isSameOrBefore( Moment( p.clientSetUpTime )) && p.orderableType !== 'Order' ) {
          //   currentMonthProposals.push( p )
          // }
          // else if ( p.orderableType === 'Order' ) {
          //   currentMonthProposals.push( p )
          // }
        }
      })

    const getCalendarColor = (p) => {
      if (p.orderNumber) {
        return 'order'
      } else {
        return 'proposal'
      }
    }
    const findMatchingDays = (day) => {
      const matchingDays = []
      currentMonthProposals.filter((p) => {
        if (Moment(p.clientSetUpTime).format('D') == day) {
          if (isSmallCalendar) {
            matchingDays.push(
              <div
                className={`small-calendar-${getCalendarColor(p)} ${
                  p.status === 'final' ? 'bg-green-300' : 'bg-yellow-200'
                } mb-3`}
                key={p.id}
              >
                <p>{Moment(p.clientSetUpTime).format('h:mm a')}</p>
              </div>,
            )
          } else {
            matchingDays.push(
              <NavLink exact to="/proposals">
                <div
                  className="relative"
                  onMouseEnter={() => this.onLoadProposal(p)}
                  onMouseLeave={() =>
                    this.setState({ [`showHover${p.id}`]: false })
                  }
                >
                  <div
                    onClick={() => this.props.selectProposal(p.id)}
                    id={p.id}
                    ref={this.setPopupRef}
                    className={`large-calendar-${getCalendarColor(
                      p,
                    )} mb-5 relative ${
                      p.status === 'final' ? 'bg-green-300' : 'bg-yellow-200'
                    } calendar-date ${
                      this.state[`showHover${p.id}`] && 'calendar-date-hover'
                    }`}
                  >
                    <p className="calendar-time-copy">
                      <p>{Moment(p.clientSetUpTime).format('h:mm a')}</p>
                      <p className="regular">
                        {(p.concept && p.concept.name) ||
                          (p.cuisineType && p.cuisineType) ||
                          p.chefName ||
                          (p.primaryChef && p.primaryChef.name)}
                      </p>
                      <p className="regular">{p.accountName}</p>
                    </p>
                    {this.state[`showHover${p.id}`] && (
                      <p className="calendar-edit">
                        <span className="calendar-plus">+</span>
                        <p>{p.orderNumber ? 'View Menu' : 'Edit Menu'}</p>
                      </p>
                    )}
                  </div>
                  {this.state[`showHover${p.id}`] && (
                    <div
                      id="calendarHover"
                      className="calendar-hover"
                      style={this.getDimensions(p.id)}
                    >
                      <CalendarPopup
                        showModal={() => this.onEditProposal(p)}
                        onSelectProposal={this.onSelectProposal}
                      />
                    </div>
                  )}
                </div>
              </NavLink>,
            )
          }
        }
      })

      return matchingDays
    }
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(
        <td className={isSmallCalendar ? 'td-small' : 'td-large'}>
          <div />
        </td>,
      )
    }
    for (let i = 1; i < daysInMonth + 1; i++) {
      days.push(
        <td className={isSmallCalendar ? 'td-small' : 'td-large'}>
          <p className="calendar-date">{i}</p>
          {findMatchingDays(i)}
        </td>,
      )
    }
    days.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }
      if (i === days.length - 1) {
        for (let i = cells.length; i < 7; i++) {
          cells.push(
            <td className={isSmallCalendar ? 'td-small' : 'td-large'}>
              <div />
            </td>,
          )
        }
        rows.push(cells)
      }
    })

    return rows.map((d, i) => <tr key={i}>{d}</tr>)
  }

  selectCalendar = () => {
    const { year, month, isSmallCalendar, selectMonth } = this.props
    if (isSmallCalendar) {
      return selectMonth(month, year)
    } else {
      return false
    }
  }

  getDimensions = (id) => {
    const { wWidth, wHeight } = this.state
    const css = {}
    const elTop =
      this.state[`popup${id}`] &&
      this.state[`popup${id}`].getBoundingClientRect().top
    const elLeft =
      this.state[`popup${id}`] &&
      this.state[`popup${id}`].getBoundingClientRect().left
    const elWidth =
      this.state[`popup${id}`] &&
      this.state[`popup${id}`].getBoundingClientRect().width
    if (elLeft + elWidth + 500 > wWidth) {
      // position to the left
      css.marginLeft = -500
      css.paddingLeft = 20
    }
    if (elLeft + elWidth + 500 < wWidth) {
      // position to the right
      css.paddingLeft = '20px'
      css.marginLeft = elWidth
    }

    if (wHeight - elTop < 600) {
      css.top = -350
    }

    return css
  }

  renderHeadingToggles = () => {
    const { year, month, selectMonth } = this.props

    return (
      <div className="month-toggle mb-4">
        <div className="w-50">
          <span
            onClick={() => selectMonth(month - 1, year)}
            className="next-arrow mr-10"
          >
            ◄
          </span>
        </div>
        <h1 className="big-calendar-title">
          {Moment(new Date(year, month)).format('MMMM')} {year}
        </h1>
        <div className="w-50">
          <span
            onClick={() => selectMonth(month + 1, year)}
            className="next-arrow ml-10"
          >
            ►
          </span>
        </div>
      </div>
    )
  }

  render() {
    const { isLoading, isSmallCalendar, month, year } = this.props

    return (
      <div className={isSmallCalendar ? 'm-2 w-full relative' : 'relative'}>
        {isSmallCalendar ? (
          <h1
            onClick={() => this.selectCalendar()}
            className="pointer mb-3 small-calendar-title"
          >
            {Moment(new Date(year, month)).format('MMMM')} {year}
          </h1>
        ) : (
          this.renderHeadingToggles()
        )}
        {isLoading && (
          <img className="calendar-loader" src={loadGif} alt="loader" />
        )}
        <table
          className={isSmallCalendar ? 'small-calendar' : 'big-calendar'}
          onClick={() => this.selectCalendar()}
        >
          <tr className="calendar-tr">
            <th>Sun</th>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
            <th>Sat</th>
          </tr>
          {this.generateDays()}
        </table>
      </div>
    )
  }
}

Calendar.propTypes = {
  checkedProposals: PropTypes.array,
  isSmallCalendar: PropTypes.bool,
  isLoading: PropTypes.bool,
  month: PropTypes.string,
  year: PropTypes.string,
  orders: PropTypes.arrayOf(PropTypes.object),
  proposal: PropTypes.object,
  proposals: PropTypes.arrayOf(PropTypes.object),
  startDate: PropTypes.object,

  calculateTotal: PropTypes.func,
  selectMonth: PropTypes.func,
  setProposal: PropTypes.func,
  selectProposal: PropTypes.func,
  loadCalendarProposal: PropTypes.func,
}

export default Calendar
