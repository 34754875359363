import React, { useEffect, useState } from 'react'
import moment, { Moment } from 'moment-timezone'
import Modal from '@components/common/modal/Modal'
import { AutocompleteInput } from '@containers/common/form'
import { OrderableConfirmationModal } from '@components/common/modal'
import DividerLine from '@components/common/DividerLine'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'
import Table from '@components/common/Table'
import Loader from '@components/common/Loader'
import { colors } from '../../../../constants'
import {
  Button,
  Checkbox,
  DateInput,
  Dropdown,
  Input,
} from '@components/common/form'
import {
  Account,
  CateringOrder,
  Chef,
  Orderable,
  OrderableConfirmationParamaters,
  OrderableRequestResult,
  User,
} from '@types'

interface Filters {
  accountExecutiveId: string | undefined
  contactId: string | undefined
  chefId: string | undefined
  dropoffAddressId: string | undefined
  resultsPerPage: number
  search: string | undefined
  accountId: string | undefined
  serializer: string
  fromDate: undefined | Moment
  toDate: undefined | Moment
}

interface LinkDinerProfileToPastOrdersModalProps {
  account: Account
  callAfterTimeout: (fn: () => void) => void
  dinerProfileMap: Record<string, string>
  hqLocaleMap: Record<string, string>
  hideModal: () => void
  confirmationModal: {
    show: (val: { text: string }) => Promise<boolean>
  }
  loadChefs: (val: { search: string }) => Chef[]
  loadSalesReps: (val: { search: string }) => User[]
  searchOrders: (filters: Filters) => Promise<CateringOrder[]>
  updateOrdersDinerProfileId: (
    hqLocaleMap: Record<number, string>,
    params: OrderableConfirmationParamaters[],
    callbackFn: (
      result: Orderable | null,
      index: number,
      error?: string,
    ) => void,
  ) => Orderable
  uri: string
}

const LinkDinerProfileToPastOrdersModal = ({
  account,
  confirmationModal,
  callAfterTimeout,
  dinerProfileMap,
  hqLocaleMap,
  hideModal,
  loadChefs,
  searchOrders,
  loadSalesReps,
  updateOrdersDinerProfileId,
  uri,
}: LinkDinerProfileToPastOrdersModalProps) => {
  const [dinerProfileId, setDinerProfileId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [orders, setOrders] = useState<CateringOrder[]>([])
  const [selectedOrderMap, setSelectedOrderMap] = useState<
    Record<string, CateringOrder | undefined>
  >({})
  const [filters, setFilters] = useState<Filters>({
    accountExecutiveId: undefined,
    contactId: undefined,
    chefId: undefined,
    dropoffAddressId: undefined,
    resultsPerPage: 20,
    search: undefined,
    accountId: account.id,
    serializer: 'for_account',
    fromDate: undefined,
    toDate: undefined,
  })
  const [showOrderableConfirmationModal, setShowOrderableConfirmationModal] =
    useState(false)
  const [results, setResults] = useState<(OrderableRequestResult | string)[]>(
    [],
  )
  const [reqsLoading, setReqsLoading] = useState<boolean[]>([])

  const mergeWithSelectedOrders = (
    fetchedOrders: CateringOrder[],
    selectedOrderMap: Record<string, CateringOrder | undefined>,
  ) => {
    const fetchedOrderIds = new Set(fetchedOrders.map((order) => order.id))

    const selectedOrders = Object.values(selectedOrderMap)
      .filter(
        (selectedOrder): selectedOrder is CateringOrder =>
          selectedOrder !== undefined,
      )
      .filter((selectedOrder) => !fetchedOrderIds.has(selectedOrder.id))

    return [...fetchedOrders, ...selectedOrders]
  }

  const loadOrders = async (
    filters: Filters,
    selectedOrderMap: Record<string, CateringOrder | undefined>,
  ) => {
    setIsLoading(true)
    const fetchedOrders = await searchOrders(filters)
    if (fetchedOrders) {
      const combinedOrders = mergeWithSelectedOrders(
        fetchedOrders,
        selectedOrderMap,
      )
      setOrders(combinedOrders)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    loadOrders(filters, selectedOrderMap)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id])

  const delayedSearchOrders = (filters: Filters) => {
    callAfterTimeout(() => loadOrders(filters, selectedOrderMap))
  }

  const updateFilters = (newFilterValues: Partial<Filters>) => {
    const newFilters = { ...filters, ...newFilterValues }
    setFilters(newFilters)
    delayedSearchOrders(newFilters)
  }

  const handleSelectAll = () => {
    const allSelected =
      orders.length === Object.values(selectedOrderMap).filter((i) => i).length
    if (allSelected) {
      setSelectedOrderMap({})
    } else {
      const newSelectedOrderMap: Record<string, CateringOrder> = {}
      orders.forEach((o) => {
        newSelectedOrderMap[o.id] = o
      })
      setSelectedOrderMap(newSelectedOrderMap)
    }
  }

  const updateOrders = (params: OrderableConfirmationParamaters[]) => {
    setReqsLoading(Array(params.length).fill(true))
    setResults(Array(params.length).fill(null))

    updateOrdersDinerProfileId(
      hqLocaleMap,
      params,
      (result: OrderableRequestResult, index: number, error?: string) => {
        setResults((prevResults) => {
          const updatedResults = [...prevResults]
          updatedResults[index] = error ? error : result

          return updatedResults
        })

        setReqsLoading((prevLoading) => {
          const updatedLoading = [...prevLoading]
          updatedLoading[index] = false

          return updatedLoading
        })
      },
    )
  }

  const handleAddToDinerProfile = async () => {
    const message = Object.values(selectedOrderMap)
      .filter((i): i is CateringOrder => i !== undefined)
      .map(
        (o) =>
          `<strong>${moment(o.clientSetUpTime).format('dddd, MMMM, do')} - ${
            o.orderNumber
          }</strong>`,
      )
      .join(' <br />')
    const confirmed = await confirmationModal.show({
      text: `Are you sure you want to add the following orders to the Diner Profile: <strong>${dinerProfileMap[dinerProfileId]}</strong> <br /> ${message}`,
    })
    if (confirmed) {
      const params = Object.keys(selectedOrderMap)
        .filter((orderId) => selectedOrderMap[orderId])
        .map((orderId) => ({
          orderId,
          dinerProfileId,
        }))
      setShowOrderableConfirmationModal(true)
      updateOrders(params)
    }
  }

  const renderOrder = (order: CateringOrder) => {
    const {
      accountExecutive,
      contact,
      dinerProfileId,
      dropoffAddress,
      orderNumber,
      chefName,
      total,
      dateMoment,
      headCount,
    } = order

    return (
      <tr key={order.id}>
        <td>
          <Checkbox
            checked={selectedOrderMap[order.id]}
            onChange={() =>
              setSelectedOrderMap({
                ...selectedOrderMap,
                [order.id]: selectedOrderMap[order.id] ? undefined : order,
              })
            }
          />
        </td>
        <td>{orderNumber}</td>
        <td>{dateMoment && dateMoment.format('MMM Do YYYY')}</td>
        <td>{dateMoment && dateMoment.format('h:mm:ss a')}</td>
        <td>{headCount}</td>
        <td>
          <strong>
            {dinerProfileId
              ? `Yes: ${dinerProfileMap[dinerProfileId]}`
              : 'No Diner Profile'}
          </strong>
        </td>
        <td>{contact.name}</td>
        <td>
          {dropoffAddress.line1 +
            ' ' +
            dropoffAddress.city +
            ' ' +
            dropoffAddress.state +
            ' ' +
            dropoffAddress.zip}
        </td>
        <td>{chefName}</td>
        <td>{accountExecutive.firstName + ' ' + accountExecutive.lastName}</td>
        <td>${total}</td>
      </tr>
    )
  }

  if (showOrderableConfirmationModal) {
    return (
      <OrderableConfirmationModal
        isUpdate={true}
        orderable="Order"
        orderableType="sales"
        params={Object.keys(selectedOrderMap)
          .filter((orderId) => selectedOrderMap[orderId])
          .map((orderId) => ({
            orderId,
            dinerProfileId: dinerProfileId,
          }))}
        results={results}
        reqsLoading={reqsLoading}
        uri={uri}
        onClose={hideModal}
        errorLinks={[]}
        successLinks={[]}
      />
    )
  }
  const sortedContacts = (account.contacts || []).sort((a, b) => {
    return (
      (a.firstName || '').localeCompare(b.firstName || '') ||
      (a.lastName || '').localeCompare(b.lastName || '')
    )
  })

  return (
    <Modal
      title="Add Past Orders to Diner Profile"
      hideModal={hideModal}
      height="950px"
      width="1200px"
    >
      <Dropdown
        label="*Select Diner Profile"
        value={dinerProfileId}
        onChange={(e) => setDinerProfileId(e.target.value)}
        width="20%"
      >
        <option />
        {Object.keys(dinerProfileMap || {}).map((dpId) => (
          <option key={dpId} value={dpId}>
            {dinerProfileMap[dpId]}
          </option>
        ))}
      </Dropdown>
      {dinerProfileId && (
        <div className="flex flex-col">
          <DividerLine margin="20px 0" />
          <div className="flex justify-start">
            <Input
              type="text"
              pattern="[0-9]*"
              marginBottom="0"
              label="Order Number"
              value={undefined}
              onChange={(e) => updateFilters({ search: e.target.value })}
              width="20%"
            />
            <XSpacing width="30px" />
            <DateInput
              date={filters.fromDate}
              label="From"
              onChange={(value: Moment) => updateFilters({ fromDate: value })}
              clearDate={() => updateFilters({ fromDate: undefined })}
              width="20%"
            />
            <XSpacing width="30px" />
            <DateInput
              date={filters.toDate}
              label="To"
              onChange={(value: Moment) => updateFilters({ toDate: value })}
              clearDate={() => updateFilters({ toDate: undefined })}
              width="20%"
            />
            <XSpacing width="30px" />
            <Dropdown
              label="Contact"
              width="20%"
              value={filters.contactId}
              onChange={(e) => updateFilters({ contactId: e.target.value })}
            >
              <option />
              {sortedContacts.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </Dropdown>
          </div>
          <YSpacing height="10px" />
          <div className="flex justify-start">
            <Dropdown
              label="Dropoff Address"
              value={filters.dropoffAddressId}
              width="20%"
              onChange={(e) =>
                updateFilters({ dropoffAddressId: e.target.value })
              }
            >
              <option />
              {account.addresses.map((a) => (
                <option key={a.id} value={a.id}>
                  {a.line1 + ' ' + a.city + ' ' + a.state + ' ' + a.zip}
                </option>
              ))}
            </Dropdown>
            <XSpacing width="30px" />
            <AutocompleteInput
              label="Chef"
              width="20%"
              loaderFunction={(search) => loadChefs({ ...search })}
              onSelect={(value) => updateFilters({ chefId: value.id })}
            />
            <XSpacing width="30px" />
            <AutocompleteInput
              label="Sales Rep"
              width="20%"
              loaderFunction={(search) => loadSalesReps({ ...search })}
              onSelect={(value) =>
                updateFilters({ accountExecutiveId: value.id })
              }
            />
          </div>
          <YSpacing height="15px" />
          <Table>
            <tr>
              <th className="flex flex-align-center">
                Select All
                <Checkbox
                  checked={
                    orders.length ===
                      Object.values(selectedOrderMap).filter((i) => i).length &&
                    orders.length > 0
                  }
                  onChange={handleSelectAll}
                />
              </th>
              <th>Number</th>
              <th>Event Date</th>
              <th>Time</th>
              <th>HeadCount</th>
              <th>Diner Profile</th>
              <th>Contact Name</th>
              <th>Dropoff Address</th>
              <th>Chef</th>
              <th>Sales Rep</th>
              <th>Total</th>
            </tr>
            {isLoading ? <Loader /> : (orders || []).map((o) => renderOrder(o))}
          </Table>
          <YSpacing height="10px" />
          <div className="flex justify-end w-full">
            <Button
              label="Add to Diner Profile"
              onClick={handleAddToDinerProfile}
              disabled={
                Object.values(selectedOrderMap).filter((i) => i).length < 1
              }
            />
            <XSpacing width="15px" />
            <Button
              label="Cancel"
              backgroundColor={colors.violet}
              onClick={hideModal}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}

export default LinkDinerProfileToPastOrdersModal
