import { connect } from 'react-redux'

import { InvoicesModal } from '@components/invoice'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const { user = {}, headquarter = {}, router } = state
  const { lastLoggedInHq } = user
  const { userHeadquarters = [] } = headquarter
  const userHeadquarter =
    userHeadquarters.find((hq) => hq.id === lastLoggedInHq) || {}
  const port = router.location.port === 3000 ? 3001 : 3000
  const uri =
    config.admin_url +
    (config.admin_url === 'http://localhost' ? `:${port}` : '')

  return {
    headquarter: userHeadquarter.id,
    locale: userHeadquarter.locale,
    uri,
    user,
  }
}

const mapDispatchToProps = () => {
  const {
    pRequestUpdateInvoice,
    pResponseAccountExecutives,
    pResponseGeneric,
    pResponseInvoiceAccount,
    pResponseInvoiceAccounts,
    pAccountingOrderType,
  } = presenters.Api
  const { VXInvoiceService, RestService, UIService } = services
  const {
    CheckInvoiceTxnStatuses,
    GetAccountingClient,
    LoadInvoiceAccount,
    LoadAccountExecutives,
    PayInvoice,
    SearchDetailInvoiceAccounts,
    SaveInvoice,
    SearchAccountingOrders,
    SearchAccountingOrderPageCount,
  } = coordinators
  const { newInvoice, newOrderPayment, newRemovedPayment, newBuyer } =
    VXInvoiceService

  const checkInvoiceTxnStatuses = CheckInvoiceTxnStatuses({
    RestService,
    HandleError,
  })

  const getAccountingClient = GetAccountingClient({
    RestService,
    pResponseEditClientAccounting: pResponseGeneric,
  })

  const getClient = LoadInvoiceAccount({
    RestService,
    pResponseInvoiceAccount,
  })

  const payInvoice = PayInvoice({
    RestService,
    UIService,
    HandleError,
  })

  const saveInvoice = SaveInvoice({
    RestService,
    UIService,
    pRequestUpdateInvoice,
    HandleError,
  })

  const searchClients = SearchDetailInvoiceAccounts({
    RestService,
    pResponseInvoiceAccounts,
  })

  const searchSalesReps = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })(true) // is not vcx

  const searchOrders = SearchAccountingOrders({ RestService, HandleError })
  const searchOrderPageCount = SearchAccountingOrderPageCount({
    RestService,
    HandleError,
  })

  const { FlashMessage, LoadingIndicator, ConfirmationModal } = UIService
  const { displayWarningMessage } = FlashMessage

  return {
    checkInvoiceTxnStatuses,
    confirmationModal: ConfirmationModal,
    displayWarningMessage,
    getOrderType: pAccountingOrderType,
    getAccountingClient,
    getClient,
    loadingIndicator: LoadingIndicator,
    newInvoice,
    newOrderPayment,
    newBuyer,
    newRemovedPayment,
    payInvoice,
    saveInvoice,
    searchClients,
    searchSalesReps,
    searchOrders,
    searchOrderPageCount,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesModal)
