export const TaxStatuses = [
  { name: 'None', value: null },
  { name: 'Tax Exempt', value: false },
  { name: 'Taxable', value: true },
]

export const colors = {
  violet: '#ee346d',
  orange: '#ff624d',
  blue: '#0288d1',

  blue100: '#80deea',
  blue200: '#00acc1',
  blue300: '#0097a7',
  blue400: '#008695',
  /******** Grays **********/
  gray100: '#eff1f4',
  gray200: '#d1d1d1',
  gray300: '#516477',
  gray400: '#3d4853',
  dark500: '#303131',

  /******** Greens **********/
  green100: '#35ff69',
  green200: '#3fe169',
  green300: '#3dc573',
}

export const HUNGRY_PERC = 0.2
export const HUNGRY_CARBON_NEUTRAL_CONTRIBUTION = 10.0

export const INVALID_ACCOUNT_DOMAINS =
  /tryhungry|gmail|proton|yahoo|outlook|hotmail/

// Client Profile Constants
export const INCUMBENT_OPTIONS = [
  'Aramark',
  'Cater2.me',
  'Compass Group',
  'DoorDash',
  'EZCater',
  'Fooda',
  'GrubHub',
  'Local Caterer',
  'Seamless',
  'UberEats',
  'ZeroCater',
  'Other',
]

export const FIRST_ORDER_DATE_TYPES = [
  'Trial Launch Date',
  'Full Launch Date',
  'Re-Launch Date',
]

export const DEFAULT_GRATUITY_TYPE_FIXED = 'Fixed'
export const DEFAULT_GRATUITY_TYPE_PERCENT = 'Percent'

export const DEFAULT_GRATUITY_TYPES = [
  DEFAULT_GRATUITY_TYPE_FIXED,
  DEFAULT_GRATUITY_TYPE_PERCENT,
]

export const AVERAGE_PRICE_PER_PERSON_TYPE_ALL_IN = 'All-in'
export const AVERAGE_PRICE_PER_PERSON_TYPE_PRE_TAX = 'Pre-tax'

export const AVERAGE_PRICE_PER_PERSON_TYPES = [
  AVERAGE_PRICE_PER_PERSON_TYPE_ALL_IN,
  AVERAGE_PRICE_PER_PERSON_TYPE_PRE_TAX,
]

// Work around for flat fee is to set percent to 10000 and cap to the flat fee
// *** Changes to defaults here need to be made in the backend as well
export const DEFAULT_DELIVERY_FEE_PERCENT_HOLIDAY = 20
export const DEFAULT_DELIVERY_FEE_PERCENT_WEEKEND = 20
export const DEFAULT_DELIVERY_FEE_PERCENT_WEEKDAY = 12.5
export const DEFAULT_DELIVERY_FEE_CAP_HOLIDAY = null
export const DEFAULT_DELIVERY_FEE_CAP_WEEKEND = null
export const DEFAULT_DELIVERY_FEE_CAP_WEEKDAY = null

export const DEFAULT_CATERING_STAFF_RATE = 50
export const DEFAULT_CLEANUP_FEE = 50.0

export const HolidayDates = {
  '1/2': true, // new years
  '1/15': true, // mlk
  '2/19': true, // presidents day
  '5/27': true, // memorial day
  '6/19': true, // juneteenth
  '7/4': true, // july4th
  '9/2': true, // labor day
  '10/14': true, // columbus day
  '11/11': true, // veterans day
  '11/28': true, // thanksgiving day
  '12/25': true, // christmas day
}

export const NetPaymentDays = {
  0: 'Due on Receipt / None',
  7: 'Net 7',
  14: 'Net 14',
  30: 'Net 30',
  45: 'Net 45',
  60: 'Net 60',
  90: 'Net 90',
}
export const AutoBatchPeriods = {
  0: 'None',
  1: 'Daily',
  7: 'Weekly',
  14: 'Biweekly',
  30: 'Monthly',
}

export const PAY_BY_ACH = 'Pay By ACH'
export const PAY_BY_CC = 'Pay By CC'
export const PAY_BY_CHECK = 'Pay By Check'

export const ARRoles = ['master admin', 'sales rep', 'sales lead', 'finance']
export const ARRolesRails = [
  'admin',
  'salesRep',
  'salesLead',
  'masterSalesRep',
  'finance',
]

// Order Sections
export const SECTION1 = 'SECTION1'
export const SECTION2 = 'SECTION2'
export const SECTION3 = 'SECTION3'
export const NONFINANCEEDIT = 'NONFINANCEEDIT'

// Menu Details Section modes
export const MENU_DETAILS_SECTION_MODE_VIEW = 'view'
export const MENU_DETAILS_SECTION_MODE_SELECT = 'select'

// Concept Types
export const MAIN = 'Main'
export const PROTEIN = 'Protein'
export const VEGETABLE_MAIN = 'Vegetable Main'
export const SIDE = 'Side'
export const STARCH_SIDE = 'Starch Side'
export const VEGETABLE_SIDE = 'Vegetable Side'
export const ADD_ON = 'Add On'
export const BUILD_YOUR_OWN = 'build your own'
export const ACH_CHECK = 'ACH-CHECK'
export const SETTLED = 'SETTLED'
export const ZERO_TIME = '0001-01-01T00:00:00Z'

// Chef Schedule
export const RECURRING = 'recurring'

// Chef Emails
export const MISSED_CHEF_EMAIL = 'MISSED - NOT SENT'
export const MISSED_CHEF_EMAIL_BG_COLOR = 'red'
export const CHEF_EMAIL_GO_DASHBOARD_COLUMN = 'Menu Chef Email Sent At'

// Chef Acceptance Status
export const CHEF_DECLINED_ORDER = 'Declined'
export const CHEF_DECLINED_ORDER_BG_COLOR = 'red'
export const CHEF_ACCEPTANCE_COLUMN = 'Chef Order Acceptance Status'

//Concept Pre-Populate Menu Item Quantities Percent Multiplier
export const BYO_MAIN_ITEM_PERCENT = 0.5
export const BYO_MAIN_CHILD_ITEM_PERCENT = 0.5
export const BYO_CHILD_ITEM_PERCENT = 1.2
export const MAIN_VEG_PROTEIN_ITEM_PERCENT = 0.2
export const MAIN_PROTEIN_ITEM_PERCENT = 0.5
export const SIDE_ITEM_PERCENT = 0.75

//Concept Types:
export const MarketTypes = {
  OFFICE: 'Office',
  GROUP_ORDER: 'Group Order',
  POP_UP: 'Pop-up',
}

export const MaxImageSizeKB = 120

export const GOrderCutoffTypes = {
  STANDARD: 'Standard',
  CUSTOM: 'Custom',
}

export const AllMpCalculationTypes = [
  { id: 'perPerson', value: 'PerPerson', label: 'Price per Person' },
  { id: 'perPackage', value: 'PerPackage', label: 'Price per Package' },
]

export const DefaultChefProcessingFee = 0.03

export const LESS_THAN_PREFIX = 'lt_'
export const GREATER_THAN_PREFIX = 'gt_'
export const LESS_THAN_CUSTOM = 'lt_custom'
export const GREATER_THAN_CUSTOM = 'gt_custom'
export const TEN_TO_FIFTEEN_RANGE = 'TEN_TO_FIFTEEN'
export const CLIENT_ID_NAME_FIELD = 'clientId_name'

export const ServingWeights = [
  {},
  { id: '4oz', value: 4, label: '4 oz' },
  { id: '6oz', value: 6, label: '6 oz' },
  { id: '8oz', value: 8, label: '8 oz' },
]
