import {
  buildInvoiceNumberMap,
  getOrderBals,
  getOrderBatchInvPayments,
  calcOrderTotalPaidOrBatchedAmt,
} from '@utils'
import { AccountingOrder } from '@types'

interface RestService {
  get: (url: string) => Promise<AccountingOrder>
  put: (url: string, params: any) => Promise<any>
}

interface FlashMessageService {
  displaySuccessMessage: (message: string) => void
  displayWarningMessage: (message: string) => void
}

interface UIService {
  FlashMessage: FlashMessageService
}

export const CheckBatchInvoicedBalances =
  ({
    RestService,
    UIService,
    HandleError,
  }: {
    RestService: RestService
    UIService: UIService
    HandleError: (params: { error: any }) => void
  }) =>
  async (orderId: string) => {
    try {
      const postSaveAccountingOrder = await RestService.get(
        `/accounting/orders/${orderId}`,
      )
      const { total: orderTotal } = getOrderBals(postSaveAccountingOrder)
      const batchInvPayments = getOrderBatchInvPayments(postSaveAccountingOrder)
      const totalPaidOrInv = calcOrderTotalPaidOrBatchedAmt(
        postSaveAccountingOrder,
      )

      // Alert user if order has any batch invoices and is not fully invoiced
      if (batchInvPayments.length > 0 && totalPaidOrInv !== orderTotal) {
        const invNumberMap = buildInvoiceNumberMap(batchInvPayments)
        const billingStatus =
          totalPaidOrInv > orderTotal ? 'over-invoiced' : 'under-invoiced'
        UIService.FlashMessage.displayWarningMessage(
          `Order ${
            postSaveAccountingOrder.orderNumber
          } is ${billingStatus} on the following batch invoice(s): ${Object.values(
            invNumberMap,
          ).join(
            ', ',
          )}. Please make sure to make any batch invoice adjustments and resend to the client if necessary so that the client is fully billed for this order.`,
        )
      }
    } catch (error) {
      HandleError({ error })
    }
  }
