/* eslint-disable max-len */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FlexContainer from '@components/common/FlexContainer'
import Checkbox from '@components/common/form/Checkbox'
import Dropdown from '@components/common/form/Dropdown'
import Input from '@components/common/form/Input'
import Button from '@components/common/form/Button'

import { IconButton } from '@res/styledComponents/index'

import { FiArrowRight, FiArrowDown } from 'react-icons/fi'

import {
  GROUP_ORDER_ITEM_TYPE_ENTREE,
  GROUP_ORDER_SUBSIDY_TYPE_FIXED,
  GROUP_ORDER_SUBSIDY_TYPE_PERCENT,
  GROUP_ORDER_SUBSIDY_TYPE_REVERSE,
  GROUP_ORDER_SUBSIDY_TYPE_HUNGRY_WALLET,
  GROUP_ORDER_SUBSIDY_TYPE_FLAT_PRICE,
  GroupOrderSubsidyTypes,
  GroupOrderItemPriceSettingsType,
} from './constants'

import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'

const WEEK = ['SU', 'M', 'T', 'W', 'TH', 'F', 'S']

class SubsidySettings extends Component {
  state = {
    itemPriceType: GROUP_ORDER_ITEM_TYPE_ENTREE,
    itemBudgetType: GROUP_ORDER_ITEM_TYPE_ENTREE,
  }

  addItemSettings = (index, settingType, itemPriceSettings) => {
    const { onChangeSubsidySettings } = this.props

    const itemPriceType =
      settingType === 'prices'
        ? this.state.itemPriceType
        : this.state.itemBudgetType

    onChangeSubsidySettings(index, 'itemPriceSettings', {
      ...itemPriceSettings,
      [settingType]: {
        ...itemPriceSettings[settingType],
        [itemPriceType]: {
          itemType: itemPriceType,
        },
      },
    })
  }

  removeItemSettings = (
    index,
    settingType,
    itemPriceType,
    itemPriceSettings,
  ) => {
    const { onChangeSubsidySettings } = this.props
    const { [itemPriceType]: _, ...updatedItemPriceSetting } =
      itemPriceSettings[settingType]

    onChangeSubsidySettings(index, 'itemPriceSettings', {
      ...itemPriceSettings,
      [settingType]: {
        ...updatedItemPriceSetting,
      },
    })
  }

  onChangeSettings = (index, field, value) => {
    const { onChangeSubsidySettings } = this.props

    onChangeSubsidySettings(index, field, value)
  }

  onChangeScheduleSettings = (index, scheduleIndex, value, schedule) => {
    const { onChangeSubsidySettings } = this.props

    const copySchedule = [...schedule]

    if (value) {
      copySchedule.push(scheduleIndex)
    } else {
      const i = schedule.indexOf(scheduleIndex)
      copySchedule.splice(i, 1)
    }

    onChangeSubsidySettings(index, 'days', copySchedule)
  }

  onChangeItemPriceSettings = (
    index,
    settingType,
    itemPriceType,
    field,
    value,
    itemPriceSettings,
  ) => {
    const { onChangeSubsidySettings } = this.props

    onChangeSubsidySettings(index, 'itemPriceSettings', {
      ...itemPriceSettings,
      [settingType]: {
        ...itemPriceSettings[settingType],
        [itemPriceType]: {
          ...itemPriceSettings[settingType][itemPriceType],
          [field]: value,
        },
      },
    })
  }

  render() {
    const {
      subsidy,
      index,
      isExpanded,
      onRemoveSubsidySettings,
      onToggleSubsidySettings,
    } = this.props

    const { itemPriceType, itemBudgetType } = this.state

    const {
      isActive,
      fullySubsidized,
      limitPurchase,
      perOrderSubsidy,
      subsidyPercentLimitAmt,
      subsidyType,
      isSubsidyPercentLimit,
      days,
      itemPriceSettings,
    } = subsidy

    const scheduleMap = (days || []).reduce((map, element) => {
      map[element] = true

      return map
    }, {})

    const priceSettings = itemPriceSettings || {}

    const priceSettingsBudgets = (priceSettings && priceSettings.budgets) || {}
    const priceSettingsPrices = (priceSettings && priceSettings.prices) || {}

    return (
      <FlexContainer justifyContent="space-between" flexDirection="column">
        <YSpacing height="10px" />
        <FlexContainer
          justifyContent="space-between"
          flexDirection="row"
          background="#c9c9c9"
          padding="10px 0px 10px 10px"
        >
          <FlexContainer justifyContent="space-between" flexDirection="column">
            <p>
              <span className="bold">{`SUBSIDY ${index + 1}`}</span>
            </p>
            <YSpacing height="5px" />
            <FlexContainer justifyContent="space-between" flexDirection="row">
              <p>
                <span className="bold">{'TYPE: '}</span>
                {subsidyType}
              </p>
              <p>
                <span className="bold">{'FULLY SUBSIDIZED?: '}</span>
                {fullySubsidized ? 'Yes' : 'No'}
              </p>
            </FlexContainer>
            <FlexContainer justifyContent="space-between" flexDirection="row">
              <p>
                <span className="bold">{'ACTIVE?: '}</span>
                {isActive ? 'Yes' : 'No'}
              </p>
              <p>
                <span className="bold">{'LIMIT PURCHASE?: '}</span>
                {limitPurchase ? 'Yes' : 'No'}
              </p>
            </FlexContainer>
            <p>
              <span className="bold">{'SCHEDULED FOR: '}</span>
              {WEEK.filter((d, i) => scheduleMap[i]).join(', ')}
            </p>
          </FlexContainer>
          <IconButton
            id={index}
            onClick={() => onToggleSubsidySettings(index)}
            data-testId="subsidy-box-open-close"
          >
            {isExpanded ? <FiArrowDown /> : <FiArrowRight />}
          </IconButton>
        </FlexContainer>
        <YSpacing height="10px" />
        {isExpanded ? (
          <FlexContainer justifyContent="space-between" flexDirection="column">
            <YSpacing height="10px" />
            <Checkbox
              label="Fully Subsidized?"
              testId={'subsidy-fully-subsidized'}
              marginBottom="20px"
              value={fullySubsidized}
              checked={fullySubsidized}
              onChange={(e) =>
                this.onChangeSettings(
                  index,
                  'fullySubsidized',
                  e.target.checked,
                )
              }
            />
            <Checkbox
              label="Subsidy Active?"
              marginBottom="20px"
              testId={'subsidy-active'}
              value={isActive}
              checked={isActive}
              onChange={(e) =>
                this.onChangeSettings(index, 'isActive', e.target.checked)
              }
            />
            {!fullySubsidized && (
              <FlexContainer
                justifyContent="space-between"
                flexDirection="column"
              >
                <Checkbox
                  label="Limit Purchase?"
                  value={limitPurchase}
                  checked={limitPurchase}
                  onChange={(e) =>
                    this.onChangeSettings(
                      index,
                      'limitPurchase',
                      e.target.checked,
                    )
                  }
                />
                <YSpacing height="10px" />
                <FlexContainer justifyContent="space-between">
                  <Dropdown
                    label="Subsidy Type"
                    width="48%"
                    value={subsidyType}
                    onChange={(e) =>
                      this.onChangeSettings(
                        index,
                        'subsidyType',
                        e.target.value,
                      )
                    }
                  >
                    {GroupOrderSubsidyTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </Dropdown>
                  {[
                    GROUP_ORDER_SUBSIDY_TYPE_FIXED,
                    GROUP_ORDER_SUBSIDY_TYPE_PERCENT,
                  ].includes(subsidyType) && (
                    <Input
                      label={`Subsidy per order ${
                        [GROUP_ORDER_SUBSIDY_TYPE_PERCENT].includes(subsidyType)
                          ? '(%)'
                          : '($)'
                      }`}
                      marginBottom="20px"
                      width="48%"
                      type="number"
                      value={perOrderSubsidy}
                      onChange={(e) =>
                        this.onChangeSettings(
                          index,
                          'perOrderSubsidy',
                          parseFloat(e.target.value),
                        )
                      }
                    />
                  )}
                </FlexContainer>
                {[GROUP_ORDER_SUBSIDY_TYPE_PERCENT].includes(subsidyType) && (
                  <FlexContainer
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Input
                      disabled={!isSubsidyPercentLimit}
                      label={'Subsidy Limit ($)'}
                      marginBottom="20px"
                      width="48%"
                      type="number"
                      value={subsidyPercentLimitAmt}
                      onChange={(e) =>
                        this.onChangeSettings(
                          index,
                          'subsidyPercentLimitAmt',
                          parseFloat(e.target.value),
                        )
                      }
                    />
                    <XSpacing width="10px" />
                    <Checkbox
                      label="Limit Subsidy?"
                      value={isSubsidyPercentLimit}
                      checked={isSubsidyPercentLimit}
                      onChange={(e) =>
                        this.onChangeSettings(
                          index,
                          'isSubsidyPercentLimit',
                          e.target.checked,
                        )
                      }
                    />
                  </FlexContainer>
                )}
                {[GROUP_ORDER_SUBSIDY_TYPE_REVERSE].includes(subsidyType) && (
                  <FlexContainer
                    justifyContent="space-between"
                    flexDirection="column"
                  >
                    <FlexContainer
                      justifyContent="space-between"
                      flexDirection="column"
                    >
                      <p>
                        <span className="bold">{'Reverse Subsidy Price'}</span>
                      </p>
                      <FlexContainer
                        justifyContent="space-between"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Dropdown
                          label="Settings Type"
                          width="48%"
                          value={itemPriceType}
                          onChange={(e) =>
                            this.setState({ itemPriceType: e.target.value })
                          }
                        >
                          {GroupOrderItemPriceSettingsType.map((type) => (
                            <option
                              key={type}
                              value={type}
                              selected={type === itemPriceType}
                            >
                              {type}
                            </option>
                          ))}
                        </Dropdown>
                        <Button
                          label={'Add Settings'}
                          onClick={() =>
                            this.addItemSettings(index, 'prices', priceSettings)
                          }
                          style={{ width: 20 }}
                        />
                      </FlexContainer>
                      {Object.keys(priceSettingsPrices).map(
                        (priceSettingsType, oIndex) => {
                          const priceSetting =
                            priceSettingsPrices[priceSettingsType] || {}

                          return (
                            <FlexContainer
                              justifyContent="space-between"
                              flexDirection="column"
                              key={oIndex}
                            >
                              <p>
                                <span className="bold">Type: </span>
                                {priceSetting.itemType}
                              </p>
                              <Input
                                label={'Price ($)'}
                                marginBottom="20px"
                                width="48%"
                                type="number"
                                value={priceSetting.price || ''}
                                onChange={(e) =>
                                  this.onChangeItemPriceSettings(
                                    index,
                                    'prices',
                                    priceSetting.itemType,
                                    'price',
                                    parseFloat(e.target.value),
                                    priceSettings,
                                  )
                                }
                              />
                              <Button
                                label={'Remove'}
                                onClick={() =>
                                  this.removeItemSettings(
                                    index,
                                    'prices',
                                    priceSetting.itemType,
                                    priceSettings,
                                  )
                                }
                                style={{ width: 20 }}
                              />
                              <YSpacing height="20px" />
                            </FlexContainer>
                          )
                        },
                      )}
                    </FlexContainer>
                    {limitPurchase && (
                      <FlexContainer
                        justifyContent="space-between"
                        flexDirection="column"
                      >
                        <p>
                          <span className="bold">
                            {'Client Budget Per Employee'}
                          </span>
                        </p>
                        <FlexContainer
                          justifyContent="space-between"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Dropdown
                            label="Settings Type"
                            width="48%"
                            value={itemBudgetType}
                            onChange={(e) =>
                              this.setState({ itemBudgetType: e.target.value })
                            }
                          >
                            {GroupOrderItemPriceSettingsType.map((type) => (
                              <option
                                key={type}
                                value={type}
                                selected={type === itemBudgetType}
                              >
                                {type}
                              </option>
                            ))}
                          </Dropdown>
                          <Button
                            label={'Add Settings'}
                            onClick={() =>
                              this.addItemSettings(
                                index,
                                'budgets',
                                priceSettings,
                              )
                            }
                            style={{ width: 20 }}
                          />
                        </FlexContainer>
                        {Object.keys(priceSettingsBudgets).map(
                          (priceSettingsType, oIndex) => {
                            const priceSetting =
                              priceSettingsBudgets[priceSettingsType] || {}

                            return (
                              <FlexContainer
                                justifyContent="space-between"
                                flexDirection="column"
                                key={oIndex}
                              >
                                <p>
                                  <span className="bold">Type: </span>
                                  {priceSetting.itemType}
                                </p>
                                <Input
                                  label={'Budget ($)'}
                                  marginBottom="20px"
                                  width="48%"
                                  type="number"
                                  value={priceSetting.budget || ''}
                                  onChange={(e) =>
                                    this.onChangeItemPriceSettings(
                                      index,
                                      'budgets',
                                      priceSetting.itemType,
                                      'budget',
                                      parseFloat(e.target.value),
                                      priceSettings,
                                    )
                                  }
                                />
                                <Button
                                  label={'Remove'}
                                  onClick={() =>
                                    this.removeItemSettings(
                                      index,
                                      'budgets',
                                      priceSetting.itemType,
                                      priceSettings,
                                    )
                                  }
                                  style={{ width: 20 }}
                                />
                                <YSpacing height="20px" />
                              </FlexContainer>
                            )
                          },
                        )}
                      </FlexContainer>
                    )}
                  </FlexContainer>
                )}
                {[GROUP_ORDER_SUBSIDY_TYPE_HUNGRY_WALLET].includes(
                  subsidyType,
                ) && (
                  <FlexContainer
                    justifyContent="space-between"
                    flexDirection="column"
                  >
                    <p>
                      <span className="bold">
                        {
                          'HUNGRY Wallet settings can be changed in the client global settings section'
                        }
                      </span>
                    </p>
                    <YSpacing height="20px" />
                  </FlexContainer>
                )}
                {[GROUP_ORDER_SUBSIDY_TYPE_FLAT_PRICE].includes(
                  subsidyType,
                ) && (
                  <FlexContainer
                    justifyContent="space-between"
                    flexDirection="column"
                  >
                    <p>
                      <span className="bold">
                        {
                          'Global Flat Price settings can be changed in the client global settings section'
                        }
                      </span>
                    </p>
                    <YSpacing height="20px" />
                  </FlexContainer>
                )}
              </FlexContainer>
            )}
            <FlexContainer justifyContent="space-between" alignItems="row">
              {WEEK.map((d, i) => {
                return (
                  <Checkbox
                    key={i}
                    label={d}
                    testId={d}
                    value={scheduleMap[i] || false}
                    checked={scheduleMap[i] || false}
                    onChange={(e) =>
                      this.onChangeScheduleSettings(
                        index,
                        i,
                        e.target.checked,
                        days,
                      )
                    }
                  />
                )
              })}
            </FlexContainer>
            <YSpacing height="10px" />
            <Button
              label={'Remove Setting'}
              onClick={() => onRemoveSubsidySettings(index)}
              style={{ width: 20 }}
            />
          </FlexContainer>
        ) : (
          <FlexContainer></FlexContainer>
        )}
      </FlexContainer>
    )
  }
}

SubsidySettings.propTypes = {
  subsidies: PropTypes.array,
  subsidy: PropTypes.object,
  index: PropTypes.number,
  isExpanded: PropTypes.bool,

  onChangeSubsidySettings: PropTypes.func,
  onRemoveSubsidySettings: PropTypes.func,
  onToggleSubsidySettings: PropTypes.func,
}

export default SubsidySettings
