import React, { useEffect } from 'react'
import Moment from 'moment-timezone'

import { Button } from '@components/common/form'
import Table from '@components/common/Table'

import { DinerProfile, Contact, Address } from '@types'
import { colors } from '../../../../constants'

const pAddress = (profile: DinerProfile) => {
  const { line1, line2, city, state, zip } = profile.address

  return `${line1}, ${
    line2 !== '' ? `${line2}, ` : ''
  }${city}, ${state}, ${zip}`
}

const pTime = (profile: DinerProfile) => {
  const { eventTime } = profile
  const hour = eventTime / 100
  const minutes = eventTime % 100

  return Moment().set({ hour, minute: minutes }).format('h:mm A')
}
interface DinerProfilesProps {
  account: { id: string; contacts: Contact[]; addresses: Address[] }
  dinerProfiles: DinerProfile[]
  loadDinerProfiles: (
    accountId: string,
    contacts: Contact[],
    addresses: Address[],
  ) => Promise<DinerProfile[]>
  editDinerProfile: (profile: DinerProfile) => void
}

const DinerProfiles: React.FC<DinerProfilesProps> = ({
  account,
  dinerProfiles,
  loadDinerProfiles,
  editDinerProfile,
}) => {
  useEffect(() => {
    const { id, contacts, addresses } = account

    const loadProfiles = async () => {
      await loadDinerProfiles(id, contacts, addresses)
    }

    loadProfiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const onAddNewDinerProfile = () => {
    editDinerProfile({ accountId: account.id } as DinerProfile)
  }

  const onEditDinerProfile = (profile: DinerProfile) => {
    editDinerProfile({ ...profile })
  }

  return (
    <div>
      <div className="mb-5">
        <Button
          testId={'add-new-diner-profile'}
          label="Add New Diner Profile"
          backgroundColor={colors.gray400}
          onClick={onAddNewDinerProfile}
        />
      </div>
      <div className="w-full p-2 rounded-lg bg-white">
        <Table>
          <tr>
            <th>Diner Profile Name</th>
            <th>Meal Type</th>
            <th>Event Time</th>
            <th>Headcount</th>
            <th>Contact</th>
            <th>Address</th>
            <th>Last Updated By</th>
          </tr>
          {dinerProfiles.map((profile) => (
            <DinerProfileRow
              key={profile.id}
              profile={profile}
              onEdit={onEditDinerProfile}
            />
          ))}
        </Table>
      </div>
    </div>
  )
}

interface DinerProfileRowProps {
  profile: DinerProfile
  onEdit: (profile: DinerProfile) => void
}

const DinerProfileRow: React.FC<DinerProfileRowProps> = ({
  profile,
  onEdit,
}) => (
  <tr className="cursor-pointer" onClick={() => onEdit(profile)}>
    <td>{profile.name}</td>
    <td>{profile.mealType}</td>
    <td>{pTime(profile)}</td>
    <td>{profile.headcount}</td>
    <td>{profile.contact.name}</td>
    <td>{pAddress(profile)}</td>
    <td>
      <div className="flex flex-col">
        <p>{profile.lastUpdatedBy}</p>
        <p>{Moment(profile.updatedAt).format('MM/DD/YYYY hh:mm A')}</p>
      </div>
    </td>
  </tr>
)

export default DinerProfiles
