import React from 'react'
import PropTypes from 'prop-types'
import FlexContainer from '@components/common/FlexContainer'
import { Input } from '@components/common/form'
import { OrderFormSectionTitle } from '@res/styledComponents/index'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import { TechHelpForm } from '@components/common'
import { TextArea } from '@components/common/form'
import {
  ContactsSection,
  PaymentSection,
} from '@containers/order/edit/sections'
import { colors } from '../../../../constants'

const NonFinanceEditSection = (props) => {
  const {
    clearError,
    errors,
    orderable,
    onChange,
    onChangePaymentMethod,
    onHide,
    onSave,
    onSaveContact,
    onSavePaymentMethod,
    onSelectContacts,
  } = props

  const {
    account,
    cateringContact,
    invoiceContact,
    newCateringContact,
    newInvoiceContact,
    newPaymentMethod,
    newReceiptContact,
    orderableType,
    paymentMethod,
    purchaseOrderNumber,
    receiptContact,
    setUpInstructions,
  } = orderable
  const { contacts, paymentMethods } = account || {}
  const hasAccount = !!account

  return (
    <FlexContainer flexDirection="column">
      <OrderFormSectionTitle>
        <span>1</span>
        Update Order Details - This order is currently locked. Only
        non-financial fields are available for updates.
      </OrderFormSectionTitle>
      <YSpacing height="20px" />
      <FlexContainer alignItems="center" justifyContent="space-between">
        <PaymentSection
          paymentMethod={paymentMethod}
          paymentMethods={paymentMethods}
          errors={errors}
          hasAccount={hasAccount}
          newPaymentMethod={newPaymentMethod}
          onChange={onChangePaymentMethod}
          onSave={onSavePaymentMethod}
          clearError={clearError}
          width="31%"
        />
        <Input
          label="PO Number / Reference"
          marginBottom="0"
          width="31%"
          value={purchaseOrderNumber}
          onChange={(e) => onChange({ purchaseOrderNumber: e.target.value })}
        />
      </FlexContainer>
      <YSpacing height="30px" />
      <FlexContainer width="100%" justifyContent="space-between">
        <div style={{ width: '2000px' }}>
          <ContactsSection
            contacts={contacts}
            invoiceContact={invoiceContact}
            receiptContact={receiptContact}
            cateringContact={cateringContact}
            errors={errors}
            isVCX={false}
            hasAccount={hasAccount}
            newCateringContact={newCateringContact}
            newInvoiceContact={newInvoiceContact}
            newReceiptContact={newReceiptContact}
            onChange={onSelectContacts}
            onSave={onSaveContact}
            clearError={clearError}
          />
        </div>
      </FlexContainer>
      <YSpacing height="30px" />
      <TextArea
        label="Invoice Notes"
        width="48%"
        value={setUpInstructions}
        onChange={(e) => onChange({ setUpInstructions: e.target.value })}
      />
      <YSpacing height="30px" />
      <FlexContainer width="100%" flexDirection="column" alignItems="end">
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <TechHelpForm margin="0 30px 0 0" />
          <LinkText
            testId="cancel"
            label="Cancel"
            onClick={onHide}
            color={colors.violet}
          />
          <XSpacing width="20px" />
          <Button
            testId="save-order"
            label={`Save ${orderableType}`}
            onClick={onSave}
          />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  )
}

NonFinanceEditSection.propTypes = {
  errors: PropTypes.object,
  orderable: PropTypes.object,

  clearError: PropTypes.func,
  onChange: PropTypes.func,
  onChangePaymentMethod: PropTypes.func,
  onHide: PropTypes.func,
  onSave: PropTypes.func,
  onSaveContact: PropTypes.func,
  onSavePaymentMethod: PropTypes.func,
  onSelectContacts: PropTypes.func,
}

export default NonFinanceEditSection
