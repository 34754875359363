import Moment from 'moment-timezone'
import {
  DinerProfile,
  ApiDinerProfile,
  Contact,
  Address,
  ApiCustomOrderSettings,
  CustomOrderSettings,
} from '@types'

export const pRequestDinerProfile = (
  profile: DinerProfile,
): ApiDinerProfile => {
  const { contact, address, customOrderSettings, ...rest } = profile

  return {
    ...rest,
    contactId: contact.id,
    addressId: address.id,
    customOrderSettings:
      customOrderSettings && pRequestCustomOrderSettings(customOrderSettings),
  }
}

export const pRequestCustomOrderSettings = (
  settings: CustomOrderSettings,
): ApiCustomOrderSettings => {
  const {
    gratuity,
    staffRate,
    staffFee,
    deliveryAndServiceFeePercentHoliday,
    deliveryAndServiceFeePercentWeekend,
    deliveryAndServiceFeePercentWeekday,
    deliveryAndServiceFeeCapHoliday,
    deliveryAndServiceFeeCapWeekend,
    deliveryAndServiceFeeCapWeekday,
    doNotArriveBeforeTime,
    ...rest
  } = settings

  return {
    ...rest,
    gratuity: gratuity && gratuity.toString(),
    staffRate: staffRate && staffRate.toString(),
    staffFee: staffFee && staffFee.toString(),
    deliveryAndServiceFeePercentHoliday:
      deliveryAndServiceFeePercentHoliday &&
      deliveryAndServiceFeePercentHoliday.toString(),
    deliveryAndServiceFeePercentWeekend:
      deliveryAndServiceFeePercentWeekend &&
      deliveryAndServiceFeePercentWeekend.toString(),
    deliveryAndServiceFeePercentWeekday:
      deliveryAndServiceFeePercentWeekday &&
      deliveryAndServiceFeePercentWeekday.toString(),
    deliveryAndServiceFeeCapHoliday:
      deliveryAndServiceFeeCapHoliday &&
      deliveryAndServiceFeeCapHoliday.toString(),
    deliveryAndServiceFeeCapWeekend:
      deliveryAndServiceFeeCapWeekend &&
      deliveryAndServiceFeeCapWeekend.toString(),
    deliveryAndServiceFeeCapWeekday:
      deliveryAndServiceFeeCapWeekday &&
      deliveryAndServiceFeeCapWeekday.toString(),
    doNotArriveBeforeTime:
      doNotArriveBeforeTime && doNotArriveBeforeTime.toISOString(),
  } as ApiCustomOrderSettings
}

export const pResponseDinerProfile = (
  profile: ApiDinerProfile,
  contacts: Contact[],
  addresses: Address[],
): DinerProfile => {
  const { contactId, addressId, customOrderSettings, ...rest } = profile

  const contact = contacts.find((c) => c.id === contactId)
  const address = addresses.find((a) => a.id === addressId)

  return {
    ...rest,
    contact: {
      id: contact?.id || '',
      name: contact?.name || '',
      email: contact?.email || '',
      phoneNumber: contact?.phoneNumber || '',
    },
    address: {
      id: address?.id || '',
      line1: address?.line1 || '',
      line2: address?.line2 || '',
      city: address?.city || '',
      state: address?.state || '',
      zip: address?.zip || '',
    },
    customOrderSettings:
      customOrderSettings && pResponseCustomOrderSettings(customOrderSettings),
  }
}

export const pResponseCustomOrderSettings = (
  settings: ApiCustomOrderSettings,
): CustomOrderSettings => {
  const {
    gratuity,
    staffRate,
    staffFee,
    deliveryAndServiceFeePercentHoliday,
    deliveryAndServiceFeePercentWeekend,
    deliveryAndServiceFeePercentWeekday,
    deliveryAndServiceFeeCapHoliday,
    deliveryAndServiceFeeCapWeekend,
    deliveryAndServiceFeeCapWeekday,
    doNotArriveBeforeTime,
    ...rest
  } = settings

  return {
    ...rest,
    gratuity: gratuity && parseFloat(gratuity),
    staffRate: staffRate && parseFloat(staffRate),
    staffFee: staffFee && parseFloat(staffFee),
    deliveryAndServiceFeePercentHoliday:
      deliveryAndServiceFeePercentHoliday &&
      parseFloat(deliveryAndServiceFeePercentHoliday),
    deliveryAndServiceFeePercentWeekend:
      deliveryAndServiceFeePercentWeekend &&
      parseFloat(deliveryAndServiceFeePercentWeekend),
    deliveryAndServiceFeePercentWeekday:
      deliveryAndServiceFeePercentWeekday &&
      parseFloat(deliveryAndServiceFeePercentWeekday),
    deliveryAndServiceFeeCapHoliday:
      deliveryAndServiceFeeCapHoliday &&
      parseFloat(deliveryAndServiceFeeCapHoliday),
    deliveryAndServiceFeeCapWeekend:
      deliveryAndServiceFeeCapWeekend &&
      parseFloat(deliveryAndServiceFeeCapWeekend),
    deliveryAndServiceFeeCapWeekday:
      deliveryAndServiceFeeCapWeekday &&
      parseFloat(deliveryAndServiceFeeCapWeekday),
    doNotArriveBeforeTime:
      doNotArriveBeforeTime && Moment(doNotArriveBeforeTime),
  } as CustomOrderSettings
}

export const pResponseDinerProfiles = (
  profiles: ApiDinerProfile[],
  contacts: Contact[],
  addresses: Address[],
): DinerProfile[] => {
  return profiles.map((profile) =>
    pResponseDinerProfile(profile, contacts, addresses),
  )
}
