import React from 'react'

import { FiMapPin } from 'react-icons/fi'
import { BiPencil, BiTrash } from 'react-icons/bi'
import { IoCheckbox } from 'react-icons/io5'

import { IconButton } from '@res/styledComponents/index'
import { YSpacing, FlexContainer } from '@components/common'
import { AddressInput } from '@containers/common/form'
import { DinerProfile } from '@types'

interface Address {
  id?: string
  key?: number
  fullAddress: string
  line1: string
  line2: string
  city: string
  state: string
  zip: string
  isDefault: boolean
}

interface AddressListProps {
  accountId?: string
  dinerProfiles: DinerProfile[]
  host: string
  currentUser: any
  addresses: Address[]
  newAddresses: Address[]
  editingAddresses: { [key: string]: Address }

  onAddNewAddress: () => void
  onChangeNewAddress: (address: Address, key: number) => void
  onCancelNewAddress: (key: number) => void

  onEditAddress: (address: Address) => void
  onChangeEditAddress: (address: Address) => void
  onCancelEditAddress: (addressId: string) => void

  onDeleteAddress: (
    accountId: string,
    address: Address,
    dinerProfiles: DinerProfile[],
    redirectHost: string,
  ) => Promise<void>
  onSaveAddress: (address: Address) => Promise<void>

  markDefaultAddress: (accountId: string, addressId: string) => Promise<void>
}

const AddressList: React.FC<AddressListProps> = ({
  accountId,
  dinerProfiles,
  addresses,
  newAddresses,
  editingAddresses,
  host,

  onAddNewAddress,
  onChangeNewAddress,
  onCancelNewAddress,

  onEditAddress,
  onChangeEditAddress,
  onCancelEditAddress,

  onDeleteAddress,
  onSaveAddress,

  markDefaultAddress,
}) => {
  return (
    <FlexContainer
      width="100%"
      justifyContent="flex-start"
      flexDirection="column"
    >
      <h2
        className="mb-2"
        style={{ color: '#FB5754', fontSize: 16, fontWeight: 'bold' }}
      >
        ADDRESSES:
      </h2>
      <YSpacing height="5px" />
      <IconButton id="add-new-address" onClick={onAddNewAddress}>
        <FiMapPin /> Add Another Address
      </IconButton>
      <YSpacing height="5px" />
      {newAddresses.length > 0 && (
        <>
          <FlexContainer width="100%" flexDirection="row" flexWrap="wrap">
            {newAddresses.map((address) => (
              <div
                style={{ width: '31%' }}
                className="px-2"
                key={`new-address-container-${address.key}`}
              >
                <AddressInput
                  key={`new-address-${address.key}`}
                  hasBackground={true}
                  address={address}
                  onChange={(addr) =>
                    address.key !== undefined &&
                    onChangeNewAddress(addr, address.key)
                  }
                  onCancel={() =>
                    address.key !== undefined && onCancelNewAddress(address.key)
                  }
                />
              </div>
            ))}
          </FlexContainer>
          <YSpacing height="5px" />
        </>
      )}
      <FlexContainer width="100%" flexDirection="column">
        {addresses.map((address, i) => (
          <FlexContainer
            key={i}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <span>
              {address.fullAddress}
              {address.isDefault && ' (Default)'}
            </span>
            <div className="flex flex-row justify-items-start w-1/3">
              <IconButton
                id={`address-${address.id}-edit`}
                onClick={() => onEditAddress(address)}
                style={{ marginRight: 20 }}
              >
                <BiPencil /> Edit
              </IconButton>
              <IconButton
                id={`address-${address.id}-delete`}
                onClick={async () => {
                  if (!accountId) {
                    return
                  }
                  await onDeleteAddress(accountId, address, dinerProfiles, host)
                }}
              >
                <BiTrash /> Delete
              </IconButton>
              {accountId && !address.isDefault && (
                <IconButton
                  id={`address-${address.id}-default`}
                  onClick={async () =>
                    address.id &&
                    (await markDefaultAddress(accountId, address.id))
                  }
                  style={{ marginLeft: 20 }}
                >
                  <IoCheckbox /> Mark Default
                </IconButton>
              )}
            </div>
          </FlexContainer>
        ))}
        <FlexContainer
          width="100%"
          padding="10px 0 10px 0"
          justifyContent="flex-start"
        >
          {Object.values(editingAddresses).map((address) => (
            <div key={`address-container-${address.id}`} className="p-2">
              <AddressInput
                key={`address-edit-${address.id}`}
                hasBackground={true}
                address={address}
                onChange={onChangeEditAddress}
                onCancel={() => address.id && onCancelEditAddress(address.id)}
                onSave={onSaveAddress}
              />
            </div>
          ))}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  )
}

export default AddressList
