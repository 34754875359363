import React from 'react'
import PropTypes from 'prop-types'

const AuthorizedDisplay = (props) => {
  const { children, visible, width } = props

  return (
    <div className="auth-container" style={{ width: width ? width : '100%' }}>
      {visible && children}
    </div>
  )
}

AuthorizedDisplay.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,

  visible: PropTypes.bool,
}

export default AuthorizedDisplay
