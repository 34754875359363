import { connect } from 'react-redux'
import { LinkDinerProfileToPastOrdersModal } from '@components/account/accountSections'
import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const {
    account,
    dinerProfiles,
    headquarter: { headquarters },
    router,
  } = state
  const port = router.location.port === 3000 ? 3001 : 3000
  const uri =
    config.admin_url +
    (config.admin_url === 'http://localhost' ? `:${port}` : '')

  const hqLocaleMap = headquarters.reduce((acc, hq) => {
    acc[hq.id] = hq.locale

    return acc
  }, {})

  return {
    account,
    accountId: account && account.id,
    dinerProfiles,
    hqLocaleMap,
    uri,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, UIService, RestService } = services
  const {
    pRequestOrdersFilter,
    pResponseAccountExecutives,
    pResponseChefs,
    pResponseOrder,
    pResponseOrders,
  } = presenters.Api
  const {
    LoadAccountExecutives,
    LoadChefs,
    SearchOrders,
    UpdateOrdersDinerProfileId,
  } = coordinators
  const pResponseError = presenters.Errors.responseErrorMessage

  const searchOrders = SearchOrders({
    RestService,
    pRequestOrdersFilter: pRequestOrdersFilter,
    pResponseOrders,
  })
  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })
  const loadSalesReps = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })(true)
  const updateOrdersDinerProfileId = UpdateOrdersDinerProfileId({
    RestService,
    pResponseOrder,
    HandleError,
    pResponseError,
  })

  const { ConfirmationModal, Timer } = UIService
  const { callAfterTimeout } = Timer

  return {
    callAfterTimeout,
    confirmationModal: ConfirmationModal,
    loadChefs,
    loadSalesReps,
    searchOrders,
    updateOrdersDinerProfileId,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LinkDinerProfileToPastOrdersModal)
