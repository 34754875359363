import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'
import { useEffect, useState, useMemo, useRef } from 'react'

export const getValOrDefault = (val, _default) => (val && val) || _default

export const isEmpty = (obj) => Object.keys(obj).length === 0

export const isNumber = (value) => typeof value === 'number'

export const valueAsNumberOrNull = (value, allowZero = false) =>
  isNaN(Number(value)) || (Number(value) === 0 && !allowZero)
    ? null
    : Number(value)

export function moneyString(number) {
  if (Number.isNaN(parseFloat(number))) {
    return '0.00'
  }

  return parseFloat(number)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
}

export function shortMoneyString(number) {
  if (number - Math.floor(number) === 0) {
    return String(number)
  }

  return moneyString(number)
}

export function alphabetize(list, { attribute }) {
  if (attribute) {
    list.sort((a, b) => {
      if (a[attribute] < b[attribute]) {
        return -1
      }
      if (a[attribute] > b[attribute]) {
        return 1
      }

      return 0
    })
  } else {
    list.sort()
  }
}

export function sortByAttribute(list, attribute, reverse = false) {
  list.sort((a, b) => {
    let result = 0
    if (a[attribute] < b[attribute]) {
      result = -1
    }
    if (a[attribute] > b[attribute]) {
      result = 1
    }

    return result * (reverse ? -1 : 1)
  })
}

export function shallowCompareObjects(a, b) {
  const keys = new Set(Object.keys(a).concat(Object.keys(b)))
  let equal = true
  keys.forEach((k) => {
    equal = equal && a[k] === b[k]
  })

  return equal
}

export function camelCaseify(val) {
  const _camelCaseify = (val) => {
    if (val == null) {
      return val
    }
    if (Array.isArray(val)) {
      return val.map((v) => _camelCaseify(v))
    }
    if (typeof val === 'object') {
      const newVal = {}
      for (let k in val) {
        const v = val[k]
        k = k.replace(/_(\w)/g, (s) => s[1].toUpperCase())
        newVal[k] = _camelCaseify(v)
      }

      return newVal
    } else {
      return val
    }
  }

  return _camelCaseify(val)
}

export function sentenceCaseify(str) {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export function camelCaseifySpace(val) {
  return val.toLowerCase().replace(/\s+(\w)?/gi, (_, letter) => {
    return letter.toUpperCase()
  })
}

export function snakeCaseify(val) {
  const _snakeCaseify = (val) => {
    if (val == null) {
      return val
    }
    if (Array.isArray(val)) {
      return val.map((v) => _snakeCaseify(v))
    }
    if (typeof val === 'object') {
      const newVal = {}
      for (let k in val) {
        const v = val[k]
        k = k.replace(/([A-Z])/g, '_$1').toLowerCase()
        newVal[k] = _snakeCaseify(v)
      }

      return newVal
    } else {
      return val
    }
  }

  return _snakeCaseify(val)
}

export function formAdd(data, target, attr, targetAttr, transformOrValue) {
  const isFormData = target.constructor.name === 'FormData'

  const attrParts = attr.split('.')
  let hasVal = true
  let value = data
  for (const a of attrParts) {
    if (!value) {
      // break out in case trying to find attribute of a null
      hasVal = false
      break
    }
    value = value[a]
    if (value === undefined) {
      hasVal = false
      break
    }
  }
  if (hasVal) {
    if (isFormData) {
      if (transformOrValue) {
        if (typeof transformOrValue === 'function') {
          target.append(targetAttr, transformOrValue(value))
        } else {
          target.append(targetAttr, transformOrValue)
        }
      } else {
        target.append(targetAttr, value)
      }
    } else {
      if (transformOrValue) {
        if (typeof transformOrValue === 'function') {
          target[targetAttr] = transformOrValue(value)
        } else {
          target[targetAttr] = transformOrValue
        }
      } else {
        target[targetAttr] = value
      }
    }
  }
}

export function resetState(state, initialState) {
  const newState = { ...state }
  for (const k in newState) {
    newState[k] = initialState[k]
  }

  return newState
}

export function reorderArrayByAttribute(
  array,
  element,
  attributes,
  idxAttribute,
  change,
) {
  const newArray = array.slice()
  const idx = newArray.findIndex((e) =>
    attributes.every((attribute) => e[attribute] === element[attribute]),
  )
  if (idx != -1) {
    const up = change > 0
    const item = { ...newArray[idx] }
    for (let i = 1; i <= Math.abs(change); i++) {
      const nextIdx = idx + (up ? i : -i)
      if (newArray[nextIdx]) {
        const next = { ...newArray[nextIdx] }
        const tmpIdx = item[idxAttribute]
        item[idxAttribute] = next[idxAttribute]
        newArray[idx] = item
        next[idxAttribute] = tmpIdx
        newArray[nextIdx] = next
      }
    }
  }

  return newArray
}

export function isArraysEqual(array1, array2, property) {
  return (
    Array.isArray(array1) &&
    Array.isArray(array2) &&
    array1.length === array2.length &&
    array1.every((val, index) => {
      return property
        ? val?.[property] === array2[index]?.[property]
        : val === array2[index]
    })
  )
}

export function removedFromArrayByAttribute(array, element, attributes) {
  const newArray = array.slice()
  const idx = newArray.findIndex((e) =>
    attributes.every((attribute) => e[attribute] === element[attribute]),
  )
  if (idx !== -1) {
    newArray.splice(idx, 1)
  }

  return newArray
}

export function replacedInArrayByAttribute(array, element, attributes) {
  const newArray = array.slice()
  const idx = newArray.findIndex((e) =>
    attributes.every((attribute) => e[attribute] === element[attribute]),
  )
  if (idx !== -1) {
    newArray[idx] = { ...newArray[idx], ...element }
  }

  return newArray
}

export function roundUp(amount) {
  return Math.round(amount * 100) / 100
}

export function roundMoneyUp(amount) {
  // round to 10 digits first
  let fmtAmount = amount
  // js add sci notation on values less than this
  if (amount >= 0.000001) {
    fmtAmount += 'e10'
  }
  const roundFloat = Number(Math.round(fmtAmount) + 'e-10')
  // then round to 2 digits

  return Number(Math.round(roundFloat + 'e2') + 'e-2')
}

export function deepCopy(inObject) {
  let value, key

  // Handle moment objects
  if (Moment.isMoment(inObject)) {
    return inObject.clone()
  }

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  const outObject = Array.isArray(inObject) ? [] : {}

  for (key in inObject) {
    value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopy(value)
  }

  return outObject
}

export function taxPercentString(taxRatesArray) {
  const totalRate = (taxRatesArray || []).reduce((sum, val) => {
    return sum + val
  }, 0)
  const truncatedRate = (totalRate * 100).toFixed(3)

  return parseFloat(truncatedRate)
}

export function removeHtmlTags(htmlString) {
  return htmlString.replace(/(<([^>]+)>)/gi, '')
}

export function toIdMap(arr) {
  return arr.reduce((map, val) => {
    map[val.id] = val

    return map
  }, {})
}

export const getWeeksInAMonth = (
  year = +Moment().format('YYYY'),
  month = +Moment().format('MM') - 1,
) => {
  const moment = extendMoment(Moment)
  const startDate = moment([year, month])

  const firstDay = moment(startDate).startOf('month')
  const endDay = moment(startDate).endOf('month')

  const monthRange = moment.range(firstDay, endDay)
  const weeks = []
  const days = Array.from(monthRange.by('day'))
  days.forEach((it) => {
    if (!weeks.includes(it.week())) {
      weeks.push(it.week())
    }
  })

  const calendar = []
  weeks.forEach((week) => {
    const firstWeekDay = moment([year, month]).week(week).day(0)
    const lastWeekDay = moment([year, month]).week(week).day(6)
    const weekRange = moment.range(firstWeekDay, lastWeekDay)
    calendar.push(Array.from(weekRange.by('day')))
  })

  return calendar
}

// takes a jsdate ie 2006-01-02T13:30:00 EST and converts to moment-timezone in the default tz ie 2006-01-02T13:30:00 PST
// not ideal to use 2 moment libraries but with current use of moment-tz set default its hard without it
export function convertTimeIntoMomentTz(time) {
  const dateString = `${time.toDateString()} ${time.toTimeString().slice(0, 8)}`

  return Moment(dateString, 'ddd MMM D YYYY hh:mm:ss')
}

// takes a moment ie 2006-01-02T13:30:00 EST and converts to jsdate in the browser tz ie 2006-01-02T13:30:00 PST
export function convertTimeIntoBrowserTz(time) {
  return new Date(Moment(time).format('YYYY-MM-DDTHH:mm:ss'))
}

export function orderBalancesEditCutoffDate(eventDate) {
  // end of week = sat end of day
  const nextSundayMorning = Moment(eventDate)
    .tz('America/New_York')
    .endOf('week')
    .add(1, 'second')
  const editCutoff = nextSundayMorning.add(1, 'day').add(15, 'hours')

  return editCutoff
}

export function checkOrderIsEditableNow(eventDate) {
  const editCutoff = orderBalancesEditCutoffDate(eventDate)
  let canEdit = false
  if (Moment().isBefore(editCutoff, 'minute')) {
    canEdit = true
  }

  return canEdit
}

export function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  )

  useEffect(() => {
    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return isIntersecting
}

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export const GetAllowedHqs = ({ headquarters, user, allowedRoles }) => {
  const allowedHqs = headquarters.filter((hq) => {
    const userRoles = user.permissions && user.permissions[hq.id]
    if (!userRoles || userRoles.length <= 0) {
      return false
    }
    let hasRole = false
    for (const roleName of userRoles) {
      if (allowedRoles.includes(roleName)) {
        hasRole = true
      }
    }

    return hasRole
  })

  return allowedHqs
}

export const GetCurrentHq = ({ headquarters, user }) => {
  const { lastLoggedInHq } = user
  const hq = headquarters.find((hq) => hq.id === lastLoggedInHq)

  return hq
}

const defaultOnBuildInvalid = () => {
  if (
    confirm(
      'This is an old version of the website, click "OK" to reload the page',
    )
  ) {
    return location.reload()
  }

  // Continue validating
  return true
}

export const ActivateBuildValidation = ({
  currentVersion,
  onError: onInvalid = defaultOnBuildInvalid,
  interval = 30000,
}) => {
  const validate = () => {
    fetch('/build.json')
      .then((response) => response.json())
      .then((response) => {
        // Don't validate if there is no currentVersion data
        if (currentVersion && response.version !== currentVersion) {
          return onInvalid() && setTimeout(validate, interval)
        }

        setTimeout(validate, interval)
      })
      .catch(() => {
        // On request error keep trying but don't give any feedback
        setTimeout(validate, interval)
      })
  }

  setTimeout(validate, interval)
}

export const assignParams = (params, key, value) => {
  if (value && value.length > 0) {
    params[key] = value.join(',')
  }
}

export const isZeroTime = (time) => {
  const mTime = Moment(time)

  return mTime.year() === 0 && mTime.month() === 11 && mTime.day() === 0
}

const parseDateStr = (dateStr) => {
  const parts = dateStr.split('-')

  return parts.map(Number)
}

export const parseMilitaryTime = (time) => {
  const hours = Math.floor(time / 100)
  const minutes = time % 100

  return [hours, minutes]
}

export const GetUpdatedDateMenusAfterCutoffChange = (dateMenus, locale) => {
  const nextMenus = []
  dateMenus.menus.forEach((menu) => {
    const cutoffTime = GetMenuCutoffTime(menu, dateMenus, locale, false)
    const nextMenu = {
      ...menu,
      cutoffTime,
    }
    nextMenus.push(nextMenu)
  })

  return {
    ...dateMenus,
    menus: nextMenus,
  }
}

export const GetMenuCutoffTime = (
  menu,
  dateMenus,
  locale,
  forceUpdate = true,
) => {
  const { dateStr } = dateMenus

  if (menu.cutoffTime && !forceUpdate) {
    // When updating a date menus, menu-level cutoffHours and cutoffDays may not
    // be available, so check if menu already has a cutoffTime attribute and use that

    // Menu-level cutoff should never be after date menus cutoff
    if (Moment(menu.cutoffTime).isAfter(dateMenus.cutoffTime)) {
      return Moment(dateMenus.cutoffTime).toISOString()
    } else {
      return menu.cutoffTime
    }
  }

  if (menu.cutoffHours === null || menu.cutoffDays === null) {
    return dateMenus.cutoffTime
  }
  const [hour, minute] = parseMilitaryTime(menu.cutoffHours)
  const [year, month, date] = parseDateStr(dateStr)

  let menuCutoff = Moment()
    .tz(locale)
    .set({
      year,
      month: month - 1,
      date,
      hour,
      minute,
      second: 0,
      millisecond: 0,
    })

  let index = 0
  while (index < menu.cutoffDays) {
    menuCutoff = menuCutoff.subtract(1, 'day')
    // Don't count Sunday and Saturday which are non-business days
    if (menuCutoff.day() === 0 || menuCutoff.day() === 6) {
      continue
    } else {
      index++
    }
  }

  // Menu-level cutoff should never be after date menus cutoff
  if (menuCutoff.isAfter(dateMenus.cutoffTime)) {
    menuCutoff = Moment(dateMenus.cutoffTime)
  }

  return menuCutoff.toISOString()
}

export const GetMenuPickupTime = (menu, dateMenus, locale) => {
  const { dateStr } = dateMenus

  if (menu.pickupHours === null) {
    return null
  }
  const [hour, minute] = parseMilitaryTime(menu.pickupHours)
  const [year, month, date] = parseDateStr(dateStr)

  const pickupTime = Moment()
    .tz(locale)
    .set({
      year,
      month: month - 1,
      date,
      hour,
      minute,
      second: 0,
      millisecond: 0,
    })

  return pickupTime.toISOString()
}

export const subtractBusinessDays = (date, numDays) => {
  const Sunday = 0
  const Saturday = 6
  let daysRemaining = numDays

  const newDate = date.clone()

  while (daysRemaining > 0) {
    newDate.subtract(1, 'days')
    if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
      daysRemaining--
    }
  }

  return newDate
}

export const timeSlotTimesFromWeek = (week, timeSlot, locale) => {
  const { day, start, end } = timeSlot

  const isoWeek = Moment.utc(week, 'MM/DD/YYYY')

  // day instead of day - 1 because isoWeek is utc and converting to american locales drops a day
  const startDay = isoWeek
    .clone()
    .add(day, 'days')
    .tz(locale)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  const offset = startDay.clone().utcOffset()

  // DST switches do not happen at midnight, so we need to account for
  // -5 at midnight but -4 at target time and vice versa
  const startTime = startDay.clone().add(start, 'hours')
  startTime.add(offset - startTime.utcOffset(), 'minutes')
  const endTime = startTime.clone().add(end - start, 'hours')

  return {
    start: startTime,
    end: endTime,
  }
}

export const getTimeSlotTimes = (start, end) => {
  const startHour = parseInt(start)
  const startMins = String(parseInt((start - startHour) * 60)).padStart(2, '0')
  const endHour = parseInt(end)
  const endMins = String(parseInt((end - endHour) * 60)).padStart(2, '0')

  let startStr = ''
  if (startHour === 0) {
    startStr = `12:${startMins} AM`
  } else if (startHour < 12) {
    startStr = `${startHour}:${startMins} AM`
  } else {
    startStr = `${startHour - 12}:${startMins} PM`
  }

  let endStr = ''
  if (endHour === 0) {
    endStr = `12:${endMins} AM`
  } else if (endHour < 12) {
    endStr = `${endHour}:${endMins} AM`
  } else {
    endStr = `${endHour - 12}:${endMins} PM`
  }

  return `${startStr} - ${endStr}`
}
export const escapeRegex = (string) => {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
}

// duplicate in reports presenters
export const getOrderType = (order) => {
  const { cateringType } = order
  const dTypes = order['dgraph.type']

  let orderType
  ;(dTypes || []).forEach((dType) => {
    switch (dType) {
      case 'CateringOrder':
        orderType = cateringType === 'VCX' ? 'VCX' : 'Catering'
        break
      case 'PopUp':
        orderType = 'Pop Up'
        break
      case 'GroupOrder':
        orderType = 'Group Order'
        break
    }
  })

  return orderType
}

export const getOrderBals = (accOrder, percent = 1.0) => {
  const orderType = getOrderType(accOrder)
  let total, tax, discount, serviceFee, tip, subtotal

  if (['Pop Up', 'Group Order'].includes(orderType)) {
    subtotal = parseFloat((accOrder.subsidy * percent).toFixed(2))
    tax = parseFloat((accOrder.subsidyTax * percent).toFixed(2))
    discount = 0.0
    serviceFee = parseFloat((accOrder.serviceFee * percent).toFixed(2))
    tip = parseFloat((accOrder.subsidyTip * percent).toFixed(2))
    total = parseFloat(
      (
        (accOrder.subsidy + tax + discount + serviceFee + tip) *
        percent
      ).toFixed(2),
    )
  } else {
    subtotal = parseFloat((accOrder.subtotal * percent).toFixed(2))
    total = parseFloat((accOrder.total * percent).toFixed(2))
    tax = parseFloat((accOrder.tax * percent).toFixed(2))
    discount = parseFloat((accOrder.discount * percent).toFixed(2))
    serviceFee = parseFloat((accOrder.serviceFee * percent).toFixed(2))
    tip = parseFloat((accOrder.tip * percent).toFixed(2))
  }

  return { subtotal, tax, discount, serviceFee, tip, total }
}

export const getOrderBatchInvPayments = (
  accountingOrder,
  includeResolvedInvs = false,
) => {
  const orderPayments = accountingOrder.orderPayments || []

  let filteredOrderPmts = orderPayments.filter(
    (payment) => payment.paymentInvoice?.isUniversal,
  )
  if (!includeResolvedInvs) {
    filteredOrderPmts = filteredOrderPmts.filter(
      (payment) => !payment.paymentInvoice?.isResolved,
    )
  }

  return filteredOrderPmts
}

export const getOrderBatchInvs = (accountingOrder) => {
  const batchPayments = getOrderBatchInvPayments(accountingOrder)
  const invoiceNums = []
  batchPayments.forEach((pmt) => {
    if (!invoiceNums.includes(pmt.paymentInvoice.invoiceNumber)) {
      invoiceNums.push(pmt.paymentInvoice.invoiceNumber)
    }
  })

  return invoiceNums
}

export const getCurrentOrderPayments = ({
  accountingOrder,
  removedPayments = [],
  newPayments = [], // batch invoice payments
  includeResolvedInvs = false,
  onlyBatchPayments = false,
}) => {
  let orderPayments = accountingOrder.orderPayments || []

  // removed existing payments that have been removed
  const removedPmtIds = removedPayments.map((pmt) => pmt.id)
  orderPayments = orderPayments.filter(
    (existingPmt) => !removedPmtIds.includes(existingPmt.id),
  )

  // replace existing payments
  orderPayments = orderPayments.map(
    (pmt) => newPayments.find((newPmt) => newPmt.id === pmt.id) || pmt,
  )

  // remove payments from resolved batch invoices
  if (!includeResolvedInvs) {
    orderPayments = orderPayments.filter(
      (pmt) => !pmt.paymentInvoice?.isResolved,
    )
  }

  // remove payments not from batch invoices
  if (onlyBatchPayments) {
    orderPayments = orderPayments.filter((pmt) => calcIsBatchPayment(pmt))
  }

  // add new payments
  const currentPayments = [
    ...orderPayments,
    ...newPayments.filter(
      (newPmt) => !newPmt.id && newPmt.paymentFor?.id === accountingOrder.id,
    ),
  ]

  return currentPayments
}

export const calcOrderTotalPaid = (
  accountingOrder,
  removedPayments = [],
  newPayments = [],
) => {
  const currentPayments = getCurrentOrderPayments({
    accountingOrder,
    removedPayments,
    newPayments,
  })
  const totalPaid = currentPayments.reduce((sum, pmt) => {
    if (pmt.isPaid) {
      sum += pmt.amount
    }

    return sum
  }, 0.0)

  return totalPaid
}

// assume order payments with no ids are new batch payments
const calcIsBatchPayment = (orderPayment) => {
  const isSavedBatchInvPmt = Boolean(orderPayment.paymentInvoice?.isUniversal)
  const isNewBatchPmt = !orderPayment.id
  // edited payments dont have a paymentInvoice but have a paymentFor
  const isEditedBatchInvPmt =
    Boolean(orderPayment.id) &&
    !orderPayment.paymentInvoice &&
    Boolean(orderPayment.paymentFor)

  return isSavedBatchInvPmt || isNewBatchPmt || isEditedBatchInvPmt
}

export const calcOrderTotalUnpaidBatchedAmt = (
  accountingOrder,
  removedPayments = [],
  newPayments = [],
) => {
  const orderBatchPayments = getCurrentOrderPayments({
    accountingOrder,
    removedPayments,
    newPayments,
    onlyBatchPayments: true,
  })
  const amount = orderBatchPayments.reduce((sum, pmt) => {
    // new payments must be batch payments
    const isBatchPmt = calcIsBatchPayment(pmt)
    if (!pmt.isPaid && isBatchPmt) {
      sum += pmt.amount
    }

    return sum
  }, 0.0)

  return roundUp(amount)
}

// returns the total amount that is either paid or on any batch invoice
export const calcOrderTotalPaidOrBatchedAmt = (
  accountingOrder,
  removedPayments = [],
  newPayments = [],
) => {
  const paidAmt = calcOrderTotalPaid(
    accountingOrder,
    removedPayments,
    newPayments,
  )
  const unpaidBatchedAmt = calcOrderTotalUnpaidBatchedAmt(
    accountingOrder,
    removedPayments,
    newPayments,
  )

  return roundUp(paidAmt + unpaidBatchedAmt)
}

// the amount on an order that is neither paid nor on a batch invoice
export const calcOrderTotalUnpaidUnbatchedAmount = (
  accountingOrder,
  removedPayments,
  newPayments,
) => {
  const { total: orderTotal } = getOrderBals(accountingOrder)
  const invAmt = calcOrderTotalPaidOrBatchedAmt(
    accountingOrder,
    removedPayments,
    newPayments,
  )
  const uninvAmt = orderTotal - invAmt

  return uninvAmt < 0 ? 0 : uninvAmt
}

export const calculatePaymentAmounts = ({
  paymentAmount,
  total,
  tip,
  tax,
  discount,
  serviceFee,
}) => {
  const proportion = total > 0 ? paymentAmount / total : 0
  // Calculate each component based on the proportion
  const calculatedTip = Number((tip * proportion).toFixed(2))
  const calculatedTax = Number((tax * proportion).toFixed(2))
  const calculatedDiscount = Number((discount * proportion).toFixed(2))
  const calculatedServiceFee = Number((serviceFee * proportion).toFixed(2))

  return {
    tip: calculatedTip,
    tax: calculatedTax,
    discount: calculatedDiscount,
    serviceFee: calculatedServiceFee,
  }
}

export const calcPercentPaidOrInvoiced = (orderTotal, invoicedAmount) => {
  if (orderTotal - invoicedAmount === 0) {
    return 100
  }
  let pct = Math.round((invoicedAmount / orderTotal) * 100)
  // dont round to 100% if not exactly 100% invoiced
  if (pct === 100) {
    if (invoicedAmount < orderTotal) {
      pct = 99
    } else {
      pct = 101
    }
  }

  return pct
}

export const buildInvoiceNumberMap = (orderPayments) =>
  orderPayments.reduce((acc, payment) => {
    acc[payment.paymentInvoice.id] = payment.paymentInvoice.invoiceNumber

    return acc
  }, {})

export const diffObjects = (obj1, obj2, primaryKey = 'id') => {
  const compareValues = (key, value1, value2, changes) => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      const arrayChanges = diffArrays(value1, value2, primaryKey)
      if (Object.keys(arrayChanges).length > 0) {
        changes[key] = arrayChanges
      }
    } else if (
      typeof value1 === 'object' &&
      value1 !== null &&
      typeof value2 === 'object' &&
      value2 !== null
    ) {
      const nestedChanges = diffObjects(value1, value2, primaryKey)
      if (Object.keys(nestedChanges).length > 0) {
        changes[key] = nestedChanges
      }
    } else if (value1 !== value2) {
      changes[key] = { from: value1, to: value2 }
    }
  }

  const findChanges = (base, comparison) => {
    const changes = {}

    // Find added and updated fields
    Object.keys(comparison).forEach((key) => {
      compareValues(key, base[key], comparison[key], changes)
    })

    // Find deleted fields
    Object.keys(base).forEach((key) => {
      if (!(key in comparison)) {
        changes[key] = { from: base[key], to: undefined }
      }
    })

    return changes
  }

  const diffArrays = (array1, array2, primaryKey) => {
    const changes = []

    if (
      typeof array1[0] === 'object' &&
      array1[0] !== null &&
      typeof array2[0] === 'object' &&
      array2[0] !== null
    ) {
      const map1 = array1.reduce((map, obj) => {
        map[obj[primaryKey]] = obj

        return map
      }, {})

      const map2 = array2.reduce((map, obj) => {
        map[obj[primaryKey]] = obj

        return map
      }, {})

      // Find added and updated items
      Object.keys(map2).forEach((key) => {
        if (map1[key]) {
          const itemChanges = diffObjects(map1[key], map2[key], primaryKey)
          if (Object.keys(itemChanges).length > 0) {
            changes.push({ key, changes: itemChanges })
          }
        } else {
          changes.push({ key, changes: { from: undefined, to: map2[key] } })
        }
      })

      // Find deleted items
      Object.keys(map1).forEach((key) => {
        if (!map2[key]) {
          changes.push({ key, changes: { from: map1[key], to: undefined } })
        }
      })
    } else {
      const set1 = new Set(array1)
      const set2 = new Set(array2)

      const added = array2.filter((item) => !set1.has(item))
      const removed = array1.filter((item) => !set2.has(item))

      if (added.length > 0) {
        changes.push({ added })
      }
      if (removed.length > 0) {
        changes.push({ removed })
      }
    }

    return changes
  }

  return findChanges(obj1, obj2)
}

export const removeLtGtPrefix = (value = '') => value.replace(/^lt_|^gt_/, '')

export const techTeamUsers = [
  'amanda@tryhungry.com',
  'andres@tryhungry.com',
  'jacob@tryhungry.com',
  'jorge@naturebox.com',
  'markpare@naturebox.com',
  'nicholas@tryhungry.com',
  'tahiru@tryhungry.com',
  'tim@tryhungry.com',
]

export const aiProductTeamUsers = ['anica@tryhungry.com']

export const checkLogInHqUsers = [...techTeamUsers, 'alexa@tryhungry.com']

// long tz -> daylight savings -> short tz
const timezonesToShortTz = {
  'America/New_York': {
    true: 'EDT', // DST active
    false: 'EST', // Standard time
  },
  'America/Chicago': {
    true: 'CDT', // DST active
    false: 'CST', // Standard time
  },
  'America/Denver': {
    true: 'MDT', // DST active
    false: 'MST', // Standard time
  },
  'America/Los_Angeles': {
    true: 'PDT', // DST active
    false: 'PST', // Standard time
  },
}

const timezonesToIANAFormat = {
  'Eastern Time (US & Canada)': 'America/New_York',
  'Central Time (US & Canada)': 'America/Chicago',
  'Mountain Time (US & Canada)': 'America/Denver',
  'Pacific Time (US & Canada)': 'America/Los_Angeles',
}

export const getDefaultMomentShortTz = () => {
  const tzs = timezonesToShortTz[Moment.defaultZone?.name]
  if (tzs) {
    return tzs[Moment().isDST()]
  }

  return '?'
}

export const convertTzToIANAFormat = (tz) => {
  return timezonesToIANAFormat[tz] || '?'
}
