import React from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import { TableHeading } from '@types'

interface DraggableTableColumnsProps {
  headings: TableHeading[]
  updateHeadings: (newHeadings: TableHeading[]) => void
}

const DraggableTableColumns = ({
  headings,
  updateHeadings,
}: DraggableTableColumnsProps) => {
  const removeHeading = (headings: TableHeading[], indexOfRemoval: number) => {
    const newHeadings: TableHeading[] = [
      ...headings.slice(0, indexOfRemoval),
      ...headings.slice(indexOfRemoval + 1),
    ]
    updateHeadings(newHeadings)
  }

  const reorder = (
    list: TableHeading[],
    startIndex: number,
    endIndex: number,
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: any) => {
    const { source, destination } = result

    if (!destination) {
      return
    } // Exit if dropped outside

    const reorderedHeadings = reorder(headings, source.index, destination.index)
    updateHeadings(reorderedHeadings)
  }

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    padding: '16px',
    margin: `0 8px 0 0`,
    background: isDragging ? 'lightgreen' : '#e2e8f0',
    ...draggableStyle,
  })

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided: DroppableProvided) => (
          <tr ref={provided.innerRef} {...provided.droppableProps}>
            {headings.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(
                  provided: DraggableProvided,
                  snapshot: DraggableStateSnapshot,
                ) => (
                  <th
                    className="table-heading"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                  >
                    <div className="flex flex-align-center">
                      <p className="font-bold">{item.label}</p>
                      <span
                        style={{ marginLeft: '10px', color: 'red' }}
                        onClick={() => removeHeading(headings, index)}
                      >
                        ✕
                      </span>
                    </div>
                  </th>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </tr>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableTableColumns
