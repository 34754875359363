import React, { useState, useEffect, ChangeEvent } from 'react'
import Moment from 'moment-timezone'

import { FlexContainer, YSpacing } from '@components/common'
import { DateInput, Dropdown, Button } from '@components/common/form'
import LoadingIndicator from '@components/common/LoadingIndicator'

interface Account {
  id: number
  name: string
  clientChampion?: { firstName: string }
  clientHunter?: { firstName: string }
  pin?: string
}

interface DinerProfile {
  id: string
  accountId: number
  name: string
  detailName?: string
}

interface Order {
  orderNumber: string
}

interface Survey {
  results: Array<{ answer: string }>
}

interface Headquarters {
  id: number
  name: string
}

interface Props {
  headquarters: Headquarters[]
  getMarketActiveItems: (hqId: number) => Promise<any[]>
  loadDinerProfiles: (params: { hqIds: number[] }) => Promise<DinerProfile[]>
  loadSurveys: (params: any) => Promise<Survey[]>
  searchAccountingClients: (params: { ids: number[] }) => Promise<Account[]>
  searchOrders: (params: any) => Promise<Order[]>
}

const PromptBuilder: React.FC<Props> = ({
  headquarters,
  getMarketActiveItems,
  loadDinerProfiles,
  loadSurveys,
  searchAccountingClients,
  searchOrders,
}) => {
  const [selectedHq, setSelectedHq] = useState<number | null>(null)
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)
  const [selectedDinerProfile, setSelectedDinerProfile] =
    useState<DinerProfile | null>(null)
  const [selectedDinerProfileText, setSelectedDinerProfileText] =
    useState<string>('')
  const [availDinerProfiles, setAvailDinerProfiles] = useState<DinerProfile[]>(
    [],
  )
  const [availAccounts, setAvailAccounts] = useState<Record<number, Account>>(
    {},
  )
  const [searchOrdersFrom, setSearchOrdersFrom] =
    useState<Moment.Moment | null>(null)
  const [searchOrdersTo, setSearchOrdersTo] = useState<Moment.Moment | null>(
    null,
  )
  const [menuItems, setMenuItems] = useState<any[]>([])
  const [loadingSurveys, setLoadingSurveys] = useState(false)
  const [loadingOrders, setLoadingOrders] = useState(false)
  const [loadingMenuItems, setLoadingMenuItems] = useState(false)
  const [orders, setOrders] = useState<Order[]>([])
  const [ordersText, setOrdersText] = useState<string>('')
  const [surveys, setSurveys] = useState<Survey[]>([])
  const [surveysText, setSurveysText] = useState<string>('')

  useEffect(() => {
    if (selectedHq) {
      loadDinerProfilesAndAccountsForHq(selectedHq)
      loadAllMarketMenuItems(selectedHq)
    }
  }, [selectedHq])

  const loadDinerProfilesAndAccountsForHq = async (hqId: number) => {
    let dinerProfiles = await loadDinerProfiles({ hqIds: [hqId] })
    const accountIds = Array.from(
      new Set(dinerProfiles.map((profile) => profile.accountId)),
    )
    const accountsList = await searchAccountingClients({ ids: accountIds })
    const accounts = accountsList.reduce(
      (dict, account) => {
        dict[account.id] = account

        return dict
      },
      {} as Record<number, Account>,
    )

    dinerProfiles.forEach((profile) => {
      const client = accounts[profile.accountId]
      const clientName = client?.name || '?'
      const repName =
        client?.clientChampion?.firstName ||
        client?.clientHunter?.firstName ||
        '?'
      profile.detailName = `${repName} | ${clientName} | ${profile.name}`
    })

    dinerProfiles = dinerProfiles.sort((a, b) =>
      (a.detailName || '') > (b.detailName || '') ? 1 : -1,
    )
    setAvailDinerProfiles(dinerProfiles)
    setAvailAccounts(accounts)
  }

  const loadOrders = async () => {
    if (!searchOrdersFrom || !searchOrdersTo || !selectedAccount) {
      return
    }
    setLoadingOrders(true)
    const orders = await searchOrders({
      fromDate: searchOrdersFrom,
      toDate: searchOrdersTo,
      accountId: selectedAccount.id,
      serializer: 'for_orders',
    })
    setOrders(orders || [])
    setOrdersText(orders ? JSON.stringify(orders, null, 2) : '')
    setLoadingOrders(false)
    loadOrderSurveys()
  }

  const loadAllMarketMenuItems = async (hq: number) => {
    setLoadingMenuItems(true)
    const items = await getMarketActiveItems(hq)
    setMenuItems(items || [])
    setLoadingMenuItems(false)
  }

  const loadOrderSurveys = async () => {
    if (!searchOrdersFrom || !searchOrdersTo || !selectedAccount) {
      return
    }
    setLoadingSurveys(true)
    const allSurveys = await loadSurveys({
      filters: {
        accountId: selectedAccount.id,
        startDate: searchOrdersFrom || Moment(),
        endDate: searchOrdersTo || Moment(),
      },
      surveyName: 'office_post',
    })

    const ordersMap = orders.reduce(
      (dict, order) => {
        dict[order.orderNumber] = true

        return dict
      },
      {} as Record<string, boolean>,
    )

    const filteredSurveys = (allSurveys || []).filter(
      (survey) => ordersMap[survey.results[0].answer],
    )
    setSurveys(filteredSurveys)
    setSurveysText(JSON.stringify(filteredSurveys, null, 2))
    setLoadingSurveys(false)
  }

  const onSelectHq = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedHq(e.target.value ? parseInt(e.target.value) : null)
  }

  const onSelectDinerProfile = (e: ChangeEvent<HTMLSelectElement>) => {
    const profile = availDinerProfiles.find(
      (prof) => prof.id === e.target.value,
    )
    if (!profile) {
      return
    }
    const account = availAccounts[profile.accountId]
    setSelectedDinerProfile(profile)
    setSelectedAccount(account || null)
    setSelectedDinerProfileText(JSON.stringify(profile, null, 2))
    setOrders([])
    setOrdersText('')
    setSurveys([])
    setSurveysText('')
  }

  return (
    <div className="flex flex-col w-3/4 align-center items-center justify-center py-4">
      <div className="flex justify-between">
        <h2 className="extra-bold hungry-grey text-4xl text-hungryGray text-left">
          Prompt Builder
        </h2>
      </div>

      <YSpacing height="20px" />

      <Dropdown
        label="Market"
        width="18rem"
        disabled={!!selectedHq}
        onChange={onSelectHq}
      >
        <option />
        {headquarters.map((hq) => (
          <option key={hq.id} value={hq.id}>
            {hq.name}
          </option>
        ))}
      </Dropdown>

      {selectedHq && (
        <>
          <YSpacing height="20px" />

          <h2 className="bold hungry-grey text-xl text-hungryGray text-left">
            Menu Items ({menuItems.length})
          </h2>

          <YSpacing height="10px" />

          {loadingMenuItems ? (
            <LoadingIndicator />
          ) : (
            <textarea
              className="border w-full regular p-2 bg-white rounded border-gray-400"
              rows={15}
              value={JSON.stringify(menuItems, null, 2)}
            />
          )}
        </>
      )}

      {selectedAccount && (
        <h2 className="bold hungry-grey text-xl text-hungryGray text-left">
          Client: {selectedAccount.name} ({selectedAccount.pin})
        </h2>
      )}

      <Dropdown
        label="Diner Profile"
        width="18rem"
        value={selectedDinerProfile?.id || ''}
        onChange={onSelectDinerProfile}
      >
        <option />
        {availDinerProfiles.map((profile) => (
          <option key={profile.id} value={profile.id}>
            {profile.detailName}
          </option>
        ))}
      </Dropdown>

      {selectedDinerProfile && (
        <>
          <textarea
            className="border w-full regular p-2 bg-white rounded border-gray-400"
            rows={15}
            value={selectedDinerProfileText}
          />

          <h2 className="bold hungry-grey text-xl text-hungryGray text-left">
            Orders ({orders.length})
          </h2>

          <FlexContainer
            alignItems="flex-start"
            justifyContent="space-between"
            width="50%"
          >
            <DateInput
              label="From"
              date={searchOrdersFrom}
              width="30%"
              onChange={(val: Moment.Moment) =>
                setSearchOrdersFrom(val.clone().startOf('day'))
              }
              clearDate={() => setSearchOrdersFrom(null)}
            />
            <DateInput
              label="To"
              date={searchOrdersTo}
              width="30%"
              onChange={(val: Moment.Moment) =>
                setSearchOrdersTo(val.clone().endOf('day'))
              }
              clearDate={() => setSearchOrdersTo(null)}
            />
            {searchOrdersFrom && searchOrdersTo && (
              <Button label="Search Orders And Surveys" onClick={loadOrders} />
            )}
          </FlexContainer>

          {loadingOrders ? (
            <LoadingIndicator />
          ) : (
            <textarea
              className="border w-full regular p-2 bg-white rounded border-gray-400"
              rows={15}
              value={ordersText}
            />
          )}

          <h2 className="bold hungry-grey text-xl text-hungryGray text-left">
            Surveys ({surveys.length})
          </h2>

          {loadingSurveys ? (
            <LoadingIndicator />
          ) : (
            <textarea
              className="border w-full regular p-2 bg-white rounded border-gray-400"
              rows={15}
              value={surveysText}
            />
          )}
        </>
      )}
    </div>
  )
}

export default PromptBuilder
