import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, TextArea } from '@components/common/form'

class ChefNote extends Component {
  state = {
    id: undefined,
    chefId: undefined,
    words: '',
    hungryDeliveryRequired: true,
  }

  timer = null

  componentDidMount() {
    this.refereshAttributes(this.props, true)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.refereshAttributes(nextProps)
    }
  }

  refereshAttributes = (props, update = false) => {
    const { chefId, chefNote } = props
    const newState = {}
    if (chefNote) {
      for (const k in this.state) {
        newState[k] = chefNote[k]
      }
      const { hungryDeliveryRequired } = chefNote
      newState.hungryDeliveryRequired =
        hungryDeliveryRequired !== undefined
          ? hungryDeliveryRequired
          : this.state.hungryDeliveryRequired // Retain current value if undefined
    } else {
      newState.chefId = chefId
    }

    // make sure a chef note is created for all chefs on mount
    if (update) {
      this.setState(newState, this.updateChefNote)
    } else {
      this.setState(newState)
    }
  }

  updateChefNote = (updateServiceCosts = false) => {
    clearTimeout(this.timer)
    this.timer = setTimeout(
      () => this.props.onEditChefNote(this.state, updateServiceCosts),
      1000,
    )
  }

  onChangeChefNote = (e) => {
    const { value } = e.target
    this.setState({ words: value }, this.updateChefNote)
  }

  onChangeDeliveryRequired = (hungryDeliveryRequired) => {
    this.setState({ hungryDeliveryRequired }, () => this.updateChefNote(true))
  }

  onClickDelivery = (hungryDeliveryRequired) => () => {
    if (hungryDeliveryRequired) {
      this.props.flashInfoOptions(
        'Are you sure you don’t need a Catering Captain to deliver this order?',
        {
          buttonAction: () =>
            this.onChangeDeliveryRequired(!hungryDeliveryRequired),
          buttonTitle: 'Confirm',
        },
      )
    } else {
      this.onChangeDeliveryRequired(!hungryDeliveryRequired)
    }
  }

  render() {
    const { chefName } = this.props
    const { hungryDeliveryRequired, words } = this.state

    return (
      <tbody>
        <tr>
          <td />
          <td colSpan="5">
            <TextArea
              label={`Notes for chef ${chefName}`}
              value={words}
              onChange={this.onChangeChefNote}
            />
          </td>
        </tr>
        <tr>
          <td />
          <td colSpan="5">
            <Checkbox
              label="Hungry Delivery Required"
              value={hungryDeliveryRequired}
              checked={hungryDeliveryRequired}
              onChange={this.onClickDelivery(hungryDeliveryRequired)}
            />
          </td>
        </tr>
      </tbody>
    )
  }
}

ChefNote.propTypes = {
  chefId: PropTypes.string,
  chefName: PropTypes.string,
  chefNote: PropTypes.object,

  onEditChefNote: PropTypes.func,
  flashInfoOptions: PropTypes.func,
}

export default ChefNote
