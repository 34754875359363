import { _ } from 'core-js'
import {
  pResponseMenuItemCreation,
  pResponseMenuItemUpdate,
} from '../../presenters/api/chefMenuItem'
import { pResponseGroupOrder } from '../../presenters/api/groupOrder'
import { pResponseCateringOrder } from '../../presenters/api/order'
import { pResponsePopUpOrder } from '../../presenters/api/popUp'
import { ScheduleUpdateStatus } from '@types'

const fetchOrders = async (RestService, HandleError, req) => {
  try {
    let cateringOrders = []
    let groupOrders = []
    let popUpOrders = []
    await Promise.all([
      RestService.get('/api/admin/orders/by_headquarter', {
        from_date: req.fromDate,
        to_date: req.toDate,
        headquarter_id: req.headquarterId,
      }),
      RestService.get('/group-orders/chef-orders/status', {
        fromDate: req.fromDate,
        toDate: req.toDate,
        hqId: req.headquarterId,
      }),
      RestService.get('/pop-ups', {
        fromDate: req.fromDate,
        toDate: req.toDate,
        headquarter: req.headquarterId,
      }),
    ]).then(([cateringResp, groupResp, popUpResp]) => {
      cateringOrders = cateringResp.map((o) => pResponseCateringOrder(o)).flat()
      groupOrders = groupResp.map((o) => pResponseGroupOrder(o))
      popUpOrders = popUpResp.map((o) => pResponsePopUpOrder(o))
    })

    return [...cateringOrders, ...groupOrders, ...popUpOrders].sort((a, b) => {
      return new Date(a.date) - new Date(b.date)
    })
  } catch (error) {
    HandleError({ error, namespace: 'chefNetwork' })
  }
}

const reviewOrder = async (RestService, HandleError, order) => {
  try {
    switch (order.mealType) {
      case 'Pop-Up':
        await RestService.put(`/pop-ups/${order.id}/cn-status`, {
          cnStatus: true,
        })
        break
      case 'Group Order':
        await RestService.put(`/group-orders/cn-status`, {
          orderId: order.id,
          chefId: order.chef.id,
          chefStatus: {
            isAccepted: order.isAccepted,
            declineReason: order.declineReason,
            cnStatus: true,
          },
        })
        break
      default:
        await RestService.put(`/api/admin/orders/${order.id}/cn_status`, {
          chef_id: order.chef.id,
          cn_status: true,
        })
    }
  } catch (error) {
    HandleError({ error, namespace: 'chefNetwork' })
  }
}

// loads all orders and whether a chef has accepted/rejected the order
export const LoadChefOrderStatus =
  ({ RestService, HandleError }) =>
  (req) =>
    fetchOrders(RestService, HandleError, req)

// this request changes cnReviewed to true. cn = chef network
export const ChefNetworkReviewedOrder =
  ({ RestService, HandleError }) =>
  async (req) => {
    try {
      await reviewOrder(RestService, HandleError, req.order)

      return fetchOrders(RestService, HandleError, {
        fromDate: req.fromDate,
        toDate: req.toDate,
        headquarterId: req.headquarterId,
      })
    } catch (error) {
      HandleError({ error, namespace: 'chefNetwork' })
    }
  }

export const LoadMenuItemUpdates =
  ({ RestService, HandleError }) =>
  async (req) => {
    try {
      return fetchMenuItemUpdateReqs(RestService, req)
    } catch (error) {
      HandleError({ error, namespace: 'chefNetwork' })
    }
  }

export const LoadNewMenuItems =
  ({ RestService, HandleError }) =>
  async (req) => {
    try {
      return fetchMenuItemCreationReqs(RestService, req)
    } catch (error) {
      HandleError({ error, namespace: 'chefNetwork' })
    }
  }

export const ChefNetworkCompletedItemUpdate =
  ({ RestService, HandleError }) =>
  async (req) => {
    try {
      await RestService.put(
        `/api/admin/menu_item_update_requests/${req.id}`,
        req,
      )

      return true
    } catch (error) {
      HandleError({ error, namespace: 'chefNetwork' })

      return false
    }
  }

export const ChefNetworkCreatedNewItem =
  ({ RestService, HandleError }) =>
  async (req) => {
    try {
      await RestService.put(
        `/api/admin/menu_item_creation_requests/${req.id}`,
        req,
      )

      return true
    } catch (error) {
      HandleError({ error, namespace: 'chefNetwork' })

      return false
    }
  }

export const ChefNetworkDeletedCreateRequest =
  ({ RestService, HandleError }) =>
  async (req) => {
    try {
      await RestService.delete(
        `/api/admin/menu_item_creation_requests/${req.id}`,
      )

      return true
    } catch (error) {
      HandleError({ error, namespace: 'chefNetwork' })

      return false
    }
  }

export const ChefNetworkDeletedUpdateRequest =
  ({ RestService, HandleError }) =>
  async (req) => {
    try {
      await RestService.delete(`/api/admin/menu_item_update_requests/${req.id}`)

      return true
    } catch (error) {
      HandleError({ error, namespace: 'chefNetwork' })

      return false
    }
  }

export const ChefNetworkSaveMenuItemImage =
  ({ RestService, HandleError }) =>
  async (params) => {
    try {
      await RestService.put(
        '/api/admin/menu_item_update_requests/update_menu_item_image',
        params,
      )

      return true
    } catch (error) {
      HandleError({ error, namespace: 'chefNetwork' })

      return false
    }
  }
export const ChefNetworkUpdateItemRequest =
  ({ RestService, HandleError }) =>
  async (updateReq, fetchReq, forNewItems) => {
    try {
      if (forNewItems) {
        await RestService.put(
          `/api/admin/menu_item_creation_requests/${updateReq.id}`,
          updateReq,
        )

        return fetchMenuItemCreationReqs(RestService, fetchReq)
      } else {
        await RestService.put(
          `/api/admin/menu_item_update_requests/${updateReq.id}`,
          updateReq,
        )

        return fetchMenuItemUpdateReqs(RestService, fetchReq)
      }
    } catch (error) {
      HandleError({ error, namespace: 'chefNetwork' })
    }
  }

const fetchMenuItemUpdateReqs = async (RestService, req) => {
  const items = await RestService.get(
    '/api/admin/menu_item_update_requests',
    req,
  )

  return items
    .map((item) => pResponseMenuItemUpdate(item))
    .sort((a, b) => {
      return new Date(a.date) - new Date(b.date)
    })
}

const fetchMenuItemCreationReqs = async (RestService, req) => {
  const items = await RestService.get(
    '/api/admin/menu_item_creation_requests',
    req,
  )

  return items
    .map((item) => pResponseMenuItemCreation(item))
    .sort((a, b) => {
      return new Date(a.date) - new Date(b.date)
    })
}

export const LoadChefScheduleUpdateRequests =
  ({ RestService, pResponseChefScheduleUpdates, HandleError }) =>
  async (req) => {
    try {
      const scheduleUpdateReqs = await RestService.get(
        '/chefs/schedule-updates',
        req,
      )

      return pResponseChefScheduleUpdates(scheduleUpdateReqs)
    } catch (error) {
      HandleError({ error, namespace: 'chefNetwork' })
    }
  }

export const ProcessChefScheduleUpdateRequest =
  ({ RestService, UIService, HandleError }) =>
  async (reqId, status, chefId, from, end) => {
    try {
      if (status === ScheduleUpdateStatus.Approved) {
        const params = {
          chefId,
          from,
        }
        if (end) {
          params.end = end
        }
        const orders = await RestService.get('/chefs/orderables', params, {
          timeout: 30000,
        })
        if (orders && orders.length > 0) {
          const orderNumbers = orders.map((o) => o.orderNumber).join(', ')
          const approved = await UIService.ConfirmationModal.show({
            title: `Chef has ${
              orders.length > 1 ? 'orders' : 'an order'
            } that may conflict with this schedule change`,
            text: `Chef has ${
              orders.length > 1 ? 'orders' : 'an order'
            } that may conflict with this schedule change. <br /> Order${
              orders.length > 1 ? 's' : ''
            }: ${orderNumbers} <br /> Are you sure you want to approve this schedule change?`,
          })
          if (!approved) {
            return false
          }
        }
      }
      await RestService.post(`/chefs/schedule-updates/${reqId}`, { status })

      return true
    } catch (error) {
      HandleError({ error, namespace: 'chefNetwork' })

      return false
    }
  }
