import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from 'react-bootstrap'
import YSpacing from '@components/common/YSpacing'

import {
  AccountProfile,
  AccountBatchInvoices,
  EmailNotifications,
  PromoCodes,
  PreferenceProfile,
  GroupOrderCustomers,
  DinerProfiles,
  OrderableHistory,
} from '@containers/account/accountSections'

export class AccountDetail extends Component {
  state = {
    activeKey: '#overview',
  }

  componentDidMount() {
    const { location } = this.props
    const qParams = location.search
    if (qParams) {
      const params = new URLSearchParams(qParams)
      const activeTab = params.get('tab')
      if (activeTab) {
        this.setState({ activeKey: `#${activeTab}` })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // If a new account is selected reset the active tab back to overview
    if (
      nextProps.account &&
      this.props.account &&
      nextProps.account.id !== this.props.account.id
    ) {
      this.setState({ activeKey: '#overview' })
    }
  }

  render() {
    const { account } = this.props
    const { autoBatchActive, netPaymentDays, autoBatchPeriod } = account || {}
    if (!account || Object.keys(account).length === 0) {
      return null
    }

    return (
      <Tabs
        className="tabs"
        activeKey={this.state.activeKey}
        onSelect={(activeKey) => {
          this.setState({ activeKey })
        }}
        mountOnEnter={true}
        unmountOnExit={true}
        animation={false}
        id="account-tabs"
      >
        <Tab id="account-tab-overview" eventKey={'#overview'} title="Overview">
          <AccountProfile />
        </Tab>

        <Tab
          id="account-tab-overview"
          eventKey={'#dinerProfiles'}
          title="Diner Profiles"
        >
          <DinerProfiles />
        </Tab>

        <Tab
          id="account-tab-overview"
          eventKey={'#groupOrderCustomers'}
          title="Group Order Customers"
        >
          <GroupOrderCustomers />
        </Tab>

        <Tab
          id="account-tab-overview"
          eventKey={'#emailNotifications'}
          title="Email Notifications"
        >
          <EmailNotifications />
        </Tab>

        <Tab eventKey={'#promo'} title="Promo Codes">
          <PromoCodes />
        </Tab>

        <Tab eventKey={'#preferenceProfile'} title="Preference Profiles">
          <PreferenceProfile />
        </Tab>

        <Tab eventKey={'#salesHistory'} title="Sales History">
          <div>
            <OrderableHistory
              orderable="Proposal"
              orderableType="proposals"
              searchOrderables={this.props.searchProposals}
              getOrderableCount={this.props.getProposalCount}
              getOrderableTotal={this.props.getProposalTotal}
            />
            <YSpacing height="30px" />
            <OrderableHistory
              orderable="Order"
              orderableType="sales"
              searchOrderables={this.props.searchOrders}
              getOrderableCount={this.props.getOrderCount}
              getOrderableTotal={this.props.getOrderTotal}
            />
          </div>
        </Tab>

        {autoBatchActive && (
          <Tab eventKey={'#batchInvoices'} title="Batch Invoices">
            <AccountBatchInvoices
              netPaymentDays={netPaymentDays}
              autoBatchPeriod={autoBatchPeriod}
            />
          </Tab>
        )}
      </Tabs>
    )
  }
}

AccountDetail.propTypes = {
  account: PropTypes.object,
  location: PropTypes.object,

  getOrderCount: PropTypes.func,
  getOrderTotal: PropTypes.func,
  getProposalCount: PropTypes.func,
  getProposalTotal: PropTypes.func,
  searchOrders: PropTypes.func,
  searchProposals: PropTypes.func,
}

export default AccountDetail
