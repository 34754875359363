import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import FlexContainer from '@components/common/FlexContainer'
import YSpacing from '@components/common/YSpacing'
import { getOrderBatchInvs, calcOrderTotalUnpaidBatchedAmt } from '@utils'

const Transactions = (props) => {
  const { transactions, order, getAccountingOrder } = props
  const [accountingOrder, setAccountingOrder] = useState(null)
  const isProposal = order.orderableType !== 'Order'

  const loadAccountingOrder = useCallback(async () => {
    if (isProposal) {
      return
    }
    const accountingOrder = await getAccountingOrder(order.id)
    setAccountingOrder(accountingOrder)
  }, [order, isProposal, getAccountingOrder])

  useEffect(() => {
    loadAccountingOrder()
  }, [order, loadAccountingOrder])

  const sortedTransactions = transactions.sort((a, b) => {
    return a.createdAt > b.createdAt ? 1 : -1
  })
  const paidAmount = parseFloat(order.paidAmount)
  const orderTotal = parseFloat(order.total)
  const totalOutstanding = orderTotal - paidAmount

  let arStatus = 'Loading...'
  let invoices = 'Loading...'
  let batchUnpaidStr = 'Loading...'
  const isAccountingLoaded = accountingOrder && accountingOrder.id === order.id
  if (isAccountingLoaded) {
    arStatus = accountingOrder.isPaid ? 'Paid' : 'Unpaid'
    const invoiceNums = getOrderBatchInvs(accountingOrder)
    const batchUnpaid = calcOrderTotalUnpaidBatchedAmt(accountingOrder)
    invoices = invoiceNums.length ? invoiceNums.join(', ') : 'None'
    let batchedStatus = ''
    if (invoiceNums.length === 0) {
      batchedStatus = '(Not Batched)'
    } else if (batchUnpaid === totalOutstanding) {
      batchedStatus = '(Fully Batched)'
    } else if (batchUnpaid > totalOutstanding) {
      batchedStatus = '(Over-Invoiced)'
    } else if (batchUnpaid < totalOutstanding) {
      batchedStatus = '(Under-Invoiced)'
    }
    batchUnpaidStr = `$${batchUnpaid.toFixed(2)} ${batchedStatus}`
  }

  return (
    <div>
      {Boolean(!isProposal && isAccountingLoaded) && (
        <FlexContainer width="60%" flexDirection="column">
          <table className="table table-hover page">
            <thead>
              <tr>
                <th>Total Due</th>
                <th>Total Paid</th>
                <th>Total Unpaid</th>
                <th>Total Batched Unpaid</th>
                <th>AR Status</th>
                <th>Batch Invoices</th>
              </tr>
            </thead>
            <tbody id="accounting">
              <tr>
                <td>${orderTotal.toFixed(2)}</td>
                <td>${paidAmount.toFixed(2)}</td>
                <td className={totalOutstanding > 0 ? 'bold' : ''}>
                  ${totalOutstanding.toFixed(2)}
                </td>
                <td>{batchUnpaidStr}</td>
                <td className={arStatus === 'Unpaid' ? 'bold' : ''}>
                  {arStatus}
                </td>
                <td>{invoices}</td>
              </tr>
            </tbody>
          </table>
        </FlexContainer>
      )}

      <YSpacing height="20px" />
      <br />
      <table className="table table-hover page">
        <thead>
          <tr>
            <th>Date</th>
            <th>Transaction Id</th>
            <th>Order Number</th>
            <th>User Email</th>
            <th>Amount</th>
            <th>Service Fee</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody id="transactions">
          {sortedTransactions.length > 0 ? (
            sortedTransactions.map((t) => (
              <tr key={t.transactionId || t.id}>
                <td> {t.createdAt} </td>
                <td> {t.transactionId} </td>
                <td> {t.orderNumber} </td>
                <td>
                  <a href={'/users/' + t.userId}> {t.userEmail} </a>
                </td>
                <td> ${t.amount} </td>
                <td> ${t.serviceFeeAmount} </td>
                <td> {t.status} </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '1.2em',
                    color: 'rgba(0,0,0,0.2)',
                    padding: '20px',
                    backgroundColor: 'rgba(0,0,0,0.01)',
                  }}
                >
                  {' '}
                  No transactions yet.{' '}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

Transactions.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object),
  order: PropTypes.object,

  getAccountingOrder: PropTypes.func,
}

Transactions.defaultProps = {
  transactions: [],
  order: null,
}

export default Transactions
