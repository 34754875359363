import { Chef } from '@types'
import { Moment } from 'moment-timezone'

export type Concept = {
  estimatedPricePerPerson: string
  name: string
  margin: string
}

export type User = {
  firstName: string
  lastName: string
}

export const isUser = (value: any): value is User =>
  value && 'firstName' in value && 'lastName' in value && 'signInCount' in value // Unique to User

export type Audit = {
  action: string
  createdBy: User
  created_at: string
}

export type CateringOrderMenuItem = {
  id: string
  chefId: string
  internalNotes: string
  name: string
  price: string
  quantity: number
  menuItem: MenuItem
}

type MenuItem = {
  description: string
  id: string
  internalNotes: string
  packaging: Packaging
  price: string
  pricePerPersonCalc: string
  retailPrice: string
  servingSize: string
  servingsPerPkg: string
  tagsDietaryPreferenceList: string
}

type Packaging = {
  name: string
}

type DropoffAddress = {
  city: string
  line1: string
  line2: string
  state: string
  zip: string
}

export type CateringOrder = {
  accountExecutive: User
  account: { name: string }
  audits: Audit[]
  clientSetUpTime: string
  concept?: Concept
  conceptName: string
  contact: { name: string }
  chefs: Chef[]
  chefName: string
  cleanupFee: string | number
  dateMoment?: Moment
  deliveryFee: string | number
  didModify?: boolean
  dinerProfileId: string | null
  dropoffAddress: DropoffAddress
  headCount: string | number
  id: string
  orderMenuItems: CateringOrderMenuItem[]
  orderNumber: string
  serviceFee: string | number
  staffingFee: string | number
  subtotal: string
  tax: string
  tip: string
  total: string
}
