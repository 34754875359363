export const GetUnpaidAccounts =
  ({
    AccountsReceivableService,
    RestService,
    UIService,
    pRequestARParams,
    pResponseAccountsReceivable,
    pGroupARByClient,
    pGroupARByRep,
    HandleError,
  }) =>
  async ({
    fromDate,
    toDate,
    sort,
    type,
    name,
    requestTime,
    selectedHeadquarters,
  }) => {
    try {
      UIService.AccountsReceivable.toggleLoading(true)
      AccountsReceivableService.clearAccountsReceivable()

      const params = pRequestARParams({
        type,
        name,
        fromDate,
        toDate,
        sort,
        selectedHeadquarters,
      })

      const limit = 750
      let offset = 0
      let fin = false
      const allAR = []
      while (!fin) {
        const batchParams = { ...params, limit, offset }
        const response = await RestService.get(
          '/accounting/accounts-receivable/page',
          batchParams,
          { timeout: 30000 },
        )
        const arBatch = pResponseAccountsReceivable(response)
        allAR.push(...arBatch)
        if (response.length !== limit) {
          fin = true
        }
        offset += limit
      }

      let groupedAR = []
      if (type === 'account') {
        groupedAR = pGroupARByClient([], allAR)
      } else {
        groupedAR = pGroupARByRep([], allAR)
      }

      // sorting
      if (sort === 'alpha') {
        groupedAR = groupedAR.sort((a, b) => (a.Id.name > b.Id.name ? 1 : -1))
      } else if (sort === 'total') {
        groupedAR = groupedAR.sort((a, b) =>
          a.unpaidBalance > b.unpaidBalance ? 1 : -1,
        )
      } else if (sort === 'oldest') {
        groupedAR = groupedAR.sort((a, b) =>
          a.largestDaysPastDue > b.largestDaysPastDue ? 1 : -1,
        )
      }

      AccountsReceivableService.setAccountsReceivable(groupedAR, requestTime)
      UIService.AccountsReceivable.toggleLoading(false)

      return true
    } catch (error) {
      HandleError({ error, namespace: 'accountsReceivable' })
      UIService.AccountsReceivable.toggleLoading(false)

      return false
    }
  }

export const UpdateARAfterMarkingPaid =
  ({ AccountsReceivableService }) =>
  (ar, recordId, orderId) => {
    ar.map((record) => {
      if (record.Id.id === recordId) {
        const { orders, unpaidBalance } = record
        const order = record.orders.find((o) => o.id === orderId)
        record.orders = orders.filter((o) => o.id !== orderId)
        record.unpaidBalance = unpaidBalance - order.unpaidBalance

        return record
      } else {
        return record
      }
    })
    AccountsReceivableService.setAccountsReceivable(ar)
  }

export const LoadOrder =
  ({ RouterService }) =>
  (orderId) => {
    RouterService.push(`/sales/${orderId}`)
  }
