import React from 'react'
import PropTypes from 'prop-types'
import { FormInputContainer } from '@res/styledComponents/index'

const LabelInfo = (props) => {
  const { value, label, marginBottom, testId, width, spacing, margin } = props

  return (
    <FormInputContainer
      style={{ overflowWrap: 'break-word', marginBottom: spacing }}
      width={width}
      margin={margin}
      marginBottom={marginBottom}
    >
      <label>{label}</label>
      <p className="break-normal" data-testId={testId}>
        {value}
      </p>
    </FormInputContainer>
  )
}

LabelInfo.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  testId: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  marginBottom: PropTypes.string,
  spacing: PropTypes.string,
}

export default LabelInfo
