import { connect } from 'react-redux'

import ChefScheduleUpdates from '@components/chefNetwork/ChefScheduleUpdates'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const { LoadChefScheduleUpdateRequests, ProcessChefScheduleUpdateRequest } =
    coordinators
  const { pResponseChefScheduleUpdates } = presenters.Api

  const loadChefScheduleUpdates = LoadChefScheduleUpdateRequests({
    RestService,
    pResponseChefScheduleUpdates,
    HandleError,
  })
  const processChefScheduleUpdateRequest = ProcessChefScheduleUpdateRequest({
    RestService,
    UIService,
    HandleError,
  })

  return {
    loadChefScheduleUpdates,
    processChefScheduleUpdateRequest,
  }
}

export default connect(undefined, mapDispatchToProps)(ChefScheduleUpdates)
