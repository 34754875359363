import {
  DelayedUpdateOrderable,
  DeleteOrderable,
  EditOrderable,
} from '@coordinators/orderable'
import { snakeCaseify } from '~/utils'

export const ChangeProposalStatus =
  ({ ProposalService, RestService, pResponseProposal }) =>
  async (code) => {
    const proposalId = ProposalService.orderableId()
    let proposal = await RestService.put(`/api/admin/proposals/${proposalId}`, {
      proposal: { proposal_statuses_attributes: [{ code }] },
    })
    proposal = pResponseProposal(proposal)
    ProposalService.setOrderable(proposal)
  }

export const DeleteProposal =
  ({ ProposalService, RestService, UIService, HandleError }) =>
  async () => {
    return DeleteOrderable({
      reduxService: ProposalService,
      RestService,
      UIService,
      HandleError,
      url: '/api/admin/proposals/',
    })()
  }

export const EditProposal =
  ({
    ProposalService,
    OrderService,
    RestService,
    UIService,
    pResponseEditProposal,
  }) =>
  async (section) => {
    return EditOrderable({
      reduxService: ProposalService,
      OrderService,
      RestService,
      UIServiceEditOrderable: UIService.EditProposal,
      pResponseEdit: pResponseEditProposal,
      url: '/api/admin/proposals/',
    })(section)
  }

export const DelayedUpdateProposal =
  ({ ProposalService, UIService }) =>
  (data) => {
    return DelayedUpdateOrderable({
      reduxService: ProposalService,
      UIService,
      UIServiceEditOrderable: UIService.EditProposal,
    })(data)
  }

export const SaveProposal =
  ({
    OrderService,
    ProposalService,
    RestService,
    RouterService,
    SessionService,
    UIService,
    pRequestUpdateProposal,
    pResponseProposal,
    pResponseProposalError,
    HandleError,
  }) =>
  async (data) => {
    const { chefAmount, preTaxTotal, serviceFee, subtotal, tax, total } =
      OrderService.calculateAll(data)
    data = {
      ...data,
      chefAmount,
      preTaxTotal,
      serviceFee,
      subtotal,
      tax,
      total,
    }
    const proposalData = pRequestUpdateProposal(data)
    let proposal
    try {
      if (proposalData.id) {
        // update
        proposal = await RestService.put(
          `/api/admin/proposals/${proposalData.id}`,
          {
            proposal: proposalData,
          },
          { timeout: 30000 },
        )
      } else {
        // create
        proposal = await RestService.post(
          '/api/admin/proposals',
          {
            proposal: proposalData,
          },
          { timeout: 30000 },
        )
      }
    } catch (error) {
      HandleError({
        RouterService,
        SessionService,
        UIService,
        pResponseError: pResponseProposalError,
      })({ error, namespace: 'editProposalModal' })

      return
    }

    proposal = pResponseProposal(proposal)
    ProposalService.setOrderable(proposal)
    const editMode = UIService.EditProposal.getMode() === 'edit'
    editMode
      ? ProposalService.clearEditOrderable()
      : ProposalService.clearNewOrderable()
    UIService.EditProposal.close()
  }

export const ProposalToOrder =
  ({
    ProposalService,
    RestService,
    UIService,
    pResponseErrorsArray,
    HandleError,
  }) =>
  async (callBackFn) => {
    const proposalId = ProposalService.orderableId()
    const params = { proposal_to_order: true, proposal_id: proposalId }
    let order
    try {
      order = await RestService.post('/api/admin/orders', params, {
        timeout: 30000,
      })
    } catch (error) {
      HandleError({ UIService, pResponseError: pResponseErrorsArray })({
        error,
        doFlash: true,
      })

      return
    }

    UIService.FlashMessage.displaySuccessMssgOptions(`Order created`, {
      buttonTitle: order.order_number,
      buttonAction: () => callBackFn(order),
      isLink: true,
    })
  }

export const ProposalsToOrders =
  ({ RestService, pResponseOrder, HandleError, pResponseError }) =>
  async (proposals, callbackFn) => {
    const batchSize = 1
    for (let i = 0; i < proposals.length; i += batchSize) {
      const batch = proposals.slice(i, i + batchSize)

      try {
        const promises = batch.map(({ proposalId }, idx) =>
          RestService.post(
            '/api/admin/orders',
            {
              proposal_to_order: true,
              proposal_id: proposalId,
            },
            {
              timeout: 30000,
            },
          )
            .then((order) =>
              callbackFn(pResponseOrder(order), i + idx, undefined),
            )
            .catch((error) => {
              const { errors, errorMessage } = pResponseError(error)
              const message = errors.message ? errors.message : errorMessage
              callbackFn(null, i + idx, message)
            }),
        )
        await Promise.all(promises)
      } catch (error) {
        HandleError({ error, doFlash: true })
      }
    }
  }

export const CopyProposal =
  ({
    ProposalService,
    OrderService,
    RestService,
    UIService,
    pAccountSettingsFromAccount,
    pResponseEditProposal,
    HandleError,
  }) =>
  async (callBackFn) => {
    const proposalId = ProposalService.orderableId()
    const proposalJson = await RestService.get(
      `/api/admin/proposals/${proposalId}`,
    )
    const { calculateDiscounts, getDeliveryFeePercentAndLimit } = OrderService
    const prevProposal = pResponseEditProposal({
      orderableJson: proposalJson,
      calculateDiscounts,
      getDeliveryFeePercentAndLimit,
    })

    const {
      account,
      clientDoNotArriveBeforeTime: prevClientDoNotArriveBeforeTime,
      // can be a date in the past but is the only date that we can easily confirm is valid based on chef schedules
      clientSetUpTime: prevClientSetUpTime,
      deliveryFee: prevDeliveryFee,
      needsStaffing: prevNeedsStaffing,
      numberOfStaff: prevNumberOfStaff,
      serviceFee: prevServiceFee,
      staffingFee: prevStaffingFee,
      staffingHours: prevStaffingHours,
      staffingRate: prevStaffingRate,
      tip: prevTip,
    } = prevProposal

    const accountSettings = pAccountSettingsFromAccount(account)

    const req = {
      proposal_id: proposalId,
      copy_proposal: true,
      copy_proposal_override_params: {
        account_settings: snakeCaseify(accountSettings),
        delivery_fee: prevDeliveryFee,
        service_fee: prevServiceFee,
        staffing_fee: prevStaffingFee,
        number_of_staff: prevNumberOfStaff,
        staffing_hours: prevStaffingHours,
        staffing_rate: prevStaffingRate,
        client_do_not_arrive_before_time: prevClientDoNotArriveBeforeTime,
        client_set_up_time: prevClientSetUpTime,
        needs_staffing: prevNeedsStaffing,
        tip: prevTip,
      },
    }

    let proposal
    try {
      proposal = await RestService.post('/api/admin/proposals', req, {
        timeout: 30000,
      })
    } catch (error) {
      HandleError({ error, doFlash: true })

      return
    }

    UIService.FlashMessage.displaySuccessMssgOptions('Proposal created: ', {
      buttonTitle: `${proposal.order_number}`,
      buttonAction: () => callBackFn(proposal),
      isLink: true,
    })
  }

export const OrderToProposal =
  ({ RestService, UIService, HandleError }) =>
  async (orderId, callBackFn) => {
    const req = {
      order_id: orderId,
      order_to_proposal: true,
    }

    let proposal
    try {
      proposal = await RestService.post('/api/admin/proposals', req, {
        timeout: 30000,
      })
    } catch (error) {
      HandleError({ error, doFlash: true })

      return
    }

    UIService.FlashMessage.displaySuccessMssgOptions('Proposal created: ', {
      buttonTitle: `${proposal.order_number}`,
      buttonAction: () => callBackFn(proposal),
      isLink: true,
    })
  }

export const OrderToProposals =
  ({
    RestService,
    OrderService,
    pRequestProposalFromOrder,
    pResponseProposal,
    HandleError,
    pResponseError,
  }) =>
  async (_, params, callbackFn) => {
    const batchSize = 1
    for (let i = 0; i < params.length; i += batchSize) {
      const batch = params.slice(i, i + batchSize)
      try {
        const promises = batch.map((param, index) =>
          RestService.post(
            '/api/admin/proposals',
            pRequestProposalFromOrder({
              params: param,
              OrderService,
            }),
            {
              timeout: 30000,
            },
          )
            .then((proposal) =>
              callbackFn(pResponseProposal(proposal), i + index, undefined),
            )
            .catch((error) => {
              const { errors, errorMessage } = pResponseError(error)
              const message = errors.message ? errors.message : errorMessage
              callbackFn(null, i + index, message)
            }),
        )
        await Promise.all(promises)
      } catch (error) {
        HandleError({ error, doFlash: true })
      }
    }
  }

export const DeleteProposals =
  ({ RestService, UIService, HandleError }) =>
  async (proposals = []) => {
    let deletedProposals = []

    if (proposals.length === 0) {
      return deletedProposals
    }
    const proposalNames = proposals.map((p) => p.orderNumber)
    const proposalIds = proposals.map((p) => p.id).join('&')
    const deleteProposalString = proposalNames.join(', ')
    const text = `Are you sure you want to delete ${deleteProposalString}?`
    const doDelete = await UIService.ConfirmationModal.show({ text })
    if (doDelete) {
      try {
        const response = await RestService.delete(
          `/api/admin/proposals/${proposalIds}`,
        )
        deletedProposals = response.deleted_proposals || []

        return deletedProposals
      } catch (error) {
        HandleError({ error })

        return deletedProposals
      }
    }
  }

export const ProposalsFromDinerProfile =
  ({
    RestService,
    pRequestProposalFromDinerProfile,
    pResponseProposal,
    HandleError,
    pResponseError,
  }) =>
  async (hqLocaleMap, params, callbackFn) => {
    const batchSize = 1
    for (let i = 0; i < params.length; i += batchSize) {
      const batch = params.slice(i, i + batchSize)
      try {
        const promises = batch.map((param, index) =>
          RestService.post(
            '/api/admin/proposals',
            pRequestProposalFromDinerProfile(hqLocaleMap, param),
            {
              timeout: 30000,
            },
          )
            .then((proposal) =>
              callbackFn(pResponseProposal(proposal), i + index, undefined),
            )
            .catch((error) => {
              const { errors, errorMessage } = pResponseError(error)
              const message = errors.message ? errors.message : errorMessage
              callbackFn(null, i + index, message)
            }),
        )
        await Promise.all(promises)
      } catch (error) {
        HandleError({ error, doFlash: true })
      }
    }
  }
