import { connect } from 'react-redux'

import { EditChildMenuItemModal } from '@components/chef/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const {
    editMenuItem: { isEnabled: parentEnabled, isApproved: parentApproved },
    editChildMenuItem,
  } = state
  const { show } = state.editChildMenuItemModal
  const errors = state.errors.menuItemModal || {}

  return {
    errors,
    menuItem: {
      ...editChildMenuItem,
    },
    parentEnabled,
    parentApproved,
    show,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, OrderService, RestService, SettingsService, UIService } =
    services
  const { pStateToReduxMenuItem, pToMenuItemVerifications } = presenters
  const {
    pRequestSettings,
    pResponseMenuItem,
    pResponseMenuItems,
    pResponseSettings,
    pRequestUpdateMarketplaceAddOn,
    pRequestUpdateMenuItem,
    pResponseChef,
    pRequestUpdateItemImages,
  } = presenters.Api
  const {
    CloseChildMenuItemModal,
    UpdateChildMenuItem,
    DeleteMenuItem,
    EditMenuItem,
    LoadSettings,
    RetryOnTimeout,
    SaveMenuItem,
    SelectChef,
  } = coordinators

  const { calculatePrice: calculateMarketPrice, calculateChefPrice } =
    OrderService
  const clearErrors = UIService.Errors.clear
  const { close } = UIService.EditChildMenuItem
  const saveMenuItem = SaveMenuItem({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateMarketplaceAddOn,
    pRequestUpdateMenuItem,
    pRequestUpdateItemImages,
    pResponseMenuItem,
    pResponseMenuItems,
    CloseChildMenuItemModal,
    EditMenuItem,
    HandleError,
    pStateToReduxMenuItem,
  })
  const updateChildMenuItem = UpdateChildMenuItem({
    ChefService,
    pStateToReduxMenuItem,
  })
  const deleteMenuItem = DeleteMenuItem({
    ChefService,
    RestService,
    UIService,
    pResponseChef,
    HandleError,
    SelectChef,
  })
  const closeChildMenuItemModal = CloseChildMenuItemModal({
    ChefService,
    RestService,
    UIService,
    pResponseMenuItem,
    EditMenuItem,
    pStateToReduxMenuItem,
  })
  const loadMenuItemSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('menuItems')
  const displayAlert = ({ message, title, options }) =>
    UIService.Alert.showPopup({ message, title, options })

  return {
    calculateChefPrice,
    calculateMarketPrice,
    clearErrors,
    close,
    closeChildMenuItemModal,
    displayAlert,
    updateMenuItem: updateChildMenuItem,
    deleteMenuItem,
    loadMenuItemSettings,
    pToMenuItemVerifications,
    saveMenuItem,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditChildMenuItemModal)
