import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Carousel } from 'react-bootstrap'
import Modal from '@components/common/modal/Modal'
import { AuthorizedInteractable } from '@containers/common/auth'
import {
  Checkbox,
  CurrencyInput,
  RadioButton,
  FileUpload,
} from '@components/common/form'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import DividerLine from '@components/common/DividerLine'
import FlexContainer from '@components/common/FlexContainer'
import Dropdown from '@components/common/form/Dropdown'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import Input from '@components/common/form/Input'
import TextArea from '@components/common/form/TextArea'
import TooltipModal from '@components/common/modal/TooltipModal'
import { MenuItemVerifications } from '@components/chef/edit'
import {
  Label,
  OrderFormSectionTitle,
  ServingWeightForm,
} from '@res/styledComponents/index'
import { colors } from '../../../../constants'
import removedImage from '@res/images/image-removed-320x240.png'
import {
  AllMpCalculationTypes,
  MaxImageSizeKB,
  ServingWeights,
} from '@constants'

const menuItemInitialState = {
  images: [],
  chefPrice: 0.0,
  marketPrice: 0.0,
  menuItemImages: [],
  pricePerPersonCalc: 'PerPerson',
  subMenuItemAllCategories: [],
  isApproved: true,
  isEnabled: true,

  allDefaultPackagings: [],
  allDietaryPreferences: [],
  allMealTypes: [],
  allServingUtensils: [],
}

class EditMenuItemModal extends Component {
  state = { ...menuItemInitialState }

  componentWillMount() {
    const { menuItem, clearErrors } = this.props
    const packagingId = menuItem.packaging && menuItem.packaging.id
    const servingUtensilId =
      menuItem.servingUtensil && menuItem.servingUtensil.id
    this.setState({ ...menuItem, packagingId, servingUtensilId })
    clearErrors()
    ;(async () => {
      const {
        allDefaultPackagings,
        allDietaryPreferences,
        allMealTypes,
        allServingUtensils,
      } = await this.props.loadMenuItemSettings()

      const servingUtensils = allServingUtensils.map((u) => {
        return { value: u.id, text: u.name }
      })

      servingUtensils.unshift({})

      const newState = {
        allDefaultPackagings,
        allDietaryPreferences,
        allMealTypes,
        allServingUtensils: servingUtensils,
        ...menuItem,
      }
      allDietaryPreferences.forEach((t) => {
        newState[this.getDietaryPrefKey(t)] = false
      })
      menuItem.tagsDietaryPreference.forEach((t) => {
        newState[this.getDietaryPrefKey(t)] = true
      })
      this.setState(newState)
    })()
  }

  componentWillReceiveProps(nextProps) {
    const { menuItem } = nextProps
    if (this.props.menuItem !== nextProps.menuItem) {
      const newState = { ...menuItem }
      this.state.allDietaryPreferences.forEach((t) => {
        newState[this.getDietaryPrefKey(t)] = false
      })
      menuItem.tagsDietaryPreference.forEach((t) => {
        newState[this.getDietaryPrefKey(t)] = true
      })
      this.setState(newState)
    }
  }

  getDietaryPrefKey = (tag) => {
    return `dietaryTag${tag}`
  }

  /* Events */

  onAddImage = (image) => {
    const { displayAlert } = this.props
    const { size } = image

    if (size / 1024 > MaxImageSizeKB) {
      displayAlert({
        title: 'Image File Too Large',
        message: `Images must be less than ${MaxImageSizeKB}kb in size.`,
      })

      return
    }

    let { images } = this.state
    images = [...images, image]
    this.setState({ images })
  }

  onChange = (key) => (e) => {
    const { value } = e.target
    this.setState({ [key]: value })
  }

  onChangeCheckbox = (e) => {
    const { checked, value: key } = e.target
    this.setState({ [key]: checked })
  }

  onChangeDietaryPref = (e) => {
    const { checked, value: key } = e.target
    const newState = { ...this.state, [this.getDietaryPrefKey(key)]: checked }

    const tags = []
    newState.allDietaryPreferences &&
      newState.allDietaryPreferences.forEach((p) => {
        if (newState[`dietaryTag${p}`] === true) {
          tags.push(p)
        }
      })
    newState.tagsDietaryPreference = tags
    this.setState(newState)
  }

  onChangeMenuItem = (key, value) => {
    const newState = { ...this.state }
    newState[key] = value
    this.setState(newState)
  }

  onChangeRadioButton = (e) => {
    const { value: key, name } = e.target
    this.setState({ [name]: key })
  }

  onDelete = () => {
    const { close, deleteMenuItem } = this.props
    const { chefId, id, name } = this.state
    deleteMenuItem({ id, chefId, name })
    close()
  }

  onDeleteImage = (index) => {
    const { images } = this.state
    images[index].destroy = !images[index].destroy
    this.setState({ images: [...images] })
  }

  onHide = () => {
    this.props.closeChildMenuItemModal(this.state)
  }

  onSave = () => {
    if (!this.validate()) {
      return
    }
    const { saveMenuItem, updateMenuItem } = this.props
    updateMenuItem(this.state)
    saveMenuItem(this.state)
  }

  validate = () => {
    const { tagsDietaryPreference = [] } = this.state
    const errors = []

    const prefContras = {
      vegetarian: {
        exclude: ['contains pork', 'contains shellfish', 'contains fish'],
        error: 'Dietary tags cannot contain vegetarian and meat products.',
      },
      vegan: {
        exclude: [
          'contains pork',
          'contains shellfish',
          'contains dairy',
          'contains eggs',
          'contains fish',
        ],
        error:
          'Dietary tags cannot contain vegetarian and meat, dairy, or eggs.',
      },
    }

    tagsDietaryPreference.forEach((pref) => {
      const excls = prefContras[pref]
      if (
        excls &&
        tagsDietaryPreference.find((p) => excls.exclude.includes(p))
      ) {
        errors.push(excls['error'])
      }
    })

    if (errors.length == 0) {
      return true
    } else {
      this.props.displayAlert({
        title: 'Validation Error',
        message: errors.join(' '),
      })

      return false
    }
  }

  render() {
    const {
      calculateChefPrice,
      calculateMarketPrice,
      errors,
      show,
      parentEnabled,
      parentApproved,
    } = this.props
    const {
      allDefaultPackagings,
      allDietaryPreferences,
      allServingUtensils,
      chefPrice,
      description,
      id,
      images,
      isApproved,
      isEnabled,
      marketPrice,
      name,
      parentMenuItemName,
      packagingId,
      pricePerPersonCalc,
      servingsPerPkg,
      servingUtensilId,
      servingWeightOzs,
      subMenuItemCategory,
      servingSize,
    } = this.state
    const childApproved = parentApproved ? parentApproved : isApproved
    const childEnabled = parentEnabled ? parentEnabled : isEnabled
    const title = id ? 'Edit Menu Item' : 'New Menu Item'

    return (
      <Modal
        show={show}
        title={title}
        hideModal={this.onHide}
        color="#001940"
        width="800px"
      >
        <YSpacing height="10px" />
        <BackButton onClick={this.onHide}>
          ◀ Go back to parent item: {parentMenuItemName}
        </BackButton>
        <OrderFormSectionTitle>Child Menu Item Details</OrderFormSectionTitle>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between" flexWrap="wrap">
          <Input
            width="31%"
            label="Menu Item Name"
            value={name}
            error={errors.name}
            onChange={this.onChange('name')}
          />
          <Input
            width="31%"
            label="Category"
            value={subMenuItemCategory}
            onChange={this.onChange('subMenuItemCategory')}
          />
          <XSpacing width="31%" />
          <Input
            width="31%"
            label="Serving Size"
            value={servingSize}
            onChange={this.onChange('servingSize')}
          />
          <Input
            width="31%"
            label="Servings Per Package"
            type="number"
            value={servingsPerPkg}
            error={errors.servingsPerPkg}
            onChange={(e) =>
              this.onChangeMenuItem('servingsPerPkg', parseInt(e.target.value))
            }
          />
          <Dropdown
            label="Serving Utensils"
            error={errors.servingUtensil}
            width="31%"
            onChange={(utensil) =>
              this.onChangeMenuItem('servingUtensilId', utensil.value)
            }
          >
            {allServingUtensils.map((o) => (
              <option
                key={o.value}
                value={o.value}
                selected={servingUtensilId === o.value}
              >
                {o.text}
              </option>
            ))}
          </Dropdown>
          <ServingWeightForm>
            <label>Serving Weights (In ounces)</label>
            <input
              type="number"
              list="servingWeights"
              value={servingWeightOzs}
              onChange={(e) =>
                this.onChangeMenuItem(
                  'servingWeightOzs',
                  parseInt(e.target.value),
                )
              }
            />
            <datalist id="servingWeights">
              {ServingWeights.map((o) => (
                <option key={o.value} value={o.value} />
              ))}
            </datalist>
          </ServingWeightForm>
          <FlexContainer flexDirection="column" width="31%">
            <CurrencyInput
              width="100%"
              label="Chef Price"
              error={errors.cost}
              value={chefPrice}
              onChange={(value) => this.onChangeMenuItem('chefPrice', value)}
            />
            <LinkText
              fontSize="12px"
              onClick={() =>
                this.onChangeMenuItem(
                  'chefPrice',
                  calculateChefPrice(marketPrice),
                )
              }
              label="Calc. Chef Price from Market Price"
            />
          </FlexContainer>
          <FlexContainer flexDirection="column" width="31%">
            <CurrencyInput
              width="100%"
              label="Market Price"
              error={errors.price}
              value={marketPrice}
              onChange={(value) => this.onChangeMenuItem('marketPrice', value)}
            />
            <LinkText
              onClick={() =>
                this.onChangeMenuItem(
                  'marketPrice',
                  calculateMarketPrice(chefPrice),
                )
              }
              label="Calc. Market Price from Chef Price"
              fontSize="12px"
            />
          </FlexContainer>
          <FlexContainer flexDirection="column" width="31%">
            <Dropdown
              label="Market Price Calculation"
              width="100%"
              onChange={(e) =>
                this.onChangeMenuItem('pricePerPersonCalc', e.target.value)
              }
              value={pricePerPersonCalc}
            >
              {AllMpCalculationTypes.map((o) => (
                <option key={o.id} value={o.value}>
                  {o.label}
                </option>
              ))}
            </Dropdown>
          </FlexContainer>
          <XSpacing width="31%" />
          <YSpacing height="20px" />
          <TextArea
            error={errors.description}
            height="50px"
            width="100%"
            label="Description"
            value={description}
            onChange={this.onChange('description')}
          />
          <FileUpload
            width="31%"
            label="Upload Image"
            onChange={(e) => this.onAddImage(e.target.files[0])}
          />
          {images.length > 0 && (
            <Carousel>
              {images.map((image, i) => (
                <Carousel.Item key={i}>
                  <div>
                    {image.destroy ? (
                      <img src={removedImage} />
                    ) : (
                      <img src={image.thumborUrl} />
                    )}
                    <button onClick={() => this.onDeleteImage(i)}>
                      {' '}
                      Delete / Undo{' '}
                    </button>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </FlexContainer>
        <FlexContainer justifyContent="space-between" width="100%">
          <FlexContainer flexDirection="column" width="31%">
            <Label>Allergens/Dietary Preferences</Label>
            <YSpacing height="5px" />
            {allDietaryPreferences.map((p) => (
              <Checkbox
                label={p}
                key={p}
                value={p}
                marginBottom="5px"
                checked={this.state[this.getDietaryPrefKey(p)]}
                onChange={this.onChangeDietaryPref}
              />
            ))}
          </FlexContainer>
          <FlexContainer flexDirection="column" width="31%">
            <Label>Packaging</Label>
            <YSpacing height="5px" />
            <RadioButton
              marginBottom="5px"
              name="packagingId"
              value={null}
              label={'No Packaging'}
              key={'noPackaging'}
              checked={!packagingId}
              onChange={() => this.setState({ packagingId: null })}
            />
            {allDefaultPackagings.map((p) => {
              const { name, id } = p

              return (
                <RadioButton
                  marginBottom="5px"
                  name="packagingId"
                  value={id}
                  label={name}
                  key={id}
                  checked={id === packagingId}
                  onChange={this.onChangeRadioButton}
                />
              )
            })}
          </FlexContainer>
          <FlexContainer flexDirection="column" width="31%">
            <FlexContainer flexDirection="row">
              <Label>Menu Item Status</Label>
              <TooltipModal
                unicode="&#9432;"
                width="400px"
                marginTop="20px"
                information={
                  "The status of the child item's approval/enabling will only be activated if the corresponding checkbox for the parent item is checked. If the parent menu item is approved/enabled, users won't be able to modify the checkbox for the child item until the parent menu item is disapproved/disabled. Otherwise, users have the flexibility to manually adjust it."
                }
              />
            </FlexContainer>
            <YSpacing height="5px" />
            <Checkbox
              diasabled={parentEnabled}
              label="Marketplace Enabled"
              value="isEnabled"
              checked={childEnabled}
              marginBottom="5px"
              onChange={this.onChangeCheckbox}
            />
            <Checkbox
              disabled={parentApproved}
              label="Approved"
              value="isApproved"
              checked={childApproved}
              onChange={this.onChangeCheckbox}
            />
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="20px" />
        {id && (
          <>
            <DividerLine height="2px" />
            <YSpacing height="10px" />
            <MenuItemVerifications
              menuItem={this.state}
              onChange={(newState) => this.setState(newState)}
              pToMenuItemVerifications={this.props.pToMenuItemVerifications}
            />
          </>
        )}
        <FlexContainer alignItems="center" justifyContent="flex-end">
          {id && (
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <LinkText
                color={colors.violet}
                onClick={this.onDelete}
                label="Delete"
              />
            </AuthorizedInteractable>
          )}
          <XSpacing width="20px" />
          <LinkText
            color={colors.gray300}
            onClick={this.onHide}
            label="Cancel"
          />
          <XSpacing width="20px" />
          <AuthorizedInteractable roles={['master admin', 'chef lead']}>
            <Button onClick={this.onSave} label="Save" />
          </AuthorizedInteractable>
        </FlexContainer>
      </Modal>
    )
  }
}

const BackButton = styled.button`
  position: absolute;
  top: 45px;
`

EditMenuItemModal.propTypes = {
  errors: PropTypes.object,
  menuItem: PropTypes.object,
  show: PropTypes.bool,
  parentEnabled: PropTypes.bool,
  parentApproved: PropTypes.bool,

  calculateChefPrice: PropTypes.func,
  calculateMarketPrice: PropTypes.func,
  clearErrors: PropTypes.func,
  close: PropTypes.func,
  closeChildMenuItemModal: PropTypes.func,
  updateMenuItem: PropTypes.func,
  deleteMenuItem: PropTypes.func,
  displayAlert: PropTypes.func,
  loadMenuItemSettings: PropTypes.func,
  pToMenuItemVerifications: PropTypes.func,
  saveMenuItem: PropTypes.func,
}

export default EditMenuItemModal
