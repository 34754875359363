import { connect } from 'react-redux'

import { EditOrderModal } from '@components/order/edit'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'
import { HandleError, Validate } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { mode, show, hubspotDeal, hubspotDeals, section } =
    state.editProposalModal
  const errors = state.errors.editProposalModal || {}
  const orderable = mode === 'edit' ? state.editProposal : state.newProposal
  const {
    orderDiscounts,
    orderTaxExempt: isOrderTaxExempt,
    orderTaxExemptReason,
    invoiceContact,
    isTaxExempt,
    isFreeDelivery,
    freeDeliveryPromoCode,
  } = orderable

  let orderTaxExempt, orderTaxExemptCause
  if (isOrderTaxExempt) {
    orderTaxExempt = isOrderTaxExempt
    orderTaxExemptCause = orderTaxExemptReason
  } else if (invoiceContact && invoiceContact.purchaserTaxStatus !== null) {
    orderTaxExempt = !invoiceContact.purchaserTaxStatus
    orderTaxExemptCause = 'Invoice Contact'
  } else {
    orderTaxExempt = isTaxExempt
    orderTaxExemptCause = 'Account'
  }

  const freeDeliveryWithoutPromo = isFreeDelivery && !freeDeliveryPromoCode
  const orderableType = 'Proposal'

  const { user } = state
  const { lastLoggedInHq: headquarterId, permissions } = user || {}
  const userPermissions = permissions[headquarterId]

  const {
    headquarter: { headquarters },
  } = state
  const hqLocaleMap = headquarters.reduce((acc, hq) => {
    acc[hq.id] = hq.locale

    return acc
  }, {})

  return {
    orderable: {
      ...orderable,
      isFreeDelivery: freeDeliveryWithoutPromo,
      isTaxExempt: orderTaxExempt,
      orderableType,
      discounts: orderDiscounts || [],
      orderTaxExemptCause,
      hubspotProposal: hubspotDeal,
      hubspotProposals: hubspotDeals,
    },
    section: section,
    headquarterId,
    user,
    userPermissions,
    hqLocaleMap,

    errors,
    show,
  }
}

const mapDispatchToProps = () => {
  const {
    ChefService,
    HubspotDealService,
    OrderService,
    ProposalService,
    ReferralService,
    RestService,
    RouterService,
    SessionService,
    SettingsService,
    UIService,
  } = services
  const {
    AsyncLoadAccount,
    AsyncLoadAccounts,
    AsyncLoadChefs,
    AsyncLoadConcepts,
    AsyncLoadLandingProposals,
    AsyncLoadTaxRates,
    ConfirmPaymentMethod,
    DelayedUpdateProposal,
    HandleError: HandleError_,
    OrderToProposals,
    LoadAccountExecutives,
    LoadChef,
    LoadHubspotSingle,
    LoadOrSearchHubspotList,
    LoadProposals,
    LoadReferralPartners,
    LoadSettings,
    PredictServiceCosts,
    RetryOnTimeout,
    SaveAdhocAccount,
    SaveAdhocAddress,
    SaveAdhocContact,
    SaveAdhocPaymentMethod,
    SaveProposal,
    ValidatePickupTimes,
    LoadCateringMenuItems,
    LoadDinerProfiles,
    LoadDinerProfile,
  } = coordinators
  const {
    pContactKeys,
    pDefaultAccountFields,
    pOrderAttributesFromDinerProfile,
    pFindIdentical,
    pFindIdenticalPaymentMethod,
    pRemovedItemFromArray,
    pReplacedItemInArray,
    pStateToReduxOrder,
    pAccountSettingsFromAccount,
  } = presenters
  const {
    pResponseAccountExecutives,
    pRequestHubspotList,
    pRequestSaveAdhocAccount,
    pRequestSaveAdhocAddress,
    pRequestSaveAdhocContact,
    pRequestSaveAdhocPaymentMethod,
    pRequestOrdersFilter,
    pRequestOrderSupplyBreakdown,
    pRequestPredictServiceCosts,
    pRequestUpdateProposal,
    pRequestSettings,
    pRequestLoadConcepts,
    pResponseAllChefConcepts,
    pResponseSettings,
    pResponseChefs,
    pResponseChef,
    pResponseGeneric,
    pResponseHubspotDeals,
    pResponseHubspotSingle,
    pResponseProposal,
    pResponseProposals,
    pResponseOrderError,
    pResponseOrderSearchMenuItems,
  } = presenters.Api
  const { vAddress, vContact, vPaymentMethod } = presenters.Validation

  const {
    addCheftoChefs,
    addMenuItemToChefs,
    addMenuItemsToChefs,
    addServiceItemToChefs,
    addVirtualItemToChefs,
    calculateAll,
    calculateDiscounts,
    getDeliveryFeePercentAndLimit,
    getEmptyChefs,
    getChefAmountMap,
    reorderItemInChefs,
    reorderChef,
    replaceChefNoteInAddOnChefs,
    replaceOrderMenuItemInChefs,
    replaceOrderServiceItemInChefs,
    replaceOrderVirtualItemInChefs,
    removeOrderMenuItemFromChefs,
    removeOrderServiceItemFromChefs,
    removeOrderVirtualItemFromChefs,
    removeChefFromChefs,
    syncChildItems,
    updateTaxExemptStatus,
  } = OrderService
  const { clearEditOrderable: clearForm } = ProposalService
  const confirmPaymentMethod = ConfirmPaymentMethod({ UIService })
  const loadAccountExecutives = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })
  const loadAccount = AsyncLoadAccount({
    RestService,
    pResponseGeneric,
    HandleError,
  })
  const loadAccounts = AsyncLoadAccounts({ RestService, pResponseGeneric })
  const loadChefs = AsyncLoadChefs({
    ChefService,
    OrderService,
    RestService,
    pResponseChefs,
  })
  const loadChef = LoadChef({ RestService, pResponseChef })
  const loadConcepts = AsyncLoadConcepts({
    RestService,
    pResponseAllChefConcepts,
    pRequestLoadConcepts,
  })
  const loadConceptsSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('concepts')
  const loadOrders = LoadProposals({
    UIService,
    ProposalService,
    RestService,
    pRequestProposalsFilter: pRequestOrdersFilter,
    pResponseProposals,
  })
  const loadReferralPartners = LoadReferralPartners({
    ReferralService,
    RestService,
    pResponseGeneric,
  })
  const delayedUpdateOrder = DelayedUpdateProposal({
    ProposalService,
    UIService,
    pStateToReduxProposal: pStateToReduxOrder,
  })
  const predictServiceCosts = PredictServiceCosts({
    RestService,
    pRequestPredictServiceCosts,
    pRequestOrderSupplyBreakdown,
    pResponseServiceCosts: pResponseGeneric,
  })
  const saveAccount = SaveAdhocAccount({
    RestService,
    pRequestSaveAdhocAccount,
    pResponseGeneric,
    HandleError,
  })
  const saveAddress = SaveAdhocAddress({
    RestService,
    pFindIdentical,
    pRequestSaveAdhocAddress,
    pResponseGeneric,
    HandleError,
  })
  const saveContact = SaveAdhocContact({
    RestService,
    pFindIdentical,
    pRequestSaveAdhocContact,
    pResponseGeneric,
    HandleError,
  })
  const saveOrder = SaveProposal({
    OrderService,
    ProposalService,
    RestService,
    RouterService,
    SessionService,
    UIService,
    pRequestUpdateProposal,
    pResponseProposal,
    pResponseProposalError: pResponseOrderError,
    HandleError: HandleError_,
  })
  const loadProposalDashboard = AsyncLoadLandingProposals({
    ProposalService,
    RestService,
    pResponseGeneric,
  })
  const savePaymentMethod = SaveAdhocPaymentMethod({
    RestService,
    pFindIdenticalPaymentMethod,
    pRequestSaveAdhocPaymentMethod,
    pResponseGeneric,
    HandleError,
  })
  const loadHubspotDeal = LoadHubspotSingle({
    RestService,
    HubspotService: HubspotDealService,
    pResponseHubspotSingle,
    endpoint: 'deals',
  })
  const loadHubspotProposals = LoadOrSearchHubspotList({
    RestService,
    HubspotService: HubspotDealService,
    pRequestHubspotList,
    pResponseHubspotList: pResponseHubspotDeals,
    endpoint: 'proposals',
  })
  const validateAddress = Validate({
    validator: vAddress,
    namespace: 'editOrderModal',
  })
  const validateContact = Validate({
    validator: vContact,
    namespace: 'editOrderModal',
  })
  const validatePaymentMethod = Validate({
    validator: vPaymentMethod,
    namespace: 'editOrderModal',
  })
  const validateChefPickupTimes = ValidatePickupTimes({
    RestService,
    UIService,
    HandleError,
  })

  const loadFoodItems = LoadCateringMenuItems({
    RestService,
    pResponseOrderSearchMenuItems,
  })
  const loadDinerProfiles = LoadDinerProfiles({
    RestService,
    HandleError,
  })
  const loadDinerProfile = LoadDinerProfile({
    RestService,
    HandleError,
  })

  const orderToProposals = OrderToProposals({
    RestService,
    HandleError,
  })

  const loadTaxRates = AsyncLoadTaxRates({
    RestService,
    SettingsService,
    RetryOnTimeout,
    pResponseGeneric,
    HandleError,
  })

  const {
    FlashMessage: { displayWarningMessage, displayFailureMessage },
    Errors: { clear: clearErrors, clearSingle },
    EditProposal: { close },
    ConfirmationModal: { show: showConfirmationModal },
  } = UIService
  const clearError = clearSingle('editProposalModal')

  return {
    addCheftoChefs,
    addMenuItemToChefs,
    addMenuItemsToChefs,
    addServiceItemToChefs,
    addVirtualItemToChefs,
    calculateAll,
    calculateDiscounts,
    clearError,
    clearErrors,
    clearForm,
    close,
    confirmPaymentMethod,
    delayedUpdateOrder,
    displayWarningMessage,
    displayFailureMessage,
    orderToProposals,
    getDeliveryFeePercentAndLimit,
    getEmptyChefs,
    getChefAmountMap,
    loadAccountExecutives,
    loadAccount,
    loadAccounts,
    loadDinerProfile,
    loadDinerProfiles,
    loadChefs,
    loadChef,
    loadFoodItems,
    loadConcepts,
    loadConceptsSettings,
    loadHubspotDeal,
    loadHubspotProposals,
    loadOrders,
    loadProposalDashboard,
    loadReferralPartners,
    loadTaxRates,
    pContactKeys,
    pDefaultAccountFields,
    pOrderAttributesFromDinerProfile,
    pRemovedItemFromArray,
    pReplacedItemInArray,
    pAccountSettingsFromAccount,
    predictServiceCosts,
    reorderItemInChefs,
    reorderChef,
    replaceChefNoteInAddOnChefs,
    replaceOrderMenuItemInChefs,
    replaceOrderServiceItemInChefs,
    replaceOrderVirtualItemInChefs,
    removeOrderMenuItemFromChefs,
    removeOrderServiceItemFromChefs,
    removeOrderVirtualItemFromChefs,
    removeChefFromChefs,
    saveAccount,
    saveAddress,
    saveContact,
    saveOrder,
    showConfirmationModal,
    syncChildItems,
    savePaymentMethod,
    updateTaxExemptStatus,
    validateAddress,
    validateContact,
    validatePaymentMethod,
    validateChefPickupTimes,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditOrderModal)
