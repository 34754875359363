import Moment from 'moment-timezone'

import { camelCaseify, formAdd, sortByAttribute } from '~/utils'

export const pRequestSurveys = ({ filters, orderNumber, surveyName }) => {
  const req = { survey_name: surveyName }
  if (orderNumber) {
    req.order_number = orderNumber
  } else if (filters) {
    formAdd(filters, req, 'endDate', 'end', (v) => v.toDate())
    if (filters.search && !/^ *$/.test(filters.search)) {
      req.search = filters.search
    }
    formAdd(filters, req, 'sort', 'sort')
    formAdd(filters, req, 'accountId', 'account_id')
    formAdd(filters, req, 'startDate', 'start', (v) => v.toDate())
  }

  return req
}

export const pRequestDashboardSurveys = (filters = {}) => {
  const req = { view: 'dashboard' }
  formAdd(filters, req, 'endDate', 'end', (v) => v.toDate())
  formAdd(filters, req, 'isShowingLowRatings', 'low_ratings', (v) =>
    v ? 1 : 0,
  )
  if (filters.search && !/^ *$/.test(filters.search)) {
    req.search = filters.search
  }
  formAdd(filters, req, 'search', 'search')
  formAdd(filters, req, 'sort', 'sort')
  formAdd(filters, req, 'startDate', 'start', (v) => v.toDate())

  return req
}

export const pResponseSurveyList = (json) => {
  sortByAttribute(json, 'displayName')

  return json
}

export const pResponseDashboardSurveys = (json) => {
  const surveys = camelCaseify(json)
  surveys.forEach((s) => {
    s.chefs = s.chefs ? s.chefs.join(', ') : ''
    const orderDate = Moment(s.orderDate)
    if (orderDate.isValid()) {
      s.orderDate = orderDate.format('MM/DD/YYYY h:mma').replace(' ', '\n')
    }
  })

  return surveys
}

export const pResponseSurveyObjects = ({ headers, surveys }) => {
  return surveys.map((survey) => ({
    results: survey.map((answer, idx) => ({
      question: headers[idx],
      answer,
    })),
  }))
}
