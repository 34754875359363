import React from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  CurrencyInput,
  Dropdown,
  TimeInput,
  Input,
  LinkText,
} from '@components/common/form'
import { DEFAULT_GRATUITY_TYPES } from '../../../../constants'
import FlexContainer from '@components/common/FlexContainer'
import { Label } from '@res/styledComponents/index'
import { valueAsNumberOrNull } from '~/utils'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'
import Moment from 'moment-timezone'

const CustomizedSettingsSection = (props) => {
  const {
    gratuityType,
    gratuity,
    needStaffAndServe,
    cateringStaffCount,
    cateringStaffHours,
    cateringStaffRate,
    cateringStaffFee,

    deliveryAndServiceFeePercentWeekend,
    deliveryAndServiceFeePercentWeekday,
    deliveryAndServiceFeePercentHoliday,
    deliveryAndServiceFeeCapWeekend,
    deliveryAndServiceFeeCapWeekday,
    deliveryAndServiceFeeCapHoliday,

    setUpCompleteByTime,
    doNotArriveBeforeTime,

    lastUpdatedByGratuityAmount,
    lastUpdatedByStaffingFee,
    lastUpdatedByDeliveryAndServiceWeekendFee,
    lastUpdatedByDeliveryAndServiceWeekdayFee,
    lastUpdatedByDeliveryAndServiceHolidayFee,
    lastUpdatedBySetUpCompleteByTime,
    lastUpdatedByDoNotArriveBeforeTime,
    lastUpdatedAtGratuityAmount,
    lastUpdatedAtStaffingFee,
    lastUpdatedAtDeliveryAndServiceWeekendFee,
    lastUpdatedAtDeliveryAndServiceWeekdayFee,
    lastUpdatedAtDeliveryAndServiceHolidayFee,
    lastUpdatedAtSetUpCompleteByTime,
    lastUpdatedAtDoNotArriveBeforeTime,

    onChange,
    onChangeCheckbox,
    onChangeStaffingFeeFields,
    renderLastUpdated,
    skipValues,

    currencyDelay,
  } = props

  const renderPercentButton = (percent, field) => {
    const onClick = (e) => {
      e.preventDefault()
      e.stopPropagation()

      onChange(field, percent)
    }

    return (
      <FlexContainer>
        <LinkText key={percent} onClick={onClick} label={`${percent}%`} />
        <XSpacing width="15px" />
      </FlexContainer>
    )
  }

  return (
    <div>
      <FlexContainer width="100%" justifyContent="space-between">
        <FlexContainer width="31%" flexDirection="column">
          <FlexContainer width="100%" justifyContent="space-between">
            <Dropdown
              label="Gratuity type"
              width="48%"
              testId="gratuity-type"
              value={gratuityType}
              onChange={(e) => onChange('gratuityType', e.target.value)}
            >
              <option key={-1} value="">
                Select
              </option>
              {DEFAULT_GRATUITY_TYPES.map((r) => (
                <option key={r} value={r}>
                  {r}
                </option>
              ))}
            </Dropdown>
            <Input
              label="Default Gratuity"
              type="number"
              width="48%"
              testId="default-gratuity"
              value={gratuity}
              onChange={(e) =>
                onChange(
                  'gratuity',
                  valueAsNumberOrNull(e.target.valueAsNumber, true),
                )
              }
            />
          </FlexContainer>
          {renderLastUpdated(
            lastUpdatedByGratuityAmount,
            lastUpdatedAtGratuityAmount,
          )}
        </FlexContainer>
        <FlexContainer width="31%" flexDirection="column">
          <FlexContainer flexDirection="column">
            <Label>{'Need Staff & Serve?'}</Label>
            <Checkbox
              key={'need-staff-serv'}
              label={''}
              value={'needStaffAndServe'}
              checked={needStaffAndServe}
              onChange={onChangeCheckbox}
            />
            <YSpacing height="20px" />
            {renderLastUpdated(
              lastUpdatedByStaffingFee,
              lastUpdatedAtStaffingFee,
            )}
          </FlexContainer>
        </FlexContainer>
        {needStaffAndServe ? (
          <FlexContainer width="31%" flexDirection="column">
            <Label>{'Default Staffing Fee (Catering)'}</Label>
            <FlexContainer justifyContent="space-between">
              <Input
                width="23%"
                label="#Staff"
                type="number"
                value={cateringStaffCount}
                testId="default-staffing-fee-staff"
                onChange={onChangeStaffingFeeFields('cateringStaffCount')}
              />
              <Input
                width="23%"
                type="number"
                label="#Hours"
                value={cateringStaffHours}
                testId="default-staffing-fee-hours"
                onChange={onChangeStaffingFeeFields('cateringStaffHours')}
              />
              <Input
                width="23%"
                label="Rate"
                type="number"
                value={cateringStaffRate}
                testId="default-staffing-fee-rate"
                onChange={onChangeStaffingFeeFields('cateringStaffRate')}
              />
              <CurrencyInput
                width="23%"
                disabled={true}
                label="Total"
                value={cateringStaffFee}
                testId="default-staffing-fee-total"
              />
            </FlexContainer>
          </FlexContainer>
        ) : (
          <FlexContainer width="31%"></FlexContainer>
        )}
      </FlexContainer>

      <YSpacing height="20px" />

      <FlexContainer width="100%" justifyContent="space-between">
        <FlexContainer width="31%" flexDirection="column">
          <Label>
            {'Default Delivery & Service'} <u> Weekday </u> {'Fees'}
          </Label>
          <FlexContainer justifyContent="space-between">
            <FlexContainer width="49%" flexDirection="column">
              <Input
                width="100%"
                label="% of Subtotal"
                value={deliveryAndServiceFeePercentWeekday}
                type="number"
                testId="default-weekday-delivery-fees-subtotal"
                onChange={(e) =>
                  onChange(
                    'deliveryAndServiceFeePercentWeekday',
                    valueAsNumberOrNull(e.target.valueAsNumber),
                  )
                }
              />
            </FlexContainer>
            <CurrencyInput
              width="49%"
              label="$ Fee Cap"
              delay={currencyDelay}
              value={deliveryAndServiceFeeCapWeekday}
              testId="default-weekday-delivery-fees-fee-cap"
              onChange={(value) =>
                onChange(
                  'deliveryAndServiceFeeCapWeekday',
                  valueAsNumberOrNull(value),
                )
              }
            />
          </FlexContainer>
          <FlexContainer width="35%">
            {[10, 15, 20].map((percent) =>
              renderPercentButton(
                percent,
                'deliveryAndServiceFeePercentWeekday',
              ),
            )}
          </FlexContainer>
          {renderLastUpdated(
            lastUpdatedByDeliveryAndServiceWeekdayFee,
            lastUpdatedAtDeliveryAndServiceWeekdayFee,
          )}
        </FlexContainer>
        <FlexContainer width="31%" flexDirection="column">
          <Label>
            {'Default Delivery & Service'} <u> Weekend </u> {'Fees'}
          </Label>
          <FlexContainer justifyContent="space-between">
            <FlexContainer width="49%" flexDirection="column">
              <Input
                width="100%"
                label="% of Subtotal"
                value={deliveryAndServiceFeePercentWeekend}
                type="number"
                testId="default-weekend-delivery-fees-subtotal"
                onChange={(e) =>
                  onChange(
                    'deliveryAndServiceFeePercentWeekend',
                    valueAsNumberOrNull(e.target.valueAsNumber),
                  )
                }
              />
              <FlexContainer width="35%">
                {[10, 15, 20].map((percent) =>
                  renderPercentButton(
                    percent,
                    'deliveryAndServiceFeePercentWeekend',
                  ),
                )}
              </FlexContainer>
              {renderLastUpdated(
                lastUpdatedByDeliveryAndServiceWeekendFee,
                lastUpdatedAtDeliveryAndServiceWeekendFee,
              )}
            </FlexContainer>
            <CurrencyInput
              width="49%"
              label="$ Fee Cap"
              delay={currencyDelay}
              value={deliveryAndServiceFeeCapWeekend}
              testId="default-weekend-delivery-fees-fee-cap"
              onChange={(value) =>
                onChange(
                  'deliveryAndServiceFeeCapWeekend',
                  valueAsNumberOrNull(value),
                )
              }
            />
          </FlexContainer>
        </FlexContainer>

        <FlexContainer width="31%" flexDirection="column">
          <Label>
            {'Default Delivery & Service'} <u> Holiday </u> {'Fees'}{' '}
          </Label>
          <FlexContainer justifyContent="space-between">
            <FlexContainer width="49%" flexDirection="column">
              <Input
                width="100%"
                label="% of Subtotal"
                type="number"
                value={deliveryAndServiceFeePercentHoliday}
                testId="default-holiday-delivery-fees-subtotal"
                onChange={(e) =>
                  onChange(
                    'deliveryAndServiceFeePercentHoliday',
                    valueAsNumberOrNull(e.target.valueAsNumber),
                  )
                }
              />
              <FlexContainer width="35%">
                {[10, 15, 20].map((percent) =>
                  renderPercentButton(
                    percent,
                    'deliveryAndServiceFeePercentHoliday',
                  ),
                )}
              </FlexContainer>
              {renderLastUpdated(
                lastUpdatedByDeliveryAndServiceHolidayFee,
                lastUpdatedAtDeliveryAndServiceHolidayFee,
              )}
            </FlexContainer>
            <CurrencyInput
              width="49%"
              label="$ Fee Cap"
              delay={currencyDelay}
              value={deliveryAndServiceFeeCapHoliday}
              testId="default-holiday-delivery-fees-fee-cap"
              onChange={(value) =>
                onChange(
                  'deliveryAndServiceFeeCapHoliday',
                  valueAsNumberOrNull(value),
                )
              }
            />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>

      <YSpacing height="20px" />

      <FlexContainer width="100%" justifyContent="space-between">
        {!(skipValues || {})['setUpCompleteByTime'] && (
          <FlexContainer width="31%" flexDirection="column">
            <TimeInput
              width="100%"
              label="Default set up complete by time"
              time={setUpCompleteByTime}
              testId="default-set-up-complete-by-time"
              onChange={(value) => onChange('setUpCompleteByTime', value)}
            />
            {renderLastUpdated(
              lastUpdatedBySetUpCompleteByTime,
              lastUpdatedAtSetUpCompleteByTime,
            )}
          </FlexContainer>
        )}
        <FlexContainer width="31%" flexDirection="column">
          <TimeInput
            width="100%"
            label="Default do not arrive before time"
            time={doNotArriveBeforeTime}
            testId="default-do-not-arrive-before-time"
            onChange={(value) => onChange('doNotArriveBeforeTime', value)}
          />
          {renderLastUpdated(
            lastUpdatedByDoNotArriveBeforeTime,
            lastUpdatedAtDoNotArriveBeforeTime,
          )}
        </FlexContainer>
        {!(skipValues || {})['doNotArriveBeforeTime'] && (
          <FlexContainer width="31%"></FlexContainer>
        )}
        <FlexContainer width="31%" flexDirection="column"></FlexContainer>
      </FlexContainer>
    </div>
  )
}

CustomizedSettingsSection.propTypes = {
  gratuityType: PropTypes.string,
  gratuity: PropTypes.number,
  needStaffAndServe: PropTypes.bool,
  cateringStaffCount: PropTypes.number,
  cateringStaffHours: PropTypes.number,
  cateringStaffRate: PropTypes.number,
  cateringStaffFee: PropTypes.number,
  deliveryAndServiceFeePercentWeekend: PropTypes.number,
  deliveryAndServiceFeePercentWeekday: PropTypes.number,
  deliveryAndServiceFeePercentHoliday: PropTypes.number,
  deliveryAndServiceFeeCapWeekend: PropTypes.number,
  deliveryAndServiceFeeCapWeekday: PropTypes.number,
  deliveryAndServiceFeeCapHoliday: PropTypes.number,
  setUpCompleteByTime: PropTypes.instanceOf(Moment),
  doNotArriveBeforeTime: PropTypes.instanceOf(Moment),
  lastUpdatedByGratuityAmount: PropTypes.string,
  lastUpdatedByStaffingFee: PropTypes.string,
  lastUpdatedByDeliveryAndServiceWeekendFee: PropTypes.string,
  lastUpdatedByDeliveryAndServiceWeekdayFee: PropTypes.string,
  lastUpdatedByDeliveryAndServiceHolidayFee: PropTypes.string,
  lastUpdatedBySetUpCompleteByTime: PropTypes.string,
  lastUpdatedByDoNotArriveBeforeTime: PropTypes.string,
  lastUpdatedByClientNotes: PropTypes.string,
  lastUpdatedByCuisinePreferences: PropTypes.string,
  lastUpdatedByClientSuccessMetrics: PropTypes.string,
  lastUpdatedAtGratuityAmount: PropTypes.instanceOf(Moment),
  lastUpdatedAtStaffingFee: PropTypes.instanceOf(Moment),
  lastUpdatedAtDeliveryAndServiceWeekendFee: PropTypes.instanceOf(Moment),
  lastUpdatedAtDeliveryAndServiceWeekdayFee: PropTypes.instanceOf(Moment),
  lastUpdatedAtDeliveryAndServiceHolidayFee: PropTypes.instanceOf(Moment),
  lastUpdatedAtSetUpCompleteByTime: PropTypes.instanceOf(Moment),
  lastUpdatedAtDoNotArriveBeforeTime: PropTypes.instanceOf(Moment),
  lastUpdatedAtClientNotes: PropTypes.instanceOf(Moment),
  lastUpdatedAtCuisinePreferences: PropTypes.instanceOf(Moment),
  lastUpdatedAtClientSuccessMetrics: PropTypes.instanceOf(Moment),

  onChange: PropTypes.func,
  onChangeStaffingFeeFields: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  renderLastUpdated: PropTypes.func,
  skipValues: PropTypes.object,
  currencyDelay: PropTypes.number,
}

export default CustomizedSettingsSection
