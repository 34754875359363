import { sentenceCaseify } from '~/utils'

export const pResponseUser = (user) => {
  user.name = [user.lastName, user.firstName].join(' ')
  user.roles = []
  user.hqs = []
  user.permissions &&
    Object.keys(user.permissions).forEach((hq) => {
      user.permissions[hq].forEach((role) => {
        const headquarterId = parseInt(hq)
        user.roles.push({
          headquarterId,
          name: pResponseRole(role),
        })
        if (!user.hqs.includes(headquarterId)) {
          user.hqs.push(headquarterId)
        }
      })
    })
  user.lastLoggedInHq = user.lastHeadquarterSignIn

  return user
}

export const pResponseRole = (role) => {
  if (role === 'masterSalesRep') {
    return 'sales lead'
  } else if (role === 'chefRecruiting') {
    return 'chef lead'
  } else if (role === 'admin') {
    return 'master admin'
  } else {
    return sentenceCaseify(role).toLowerCase()
  }
}

export const pUserHasCurrentRoles = (user, roles) => {
  const { lastLoggedInHq } = user
  const currentRoles = user.roles.filter(
    (r) => r.headquarterId === lastLoggedInHq,
  )

  return currentRoles.some((role) => roles.includes(role.name))
}
