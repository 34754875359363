export const AsyncLoadSurveyList =
  ({ RestService, pResponseSurveyList }) =>
  async () => {
    const surveys = await RestService.get(
      '/api/admin/surveys/?view=survey_list',
    )

    return pResponseSurveyList(surveys)
  }

export const LoadDashboardSurveys =
  ({
    RestService,
    SurveyService,
    pRequestDashboardSurveys,
    pResponseDashboardSurveys,
  }) =>
  async (filters) => {
    const params = pRequestDashboardSurveys(filters)
    let surveys = await RestService.get('/api/admin/surveys', params)
    surveys = pResponseDashboardSurveys(surveys)
    SurveyService.setDashboardSurveys(surveys)
  }

export const LoadSurveys =
  ({ RestService, SurveyService, pRequestSurveys }) =>
  async ({ filters, orderNumber, surveyName }) => {
    const params = pRequestSurveys({ filters, orderNumber, surveyName })
    const surveyInfo = await RestService.get('/api/admin/surveys', params)
    SurveyService.setSurveys(surveyInfo)
  }

export const AsyncLoadSurveys =
  ({ RestService, pRequestSurveys, pResponseSurveyObjects }) =>
  async ({ filters, orderNumber, surveyName }) => {
    const params = pRequestSurveys({ filters, orderNumber, surveyName })
    const surveyInfo = await RestService.get('/api/admin/surveys', params)

    return pResponseSurveyObjects(surveyInfo)
  }
